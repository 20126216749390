import type {
  AppDispatch,
  IMonthBucket,
  ITripTerminus,
} from "@b2bportal/core-types";
import { useEnablePricePrediction } from "@b2bportal/core-utilities";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { TripCategory } from "../../../types";
import {
  getPricePredictionDepartureMonths,
  getPriceTags,
  isFlightPredictionLoading,
} from "../store";
import { fetchCalendar } from "../store/thunks";

export const useFetchCalendar = ({
  origin,
  destination,
  tripCategory,
}: {
  origin?: ITripTerminus;
  destination?: ITripTerminus;
  tripCategory?: TripCategory;
}): {
  departureMonths: IMonthBucket[];
  priceTags: string[];
  isLoading: boolean;
} => {
  const isEnabled = useEnablePricePrediction();
  const isLoading = useSelector(isFlightPredictionLoading);
  const priceTags = useSelector(getPriceTags);
  const departureMonths = useSelector(getPricePredictionDepartureMonths);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      origin?.id?.code != null &&
      destination?.id?.code != null &&
      tripCategory != null &&
      isEnabled
    ) {
      dispatch(fetchCalendar({ origin, destination, tripCategory }));
    }
  }, [origin?.id?.code, destination?.id?.code, tripCategory, isEnabled]);

  return {
    departureMonths,
    priceTags,
    isLoading,
  };
};
