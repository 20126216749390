import clsx from "clsx";
import type { LodgingPrice } from "@b2bportal/lodging-api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import CashBackIconLight from "../../../assets/client/darkMode/cash-back-icon-light.svg";
import CashBackIcon from "../../../assets/client/cash-back-icon.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import styles from "./styles.module.scss";

interface LodgingCardCashBackProps {
  price: LodgingPrice;
}

const LodgingCardCashBack = ({ price }: LodgingCardCashBackProps) => {
  const { formatFiatCurrency, t } = useI18nContext();

  const isDarkModePreferred = useDarkModePreferred();
  if (!price) return undefined;

  // cashback is a standard 1%, always
  const cashBackAmount = formatFiatCurrency(
    {
      ...price?.totalPrice?.fiat,
      value: Math.floor(price?.totalPrice?.fiat.value * 0.01),
    },
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );

  return (
    <div className={clsx(styles.LodgingCardCashBack)}>
      <img
        className="lodging-card-cashback-icon"
        src={isDarkModePreferred ? CashBackIconLight : CashBackIcon}
        alt=""
      />
      <p className="lodging-card-cashback-amount">
        <Trans
          i18nKey="inCashBack"
          values={{
            amount: cashBackAmount,
          }}
          components={[<span className="lodging-card-cashback-label" />]}
        />
      </p>
    </div>
  );
};

export default LodgingCardCashBack;
