import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import {
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderInputParams,
  type AutocompleteRenderOptionState,
  Autocomplete as MuiAutocomplete,
} from "@material-ui/lab";
import { trackEvent } from "@hopper-b2b/api";
import { apiConfig } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { type IApiConfig, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useDebounce } from "@hopper-b2b/utilities";
import { LodgingSelectionEnum, type Suggestion } from "@b2bportal/lodging-api";
import { useExperiment } from "@hopper-b2b/experiments";
import { EXPERIMENTS } from "../../../App";
import styles from "./styles.module.scss";

type HotelSearchAutocompleteProps<T> = {
  id: string;
  defaultValue?: T;
  label: string;
  disabled?: boolean;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    callback: (newOptions: T[]) => void
  ) => Promise<void>;
  onChange: (value: T) => void;
  sortOptions?: (options?: T[]) => T[];
  groupBy?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getGroupLabelKey?: (group: string) => string;
  icon?: string;
  onOpen?: () => void;
  paperClassName?: string;
  className?: string;
  shrinkLabel?: boolean;
  animated?: true;
  renderOption?: (option: T) => React.ReactNode;
};
export default HotelSearchAutocompleteProps;

export const HotelSearchAutocomplete = <T,>({
  id,
  defaultValue,
  label,
  disabled,
  fetch,
  sortOptions,
  onChange,
  groupBy,
  getOptionLabel,
  getGroupLabelKey,
  icon,
  onOpen,
  paperClassName,
  className,
  shrinkLabel,
  animated,
  renderOption: _baseRenderOption,
}: HotelSearchAutocompleteProps<T>) => {
  const { t } = useI18nContext();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);

  const [value, setValue] = useState<T>(() => defaultValue);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<T[]>([]);

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    if (!open || !debouncedSearch) return;

    setLoading(true);
    fetch(apiConfig, debouncedSearch, (newOptions: T[]) => {
      setOptions(sortOptions ? sortOptions(newOptions) : newOptions);
      setLoading(false);
    });
  }, [fetch, open, debouncedSearch, sortOptions]);

  const handleOnInputChange = useCallback(
    (_event, newInputValue: string) => {
      if (search !== newInputValue) {
        setSearch(
          newInputValue.length === 1
            ? newInputValue.toLocaleUpperCase()
            : newInputValue
        );
      }
    },
    [search]
  );

  const handleOnChangeValue = useCallback(
    (_event, value) => {
      const char_typed =
        search !== value?.id?.lodgingSelection?.searchTerm ? search : undefined;
      const location_type = value?.id?.lodgingSelection?.placeTypes;
      const search_term = value?.id?.lodgingSelection?.searchTerm;

      setValue(value);
      onChange(value);
      setOpen(false);

      trackEvent({
        eventName: LodgingShopTrackingEvents.tapped_search_suggestion,
        properties: {
          location_type,
          search_term,
          char_typed,
          // position in the list of suggestions
          location_list_index: _event.target.dataset.optionIndex + 1,
          ...value?.["trackingPropertiesV2"],
        },
      });
    },
    [onChange, search]
  );

  const handleOnOpen = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleOnFocus = useCallback(() => {
    setShrink(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setShrink(false);
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={value && !animated ? null : label}
        aria-label={label}
        variant="filled"
        InputLabelProps={{
          shrink: animated ? shrink || !!value : shrinkLabel,
          focused: false,
          className: styles.label,
          disableAnimation: !animated,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: (
            <>
              <InputAdornment
                position="start"
                className={styles.startAdornment}
              >
                {icon ? <img src={icon} alt="" /> : null}
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: params.InputProps.endAdornment,
        }}
      />
    ),
    [value, animated, label, shrink, shrinkLabel, icon]
  );

  const renderGroup = useCallback(
    ({ key, group, children }: AutocompleteRenderGroupParams) => (
      <div key={key} className={styles.group}>
        <Typography variant="caption" color="textSecondary">
          {t?.(getGroupLabelKey ? getGroupLabelKey(group) : group)}
        </Typography>
        {(children as JSX.Element[]).map((e) => ({
          ...e,
          props: { ...e.props, tabIndex: 2 },
        }))}
      </div>
    ),
    [getGroupLabelKey, t]
  );

  /**
   * Renders the autocomplete option label to bold the input text, if it exists
   *
   * Example: baseLabel = "San Francisco", state.inputValue = "San Fr", returns <b>San Fr</b>ancisco
   * @param option
   * @param state
   * @returns
   */
  const renderOption = (
    option: Suggestion,
    state: AutocompleteRenderOptionState
  ): JSX.Element | string => {
    // Create the base label with no styling
    const baseLabel =
      option?.id.Id === "Lodgings" &&
      option.id.lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
        ? option.id.lodgingSelection.searchTerm
        : option?.label;
    // Check if the base label has the input text in it
    const valuePosition = baseLabel
      .toLowerCase()
      .indexOf(state.inputValue.toLowerCase());
    if (valuePosition >= 0) {
      // The label has the text, grab that snippet out
      const textToBold = baseLabel.slice(
        valuePosition,
        valuePosition + state.inputValue.length
      );

      const updatedString = (
        <span className="custom-label">
          {baseLabel.slice(0, valuePosition)}
          <b>{textToBold}</b>
          {baseLabel.slice(valuePosition + state.inputValue.length)}
        </span>
      );
      return updatedString;
    }
    return baseLabel;
  };

  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);

  return (
    <div>
      <MuiAutocomplete
        autoComplete
        id={id}
        open={open}
        disabled={disabled}
        onOpen={handleOnOpen}
        value={value}
        inputValue={search}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        loadingText={t?.("loading")}
        noOptionsText={t?.("noOptions")}
        classes={{
          paper: clsx(paperClassName, { "black-friday": blackFridayEnabled }),
        }}
        onChange={handleOnChangeValue}
        onInputChange={handleOnInputChange}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        disableClearable={loading}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        renderInput={renderInput}
        renderGroup={renderGroup}
        renderOption={renderOption}
        className={className}
        // Disable UI filtering
        filterOptions={(options: T[]) => options}
      />
      <span id="initInstr" style={{ display: "none" }}>
        {t("autocompleteAriaHelperText")}
      </span>
      <div aria-live="assertive" className="screen-reader-text"></div>
    </div>
  );
};
