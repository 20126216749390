import { DatePickerButton, useFlightDateSelection } from "@components/flights";
import { useModuleBEM, useFlightStyles } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import defaultStyles from "./FlightDateSelection.module.scss";

export type FlightDateSelectionProps = {
  setCalendarVisitedHandler?: () => void;
  closePopupHandler?: () => void;
  disabled?: boolean;
  selectedDepartureDate?: string;
  selectedReturnDate?: string;
};

export const FlightDateSelection = (props: FlightDateSelectionProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightDateSelection,
    defaultStyles
  );

  const [, cn] = useModuleBEM(styles, CoreFlightsComponent.FlightDateSelection);

  const { context, handlers } = useFlightDateSelection(props);

  return (
    <DatePickerButton
      classes={[cn("container")]}
      buttonClass={cn("button")}
      {...context}
      {...handlers}
    />
  );
};
