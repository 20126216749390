import { useEffect, useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  TripCategory,
  SearchEvents,
} from "@b2bportal/core-types";
import defaultStyles from "./CalendarPicker.module.scss";
import { useFetchCalendar } from "../../hooks/";
import { trackEvent } from "@hopper-b2b/api";
import {
  CalendarTrackingProperties,
  useFlightSearch,
  CalendarPickerBottomContent,
} from "@components/flights";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  Calendar,
  Dialog,
  DialogContent,
  PriceRangeTags,
  PickerType,
  MonthAndDatePickerType,
} from "@components/ui";
import clsx from "clsx";
import dayjs from "dayjs";

export interface ICalendarPickerProps {
  focusedMonthIndex?: number;
  ready?: boolean;
  calendarClassName?: string;
  setCalendarVisitedHandler?: (value: boolean) => void;
  calendarVisited?: boolean;
  showBackButton?: boolean;
  open: boolean;
  onClose: () => void;
  onSearch?: () => void;
}

export const CalendarPicker = (props: ICalendarPickerProps) => {
  const {
    focusedMonthIndex,
    calendarVisited = false,
    setCalendarVisitedHandler,
    showBackButton = false,
    open,
    onClose,
    onSearch,
  } = props;

  const COMPONENT_KEY = CoreFlightsComponent.CalendarPicker;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const { brand } = useI18nContext();

  const {
    context: { origin, destination, departureDate, returnDate, tripCategory },
    handlers: { onDepartureDateChange, onReturnDateChange },
  } = useFlightSearch();

  const trackingProperties: CalendarTrackingProperties = {
    origin: origin?.id.code.code,
    destination: destination?.id.code.code,
    tripCategory: tripCategory,
  };

  const headerTitle = useMemo(
    () =>
      isDesktopAndUp
        ? tripCategory === TripCategory.ONE_WAY
          ? t("core-flights.search.dateSelection.title.full.oneWay")
          : t("core-flights.search.dateSelection.title.full.roundTrip")
        : tripCategory === TripCategory.ONE_WAY
        ? t("core-flights.search.dateSelection.title.short.oneWay")
        : t("core-flights.search.dateSelection.title.short.roundTrip"),
    [t, tripCategory, isDesktopAndUp]
  );

  const calendarClassName = useMemo(
    () =>
      tripCategory === TripCategory.ONE_WAY ? cn("oneWay") : cn("roundtrip"),
    [tripCategory]
  );

  useEffect(() => {
    if (!calendarVisited && setCalendarVisitedHandler)
      setCalendarVisitedHandler(true);
    trackEvent({
      eventName: SearchEvents.VIEW_CALENDAR,
      properties: trackingProperties,
    });
  }, []);

  const ready = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? !!departureDate
      : tripCategory === TripCategory.ROUND_TRIP
      ? !!returnDate && !!departureDate
      : false;
  }, [tripCategory, departureDate, returnDate]);

  const pickerType =
    tripCategory === TripCategory.ROUND_TRIP
      ? PickerType.RANGE
      : PickerType.DAY;

  const { departureMonths, priceTags } = useFetchCalendar({
    origin,
    destination,
    tripCategory,
  });

  const priceTagsElement = priceTags.length ? (
    <div className={cn("price-tags-section")}>
      <PriceRangeTags priceTags={priceTags} />
    </div>
  ) : null;

  const bottomContent = (
    <CalendarPickerBottomContent
      priceTagsElement={priceTagsElement}
      ready={ready}
      onClose={onClose}
      onSearch={onSearch}
    />
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        title={headerTitle}
        className={clsx(block, calendarClassName)}
        fullScreen={!isDesktopAndUp}
        showBackButton={showBackButton}
        bottomFixedContent={bottomContent}
        showCloseButton={!showBackButton}
      >
        <Calendar
          className={cn("calendar")}
          viewType={
            isDesktopAndUp
              ? MonthAndDatePickerType.Horizontal
              : MonthAndDatePickerType.Column
          }
          focusedMonthIndex={focusedMonthIndex}
          setStartDate={(date) => onDepartureDateChange(date?.toISOString())}
          setEndDate={(date) => onReturnDateChange(date?.toISOString())}
          startDate={
            departureDate == null ? null : dayjs(departureDate).toDate()
          }
          endDate={returnDate == null ? null : dayjs(returnDate).toDate()}
          months={departureMonths}
          priceTagsElement={priceTagsElement ?? <></>}
          showPriceTags={!isDesktopAndUp}
          priceTags={priceTags}
          pickerType={pickerType}
          currency={brand.currency.code}
        />
      </DialogContent>
    </Dialog>
  );
};
