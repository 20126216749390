import {
  initialPaxCount,
  MAXIMUM_PASSENGERS_COUNT,
  TravelerPickerProps,
} from "@b2bportal/core-flights";
import { useEffect, useMemo, useState } from "react";
import { IPassengerCounts, SearchEvents } from "@overrides/types";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";

export const useFlightPassengerSelection = (props: TravelerPickerProps) => {
  const paxCount = props?.paxCount ?? initialPaxCount;
  const maximumCount = props?.maximumCount ?? MAXIMUM_PASSENGERS_COUNT;
  const { t } = useI18nContext();
  const { updatePaxCount, unlimited } = props;
  useEffect(() => {
    if (paxCount !== travelerSelection) {
      setTravelerSelection(paxCount);
    }
  }, [paxCount]);
  const numTravelers = useMemo(
    () => Object.values(paxCount).reduce((a, b) => a + b, 0),
    [paxCount]
  );
  const [travelerSelection, setTravelerSelection] =
    useState<IPassengerCounts>(paxCount);
  const [openPassengerSelectionDialog, setOpenPassengerSelectionDialog] =
    useState(false);

  const closeDialog = () => setOpenPassengerSelectionDialog(false);

  const handleOnChange = () => {
    trackEvent({
      eventName: SearchEvents.SEARCH_TRAVELERS_UPDATED,
      properties: undefined,
    });
    updatePaxCount(travelerSelection);
    closeDialog();
  };

  const { adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount } =
    paxCount;

  const maximumExceeded =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount >
      maximumCount;

  const applyButtonText = t("core-flights.search.passengerSelection.apply");
  return {
    context: {
      maximumExceeded,
      applyButtonText,
      numTravelers,
      travelerSelection,
      selectedPassengerLabel: t(
        "core-flights.search.passengerSelection.passengerCount",
        {
          count: numTravelers,
        }
      ),
    },
    handlers: {
      handleOnChange,
      closeDialog,
      setOpenPassengerSelectionDialog,
      openPassengerSelectionDialog,
      setTravelerSelection,
    },
  };
};
