import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Radio } from "@material-ui/core";
import {
  CancellationPolicyEnum,
  type RoomInfoProducts,
  type RoomProduct,
} from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useRenderLodgingStrikethroughPrice } from "@hopper-b2b/utilities";
import { LodgingCardOriginalPrice } from "../LodgingCardOriginalPrice";
import styles from "./styles.module.scss";

export interface RoomDetailsSelectProps {
  room: RoomInfoProducts;
  onChange: (product: RoomProduct) => void;
  nights: number;
  DetailsModal: JSX.Element;
  selectedId: string;
}

const RoomDetailsSelect = ({
  room,
  onChange,
  nights,
  DetailsModal,
  selectedId,
}: RoomDetailsSelectProps) => {
  const { t } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();
  const renderLodgingStrikethroughPriceActive =
    useRenderLodgingStrikethroughPrice();
  const [selectedPricePerNight, setSelectedPricePerNight] = useState(null);
  const [selectedTotalPrice, setSelectedTotalPrice] = useState(null);
  const [nightlyPriceWithoutDiscounts, setNightlyPriceWithoutDiscounts] =
    useState(null);
  const [nightlyPriceWithDiscounts, setNightlyPriceWithDiscounts] =
    useState(null);

  useEffect(() => {
    if (room?.products) {
      const product = room.products[0];
      setSelectedPricePerNight(formatPrice(product.perNightTotal));
      setSelectedTotalPrice(formatPrice(product.tripTotal));
      setNightlyPriceWithoutDiscounts(
        product.nightlyDiscountAware?.priceWithNoDiscounts
      );
      setNightlyPriceWithDiscounts(product.perNightTotal);
    }
  }, [room]);

  const handleSelectRoom = useCallback(
    (product: RoomProduct) => {
      setSelectedPricePerNight(formatPrice(product.perNightTotal));
      setSelectedTotalPrice(formatPrice(product.tripTotal));
      setNightlyPriceWithoutDiscounts(
        product.nightlyDiscountAware?.priceWithNoDiscounts
      );
      setNightlyPriceWithDiscounts(product.perNightTotal);
      onChange(product);
    },
    [formatPrice, onChange]
  );

  const getIsSelected = useCallback(
    (product: RoomProduct) => selectedId === product?.rateId?.value,
    [selectedId]
  );

  const renderLodgingStrikethroughPrice = useMemo(
    () =>
      renderLodgingStrikethroughPriceActive &&
      nightlyPriceWithoutDiscounts > nightlyPriceWithDiscounts,
    [nightlyPriceWithoutDiscounts, nightlyPriceWithDiscounts]
  );

  return (
    <div
      className={clsx(
        styles.RoomDetailsSelectContainer,
        "RoomDetailsSelectContainer"
      )}
    >
      <fieldset
        className={clsx(styles.RoomDetailsSelect, "RoomDetailsSelect")}
        id={room.roomInfo.roomId}
      >
        <h6 className="title">{t("cancellationPolicyCopy")}</h6>
        {room.products.map((product: RoomProduct) => (
          <RoomProductOption
            room={room}
            product={product}
            onSelect={() => handleSelectRoom(product)}
            selected={getIsSelected(product)}
          />
        ))}
      </fieldset>
      <div className={clsx("price-info", styles.RoomDetailsSelectPrice)}>
        <div className="price-per-night price-info-content">
          <p className="price-info-label">{t("pricePerNightLabel")}</p>
          <div className="price-info-value-container">
            {renderLodgingStrikethroughPrice ? (
              <LodgingCardOriginalPrice
                originalPrice={nightlyPriceWithoutDiscounts}
                finalPrice={nightlyPriceWithDiscounts}
              />
            ) : null}
            <p className="price-info-value">{selectedPricePerNight}</p>
          </div>
        </div>
        <div className="total-price price-info-content">
          <p className="price-info-label">
            {t("totalPriceLabel", {
              count: nights,
            })}
          </p>
          <p className="price-info-value">{selectedTotalPrice}</p>
        </div>
        <p className="taxes-and-fees">{t("taxesAndFeesIncluded")}</p>
      </div>
      {DetailsModal}
    </div>
  );
};

export interface RoomProductOptionProps {
  room: RoomInfoProducts;
  product: RoomProduct;
  selected: boolean;
  onSelect: () => void;
}

const RoomProductOption = ({
  room,
  product,
  selected,
  onSelect,
}: RoomProductOptionProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const getProductSurplus = useCallback(
    (product: RoomProduct) => {
      const defaultSelection = room?.products[0];
      if (
        onlyOption ||
        product?.rateId?.value === defaultSelection?.rateId?.value
      ) {
        return null;
      }
      const selectedPricePerNight = product?.perNightTotal;
      const defaultPricePerNight = defaultSelection?.perNightTotal;
      return {
        currencyCode: selectedPricePerNight.fiat?.currencyCode,
        currencySymbol: selectedPricePerNight.fiat?.currencySymbol,
        value: Math.ceil(
          selectedPricePerNight.fiat?.value - defaultPricePerNight.fiat?.value
        ),
      };
    },
    [room]
  );

  const refundable =
    product.cancellationPolicy.CancellationPolicy ===
    CancellationPolicyEnum.Refundable;
  const cancellationPolicy = product.cancellationPolicy.primaryText;
  const onlyOption = room.products.length === 1;
  const surplus = getProductSurplus(product);

  return (
    <ButtonWrap className="option" onClick={onSelect}>
      <div className="option-selection">
        <Radio
          className={clsx({ hidden: onlyOption })}
          value={product.rateId.value}
          inputProps={{
            "aria-selected": selected,
          }}
          checked={onlyOption ? true : selected}
        />
        <div className="input-label-container">
          <p className="input-label">
            {refundable ? t("refundable") : t("nonrefundable")}
          </p>
          {refundable ? (
            <div className="cancellation-policy">{cancellationPolicy}</div>
          ) : null}
        </div>
      </div>
      {surplus?.value > 0 ? (
        <div className="surplus">+{formatFiatCurrency(surplus)}</div>
      ) : null}
    </ButtonWrap>
  );
};

export default RoomDetailsSelect;
