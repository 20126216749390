import clsx from "clsx";
import { Prices } from "@b2bportal/air-cfar-api";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarExerciseProgress,
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  ClientName,
  FintechProductType,
} from "@hopper-b2b/types";
import { LearnMore } from "@hopper-b2b/ui";
import { getEnvVariables, useDeviceTypes } from "@hopper-b2b/utilities";
import { isEmpty, uniq } from "lodash-es";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getSelectedFlight } from "../../../../../../../../libs/trips/src/pages/TripsList/reducer";
import "./styles.scss";

interface CfarDetailsProps {
  cfarExerciseProgress: CfarExerciseProgress;
  refundPrice: Prices | null;
  isFtc: boolean | null;
  airlineNames: string[];
}
export const CfarDetails = ({
  cfarExerciseProgress,
  refundPrice,
  isFtc,
  airlineNames,
}: CfarDetailsProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t, formatFiatCurrency } = useI18nContext();
  const flight = useSelector(getSelectedFlight);
  const refund = refundPrice
    ? formatFiatCurrency(refundPrice?.fiat, { signDisplay: "never" })
    : "——";

  const trackOpenLearnMore = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.EXERCISE },
    });
  }, []);

  const tenantId = getEnvVariables("clientName");

  const airlineName = useMemo(() => {
    const uniqueAirlines = uniq(airlineNames);
    if (isEmpty(uniqueAirlines)) {
      return null;
    }

    if (uniqueAirlines.length === 1) {
      return uniqueAirlines[0];
    }

    return null;
  }, [airlineNames]);

  const isFtcText = useMemo(() => {
    return airlineName
      ? t("cfarExercise.confirmation.ftc.details", {
          refundAmount: refund,
          airline: airlineName,
        })
      : t("cfarExercise.confirmation.ftc.detailsNoAirline", {
          refundAmount: refund,
        });
  }, [airlineName, refund, t]);

  const descriptionText = useMemo(() => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        return isFtc ? (
          isFtcText
        ) : (
          <Trans
            i18nKey="cfarExercise.confirmation.cash.details"
            components={[<b />]}
            values={{ refundAmount: refund }}
          />
        );

      case CfarExerciseProgress.Completed:
        return (
          <I18nMarkup
            tKey={"cfarExercise.completedCancellation"}
            replacements={{ email: flight?.emailAddress || "" }}
          />
        );

      default:
        return (
          <Trans
            i18nKey="cfarExercise.confirmation.cash.details"
            components={[<b />]}
            values={{ refundAmount: refund }}
          />
        );
    }
  }, [cfarExerciseProgress, isFtc, isFtcText, t, refund, flight?.emailAddress]);

  const renderLinks = () => {
    switch (tenantId) {
      case ClientName.HOPPER: {
        return (
          <span className="links">
            <a
              href="https://www.hopper.com/legal/cfar-learn-more"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackOpenLearnMore()}
            >
              {t("howItWorks")}
            </a>
            <span>&nbsp;|&nbsp;</span>
            <a
              href="https://www.hopper.com/legal/Air-CFAR"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("viewTerms")}
            </a>
          </span>
        );
      }
      case ClientName.UBER:
      default:
        return (
          <LearnMore
            label={t("learnMore")}
            description={
              <I18nMarkup tKey={"cfarOffers.learnMoreDescription"} />
            }
            productType={FintechProductType.CfarExercise}
            descriptionAdditional
            header={t("cfarOffers.learnMoreHeader")}
            title={t("cfarOffers.learnMoreTitle")}
            className="cfar-learn-more"
            onOpen={trackOpenLearnMore}
          />
        );
    }
  };

  return (
    <span
      className={clsx("cfar-exercise-copy-details", { mobile: matchesMobile })}
    >
      {descriptionText}
      &nbsp;
      {cfarExerciseProgress !== CfarExerciseProgress.Completed && renderLinks()}
    </span>
  );
};
