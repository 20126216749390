import {
  FlightSteps,
  NavigationFlow,
  useNavigationFlow,
} from "@b2bportal/core-utilities";
import { useMemo } from "react";

type BreadcrumbStepData = {
  step: string;
  label: string;
  isSelected: boolean;
  onClick: () => void;
};

type UseFlightShopBreadcrumbsResponse = {
  shopSteps: Array<BreadcrumbStepData>;
};

export const useFlightShopBreadcrumbs = ({
  currentStepId,
}: {
  currentStepId: FlightSteps;
}): UseFlightShopBreadcrumbsResponse => {
  const goToStep = NavigationFlow.useGoToStep();

  const {
    flow: { stepOrder, steps },
  } = useNavigationFlow();

  const shopSteps = useMemo(() => {
    return stepOrder.reduce<BreadcrumbStepData[]>((acc, stepId) => {
      const step = steps[stepId];
      if (step.breadcrumb != null) {
        acc.push({
          step: stepId,
          label: step.breadcrumb.label,
          onClick: () => goToStep(stepId, false),
          isSelected: stepId === currentStepId,
        });
      }
      return acc;
    }, []);
  }, [goToStep, stepOrder, steps]);

  return { shopSteps };
};
