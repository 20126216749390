import { RadioGroup } from "@b2bportal/core-ui";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import defaultStyles from "./FlightCategorySelection.module.scss";
import {
  type UseFlightCategorySelectionParams,
  useFlightCategorySelection,
} from "./hooks/useFlightCategorySelection";

export interface IFlightCategoryToggleProps
  extends UseFlightCategorySelectionParams {
  className?: string;
}

export const FlightCategorySelection = (props: IFlightCategoryToggleProps) => {
  const { selectedTripCategoryState, getCategoryOptions, handleOnChange } =
    useFlightCategorySelection(props);

  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCategorySelection,
    defaultStyles
  );
  const [, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCategorySelection
  );

  const radioGroupClassNames = {
    container: cn("radio-group-container"),
    itemContainer: cn("radio-item-container"),
  };

  return (
    <RadioGroup
      options={getCategoryOptions()}
      selectedOption={selectedTripCategoryState}
      onSelectValue={(value) => handleOnChange(value)}
      classNames={radioGroupClassNames}
    />
  );
};
