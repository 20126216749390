import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import defaultStyles from "./ExternalLinkContainer.module.scss";
import { Dialog, DialogTrigger, IframeDialogContent } from "@b2bportal/core-ui";

export interface ExternalLinkContainerProps {
  linkUrl: string;
  displayInModal: boolean;
  content: JSX.Element;
}

export const ExternalLinkContainer = ({
  linkUrl,
  displayInModal,
  content,
}: ExternalLinkContainerProps) => {
  const COMPONENT_KEY = CoreDisruptionComponent.ExternalLinkContainer;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const linkContent = displayInModal ? (
    <DialogTrigger className={cn("dialogTrigger")}>{content}</DialogTrigger>
  ) : (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className={cn("linkContainer")}
    >
      {content}{" "}
    </a>
  );

  return (
    <Dialog>
      {linkContent}
      <IframeDialogContent showHeaderBottomBorder={false}>
        <iframe src={linkUrl} />
      </IframeDialogContent>
    </Dialog>
  );
};

export default ExternalLinkContainer;
