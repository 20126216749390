import { useMemo } from "react";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AlgomerchTag, PlatformComponentProps } from "@b2bportal/core-types";
import defaultStyles from "./AlgomerchTags.module.scss";
import clsx from "clsx";
import { CoreFlightsComponent } from "@b2bportal/core-types";

interface IAlgomerchTagsProps extends PlatformComponentProps {
  onClickTag?: (label?: string) => void;
  tags: AlgomerchTag[];
  tagsLimit?: number;
}

export const AlgomerchTags = ({
  onClickTag,
  tags,
  tagsLimit,
  className,
  children,
}: IAlgomerchTagsProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.AlgomerchTags,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(styles, CoreFlightsComponent.AlgomerchTags);

  const { t } = useI18nContext();

  const labelToText = {
    BestFlight: "algomerch.bestFlightTag.title",
    Cheapest: "algomerch.cheapestTag.title",
    BestQuality: "algomerch.bestQualityTag.title",
    Fastest: "algomerch.fastestTag.title",
  };

  const slicedTags = useMemo(() => {
    return tags.slice(0, tagsLimit);
  }, [tags, tagsLimit]);

  const tagsRender = useMemo(() => {
    return slicedTags.reverse().map((value, index) => (
      <div className={cn("badge")} key={value}>
        {`${t(labelToText[value])}`}
      </div>
    ));
  }, [slicedTags]);

  return slicedTags.length !== 0 ? (
    <div className={clsx(block, className)} onClick={() => onClickTag?.()}>
      {tagsRender} {children}
    </div>
  ) : null;
};
