import { useCallback, useState } from "react";
import type { AirlineCode } from "@hopper-b2b/types";
import {
  ActionLink,
  ButtonWrap,
  IconComponent,
  IconName,
  MobileFloatingSection,
  MobilePopoverCard,
  RadioDropdown,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import Chevron from "../../../assets/client/chevron.svg";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";

export interface IFlightNumberFilterSelectionProps {
  options: { label: AirlineCode; value: AirlineCode }[];
  onChange: (value: string) => void;
  value: string;
  flightNumberOptions: JSX.Element[];
}

const FlightNumberFilterSelection = ({
  options,
  onChange,
  value,
  flightNumberOptions,
}: IFlightNumberFilterSelectionProps) => {
  const { t } = useI18nContext();
  const [flightSelectionOpen, setFlightSelectionOpen] = useState(false);
  const [selectedAirline, setSelectedAirline] = useState(null);

  const airlineSelectedHandler = useCallback(
    (selectedAirline: string) => {
      onChange(selectedAirline);
      setSelectedAirline(
        options.find((option) => option.value === selectedAirline).label
      );
    },
    [onChange, options]
  );

  const flightNumbersClosedHandler = useCallback(() => {
    setFlightSelectionOpen(false);
  }, []);

  const flightNumberSelectHandler = useCallback(() => {
    if (flightNumberOptions.length > 0) {
      setFlightSelectionOpen(true);
    }
  }, [flightNumberOptions]);

  return (
    <>
      <div className="flight-number-filter-selection">
        {/* airline select */}
        <SelectWithHalfSheet
          label={t("searchFilter.airline")}
          selected={selectedAirline}
          ctaLabel={t("select")}
          className={
            "mobile-flight-shop-search-filter-wrapper airline-selection"
          }
          chevronSrc={Chevron}
          modalContent={
            <RadioDropdown
              dropdownLabel={t("searchFilter.airline")}
              options={options.map((option) => {
                return {
                  value: option.value,
                  label: option.label,
                };
              })}
              setOption={(option) => airlineSelectedHandler(option)}
              selected={value}
              showDropdownContentOnly={true}
            />
          }
        />

        {/* flight select */}
        <ButtonWrap onClick={flightNumberSelectHandler}>
          <p className="flight-number-filter-select-title">
            {t("searchFilter.flightNumber")}
          </p>
          <div className="flight-number-filter-select-row">
            <p>{t("select")}</p>
            <img className="down-arrow" alt="down-arrow" src={Chevron} />
          </div>
        </ButtonWrap>
      </div>

      {/* Flight select popover card */}
      <MobilePopoverCard
        open={flightSelectionOpen}
        onClose={flightNumbersClosedHandler}
        fullScreen={true}
        className={"mobile-flight-shop-search-filter full-screen"}
        contentClassName={
          "mobile-flight-shop-search-filter-wrapper flight-selection"
        }
        bottomButton={
          <MobileFloatingSection
            className="mobile-flight-shop-filter-button"
            primaryButton={{
              children: t("searchFilter.apply"),
              onClick: flightNumbersClosedHandler,
            }}
          />
        }
        topRightButton={
          <ActionLink
            className="filter-close-button"
            content={
              <IconComponent
                ariaLabel="Close button icon"
                className={"close-button-icon"}
                name={IconName.Close}
              />
            }
            label="Close"
            onClick={() => setFlightSelectionOpen(false)}
          />
        }
      >
        {/* TODO: Update header for halfsheets */}
        <h1>{t("searchFilter.flightNumber")}</h1>
        {flightNumberOptions}
      </MobilePopoverCard>
    </>
  );
};

export default FlightNumberFilterSelection;
