import { useI18nContext } from "@hopper-b2b/i18n";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  IPassengerCounts,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./PassengerCountPicker.module.scss";
import { Icon } from "@b2bportal/core-ui";

export const MINIMUM_ADULTS_PASSENGERS_COUNT = 1;
export const MAXIMUM_PASSENGERS_COUNT = 6;

export type PassengerCountPickerType = IPassengerCounts;

const getAdultsCount = (counts: PassengerCountPickerType) => {
  return counts.adultsCount;
};

const getChildrenCount = (counts: PassengerCountPickerType) => {
  return counts.childrenCount;
};

const getInfantsInSeatCount = (counts: PassengerCountPickerType) => {
  return counts.infantsInSeatCount;
};

const getInfantsOnLapCount = (counts: PassengerCountPickerType) => {
  return counts.infantsOnLapCount;
};

export interface PassengerCountPickerComponentProps
  extends PlatformComponentProps {
  setPassengerCounts: (passengerCounts: PassengerCountPickerType) => void;
  counts: PassengerCountPickerType;
  minimumCount?: number;
  maximumCount?: number;
  titles?: PassengerCountPickerTitles;
  warningMessage?: string;
  unlimited?: boolean;
  className?: string;
  hideChildrenSubtitle?: boolean;
  modalSubtitle?: string;
  onChange?: (passengerCounts: PassengerCountPickerType) => void;
}

export type PassengerCountPickerTitles = {
  modalTitle: string;
  modalSubtitle?: string;
  adultTitle: string;
  adultSubtitle: string;
  childrenTitle: string;
  childrenSubtitle: string;
  infantSeatTitle?: string;
  infantSeatSubtitle?: string;
  infantLapTitle?: string;
  infantLapSubtitle?: string;
};

export const PassengerCountPicker = ({
  children,
  className,
  ...props
}: PassengerCountPickerComponentProps) => {
  const { t } = useI18nContext();
  const {
    onChange,
    counts,
    titles = {
      modalSubtitle: props.modalSubtitle || null,
      adultTitle: t("passengerCount.adultTitle"),
      adultSubtitle: "",
      childrenTitle: t("passengerCount.childrenTitle"),
      childrenSubtitle: props.hideChildrenSubtitle
        ? ""
        : t("passengerCount.childrenSubtitle"),
      childrenAgeMessage: t("guestCount.childrenAgeMessage"),
      childrenAgePlaceholderKey: "passengerCount.childrenAgePlaceholder",
      infantLapTitle: t("passengerCount.infantLapTitle"),
      infantLapSubtitle: t("passengerCount.infantLapSubtitle"),
      infantSeatTitle: t("passengerCount.infantSeatTitle"),
      infantSeatSubtitle: t("passengerCount.infantSeatSubtitle"),
    },
    minimumCount = MINIMUM_ADULTS_PASSENGERS_COUNT,
    maximumCount = MAXIMUM_PASSENGERS_COUNT,
    warningMessage = t("passengerCount.warningMessage", {
      maximumCount: MAXIMUM_PASSENGERS_COUNT,
    }),
    unlimited,
  } = props;

  const [adultsCount, setAdultsCount] = useState(getAdultsCount(counts));
  const [childrenCount, setChildrenCount] = useState(getChildrenCount(counts));
  const [infantsInSeatCount, setInfantsInSeatCount] = useState(
    getInfantsInSeatCount(counts)
  );
  const [infantsOnLapCount, setInfantsOnLapCount] = useState(
    getInfantsOnLapCount(counts)
  );

  const maximumReached =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount >=
      maximumCount;

  useEffect(() => {
    onChange?.({
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    });
  }, [
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    onChange,
  ]);

  const styles = useFlightStyles(
    CoreFlightsComponent.PassengerCountPicker,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.PassengerCountPicker
  );

  return (
    <div className={clsx(block, className)}>
      <div className={cn("container")}>
        {titles.modalSubtitle ? (
          <div className={cn("subtitle")}>{titles.modalSubtitle}</div>
        ) : null}
        <div className={cn("pickers-section")}>
          <CountPicker
            title={titles.adultTitle}
            subtitle={titles.adultSubtitle}
            classNames={cn("adults")}
            minimumCount={minimumCount}
            maximumReached={maximumReached}
            count={adultsCount}
            setCount={setAdultsCount}
          />
          <CountPicker
            title={titles.childrenTitle}
            subtitle={titles.childrenSubtitle}
            classNames={cn("children")}
            minimumCount={0}
            maximumReached={maximumReached}
            count={childrenCount}
            setCount={setChildrenCount}
          />
          {titles.infantSeatTitle && (
            <CountPicker
              title={titles.infantSeatTitle}
              subtitle={titles.infantSeatSubtitle}
              classNames={cn("infants-in-seat")}
              minimumCount={0}
              maximumReached={maximumReached}
              count={infantsInSeatCount}
              setCount={setInfantsInSeatCount}
            />
          )}
          {titles.infantLapTitle && (
            <CountPicker
              title={titles.infantLapTitle}
              subtitle={titles.infantLapSubtitle}
              classNames={cn("infants-on-lap")}
              minimumCount={0}
              maximumReached={maximumReached}
              count={infantsOnLapCount}
              setCount={setInfantsOnLapCount}
            />
          )}
        </div>
        <div className={cn("count-button-container")}>
          {!unlimited && props.modalSubtitle && warningMessage ? (
            <div className={cn("warning-message")}>{warningMessage}</div>
          ) : null}
        </div>
      </div>
      {className}
    </div>
  );
};

export type CountPickProps = {
  title: string;
  subtitle?: string;
  classNames: string;
  minimumCount?: number;
  maximumReached?: boolean;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
};

const CountPicker = ({
  title,
  subtitle,
  classNames,
  minimumCount,
  count,
  setCount,
  maximumReached = false,
}: CountPickProps) => {
  const minusDisabled = count <= (minimumCount ?? 0);

  const styles = useFlightStyles(
    CoreFlightsComponent.CountPicker,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(styles, CoreFlightsComponent.CountPicker);

  const { t } = useI18nContext();

  return (
    <div className={clsx(cn("container"), classNames)}>
      <div className={cn("description-container")}>
        <div className={cn("passenger-type-description")}>
          <div className={cn("passenger-type-title")}>{title}</div>
          {subtitle && (
            <div className={cn("passenger-type-subtitle")}>{subtitle}</div>
          )}
        </div>
      </div>
      <div className={cn("picker-container")}>
        <button
          // This should be translatable
          aria-label={t(
            "core-flights.search.decreaseAmountOfTravelersByCategory",
            {
              category: title || subtitle,
            }
          )}
          className={clsx(cn("picker-button-minus"), cn("picker-button"))}
          disabled={minusDisabled}
          onClick={() => {
            if (!minusDisabled) {
              setCount((prevCount) => prevCount - 1);
            }
          }}
        >
          <div
            className={clsx(cn("button-content-wrapper"), {
              [cn("disabled")]: minusDisabled,
            })}
          >
            <Icon iconName={IconNameEnum.minus} />
          </div>
        </button>
        <div className={cn("passenger-count-number-container")}>
          <div className={cn("passenger-count-number")}>
            {count}
            <span
              className={cn("passenger-count-alert")}
              aria-live="polite"
              aria-atomic={true}
            >
              {subtitle ? `${count} ${title} ${subtitle}` : `${count} ${title}`}
            </span>
          </div>
        </div>
        <button
          // This should be translatable
          aria-label={t(
            "core-flights.search.increaseAmountOfTravelersByCategory",
            {
              category: title || subtitle,
            }
          )}
          className={clsx(cn("passenger-count-picker-button"), "plus")}
          onClick={() => setCount((prevCount) => prevCount + 1)}
          disabled={maximumReached}
        >
          <div
            className={clsx("button-content-wrapper", {
              [cn("disabled")]: maximumReached,
            })}
          >
            {" "}
            <Icon iconName={IconNameEnum.plus} />
          </div>
        </button>
      </div>
    </div>
  );
};
