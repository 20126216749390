import type { Airport, TripSlice } from "@b2bportal/air-shopping-api";
import { MiniFlightSummaryPanel } from "@hopper-b2b/ui";
import "./styles.scss";

interface FlightShopPanelProps {
  airports: { [key: string]: Airport };
  tripSlice: TripSlice;
}

export const FlightShopSummaryPanel = ({
  airports,
  tripSlice,
}: FlightShopPanelProps) => (
  <MiniFlightSummaryPanel tripSlice={tripSlice} airports={airports} />
);
