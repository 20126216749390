import { Fragment, useCallback, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Product } from "@b2bportal/purchase-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { placeLabelToCity, ViewOption } from "@hopper-b2b/lodging-utils";
import {
  ClientName,
  LodgingShopTrackingEvents,
  RenderLodgingShopValuePropsVariant,
} from "@hopper-b2b/types";
import { IndeterminateLoader, Slot } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  PATH_HOTELS_BOOK_AVAILABILITY,
  useDeviceTypes,
  useEnableWallet,
  useFeatureFlagsContext,
  useHideLodgingMap,
  useRenderHotelMapAsDefaultView,
  useRenderLodgingShopValueProps,
} from "@hopper-b2b/utilities";
import { CircularProgress, Divider, Typography } from "@material-ui/core";
import { useTrackEvents } from "../../tracking";
import { LodgingSearchRoot } from "../search/Search";
import { useWalletOffers } from "../wallet/hooks/useWalletOffers";
import { getWalletOffersNoCredits } from "../wallet/reducer";
import { setView } from "./actions/actions";
import "./Availability.scss";
import AvailabilityMap, { AvailabilityListMapToggle } from "./AvailabilityMap";
import { EmptyState } from "./components/EmptyState";
import { FiltersModal } from "./components/FiltersModal";
import { LodgingCard, LodgingCardSkeleton } from "./components/LodgingCard";
import { MobileHorizontalScrollList } from "./components/MobileHorizontalScrollList";
import { SortInput } from "./components/SortInput";
import type { AvailabilityProps } from "./container";
import { useAvailabilitySearch } from "./hooks/useAvailabilitySearch";
import {
  getView,
  type ICentroid,
  type ILodgingAvailabilityState,
} from "./reducer";

const LODGINGS_GRID_INITIAL_DISPLAY_COUNT = 50;
const LODGINGS_GRID_INCREMENT = 20;

export type QueryStayValuesType = Pick<
  ILodgingAvailabilityState,
  "guests" | "rooms" | "fromDate" | "untilDate"
>;

export const Availability = ({
  isOverFiltered,
  place,
  fromDate,
  untilDate,
  totalLodgingCount,
  guests,
  rooms,
  filters,
  filterBoundaries,
  sort,
  setFilterFreeCancellation,
  setFilterStarRating,
  setFilterUserRating,
  setFilterAmenities,
  setFilterAmenitiesType,
  setFilterPriceRange,
  resetFilters,
  setSort,
}: AvailabilityProps) => {
  const dispatch = useDispatch();

  const featureFlags = useFeatureFlagsContext();
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [mobileCardCentroidInView, setMobileCardCentroidInView] =
    useState<ICentroid | null>(null);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [lodgingsGridCount, setLodgingsGridCount] = useState(
    LODGINGS_GRID_INITIAL_DISPLAY_COUNT
  );
  const isLloydsClient = getEnvVariables("clientName") === ClientName.LLOYDS;

  const isHorizontalScrollEnabled =
    Boolean(featureFlags.showHorizontalScrollListInMobileMap) && matchesMobile;

  const trackEvent = useTrackEvents();

  const renderLodgingShopValueProps = useRenderLodgingShopValueProps();
  const showMapAsDefault = useRenderHotelMapAsDefaultView();
  const hasVouchersEnabled = useEnableWallet();
  const offers = useSelector(getWalletOffersNoCredits);
  const { bestOffer } = useWalletOffers();

  const hideLodgingMap = useHideLodgingMap();

  const { loading, lodgings, search, searchMapArea } = useAvailabilitySearch();

  const view = useSelector(getView);

  const [selectedHotelID, setSelectedHotelID] = useState<string | null>();

  const onUpdateView = useCallback(
    (val: ViewOption) => {
      setSelectedHotelID(null);
      dispatch(setView(val));
    },
    [dispatch]
  );

  const onScrollNextPage = useCallback(() => {
    setLodgingsGridCount((prev) => prev + LODGINGS_GRID_INCREMENT);
  }, []);

  const handleAmenitiesChange = useCallback(
    (value: string[]) => {
      setFilterAmenities(value);
    },
    [setFilterAmenities]
  );

  const handleAmenitiesTypeChange = useCallback(
    (value: boolean) => {
      setFilterAmenitiesType(value);
    },
    [setFilterAmenitiesType]
  );

  const openFiltersModal = useCallback(() => {
    setShowFiltersModal(true);
    trackEvent(LodgingShopTrackingEvents.hotel_tapped_filter);
  }, [trackEvent]);

  const closeFiltersModal = useCallback(() => {
    setShowFiltersModal(false);
  }, []);

  const handleDeleteFilter = useCallback(
    (key: string) => {
      switch (key) {
        case "starRating":
          setFilterStarRating([]);
          break;
        case "amenities":
          setFilterAmenities([]);
          break;
        case "userRating":
          setFilterUserRating(0);
          break;
        case "priceRange":
          setFilterPriceRange({
            min: filters[key].lowest,
            max: filters[key].highest,
            lowest: filters[key].lowest,
            highest: filters[key].highest,
          });
          break;
        default:
          break;
      }
    },
    [
      setFilterStarRating,
      setFilterAmenities,
      setFilterUserRating,
      setFilterPriceRange,
      filters,
    ]
  );

  const goBackToListView = useCallback(() => {
    setSelectedHotelID(null);
    onUpdateView(ViewOption.LIST);
  }, [onUpdateView]);

  const handleOfferBannerTap = useCallback(() => {
    trackEvent(LodgingShopTrackingEvents.viewed_offer, {
      screen: "availability",
    });
  }, [trackEvent]);

  const showMapToggle = useMemo(
    () =>
      matchesMobile &&
      !hideLodgingMap &&
      !loading &&
      !(view === ViewOption.MAP && isHorizontalScrollEnabled),
    [hideLodgingMap, isHorizontalScrollEnabled, loading, matchesMobile, view]
  );

  const renderValueProp = useCallback(
    (index: number) => {
      switch (renderLodgingShopValueProps) {
        case RenderLodgingShopValuePropsVariant.Installments:
          if (index === 0) {
            return <Slot id="installments-banner" />;
          }
          break;
        case RenderLodgingShopValuePropsVariant.PriceMatch:
          if (index === 0) {
            return (
              <Slot
                id="best-price-guarantee-banner"
                className="best-price-guarantee-banner"
                product={Product.Hotel}
              />
            );
          }
          break;
        case RenderLodgingShopValuePropsVariant.InstallmentsAndPriceMatch:
          if (index === 0) {
            return <Slot id="installments-banner" />;
          } else if (index === 1) {
            return (
              <Slot
                id="best-price-guarantee-banner"
                className="best-price-guarantee-banner"
                product={Product.Hotel}
              />
            );
          }
          break;
        default:
          return null;
      }
    },
    [renderLodgingShopValueProps]
  );

  return (
    <>
      <div className="Availability">
        <div className="Availability-heading">
          <Slot
            id="lodging-availability-search"
            initialDestination={place}
            initialCheckinDate={fromDate}
            initialCheckoutDate={untilDate}
            initialGuestCount={{
              adults: guests?.adults ?? 2,
              children: guests?.children ?? [],
              rooms,
            }}
            onSearch={search}
            filters={filters}
            openFiltersModal={openFiltersModal}
            onStarRatingChange={setFilterStarRating}
            onUserRatingChange={setFilterUserRating}
            onFilterPriceRangeChange={setFilterPriceRange}
            enableRooms={true}
            openLocationModal={openLocationModal}
            openCalendarModal={openCalendarModal}
            setOpenLocationModal={setOpenLocationModal}
            setOpenCalendarModal={setOpenCalendarModal}
            filterBoundaries={filterBoundaries}
            onAmenitiesChange={handleAmenitiesChange}
            onAmenitiesTypeChange={handleAmenitiesTypeChange}
          />
        </div>
        {loading ? (
          <div className="Availability-loader">
            <IndeterminateLoader />
          </div>
        ) : null}
        <div className="Availability-lodgings-header">
          <Slot
            id="hotel-shop-header"
            initialDestination={place}
            initialCheckinDate={fromDate}
            initialCheckoutDate={untilDate}
            openFiltersModal={openFiltersModal}
            resetFilters={resetFilters}
            filters={filters}
            deleteFilter={handleDeleteFilter}
            lodgingCount={totalLodgingCount}
            lodgingFilteredCount={lodgings.length}
            sort={sort}
            setSort={setSort}
            loading={loading}
            view={view}
            component={
              <>
                <Typography
                  className="Availability-lodgings-header-title"
                  variant="body2"
                  color="textPrimary"
                  component="h1"
                >
                  {loading ? (
                    <I18nMarkup tKey="searchingHotels"></I18nMarkup>
                  ) : place?.label ? (
                    <I18nMarkup
                      tKey="viewingHotelsInDestination"
                      replacements={{
                        destination: placeLabelToCity(place.label),
                      }}
                    ></I18nMarkup>
                  ) : (
                    t("viewingHotels")
                  )}
                </Typography>
                {!matchesMobile && <SortInput value={sort} setSort={setSort} />}
              </>
            }
          />
        </div>
        <div className="Availability-lodgings">
          {view === ViewOption.LIST && loading ? (
            <div className="Availability-lodgings-grid">
              {[1, 2].map((skeletonNumber) => (
                <LodgingCardSkeleton key={skeletonNumber} />
              ))}
            </div>
          ) : lodgings?.length > 0 &&
            (!matchesMobile || (matchesMobile && view === ViewOption.LIST)) ? (
            <InfiniteScroll
              className="Availability-lodgings-grid"
              key={matchesMobile ? "is-mobile" : "is-desktop"}
              dataLength={lodgingsGridCount}
              hasMore={lodgingsGridCount < lodgings.length}
              next={onScrollNextPage}
              loader={
                <div className="infinite-scroll-loader">
                  <CircularProgress
                    color="primary"
                    size={32}
                    aria-label={t("loadingMoreHotels")}
                  />
                </div>
              }
            >
              {(hasVouchersEnabled && bestOffer) || isLloydsClient ? (
                <Slot
                  id="hotel-voucher-offer-banner"
                  isEligible
                  offers={offers}
                  offer={bestOffer}
                  showMore
                  onClick={handleOfferBannerTap}
                />
              ) : null}
              {!hideLodgingMap && matchesMobile ? (
                <Slot
                  id="hotel-list-map-card"
                  onClick={() => onUpdateView(ViewOption.MAP)}
                />
              ) : null}
              {lodgings.slice(0, lodgingsGridCount).map((item, index) => (
                <Fragment key={index}>
                  <LodgingCard
                    id={item.lodging.id}
                    lodging={item}
                    lodgingListIndex={index}
                    key={"lodgingCard-" + item.lodging.id}
                    setOveredId={setHoveredId}
                  />
                  {!matchesMobile && <Divider />}
                  {renderLodgingShopValueProps ? renderValueProp(index) : null}
                </Fragment>
              ))}
            </InfiniteScroll>
          ) : view === ViewOption.LIST ? (
            // double check if the view is a list and not a map, check this within the map
            <Slot
              id="lodging-availability-empty-state"
              component={
                <EmptyState
                  resetFilters={isOverFiltered ? resetFilters : null}
                />
              }
            />
          ) : null}
        </div>
        {/* Availability view switcher */}
        {showMapToggle ? (
          <AvailabilityListMapToggle view={view} onChange={onUpdateView} />
        ) : undefined}
        {!hideLodgingMap &&
        (!matchesMobile || (matchesMobile && view === ViewOption.MAP)) ? (
          <>
            <AvailabilityMap
              isOverFiltered={isOverFiltered}
              loading={loading}
              lodgings={lodgings}
              defaultHoveredId={hoveredId}
              onSearchArea={searchMapArea}
              onBackClick={goBackToListView}
              selectedHotelID={selectedHotelID}
              setSelectedHotelID={(id: string) => {
                setHoveredId(null);
                setSelectedHotelID(id);
              }}
              mobileCardCentroidInView={mobileCardCentroidInView}
            />
            {isHorizontalScrollEnabled && matchesMobile ? (
              <MobileHorizontalScrollList
                setHoveredId={(id: string) => {
                  setHoveredId(id);
                  setSelectedHotelID(null);
                }}
                selectedHotelID={selectedHotelID}
                setMobileCardCentroidInView={setMobileCardCentroidInView}
              />
            ) : null}
          </>
        ) : undefined}
      </div>

      <Slot
        id="hotel-shop-filters-modal"
        open={showFiltersModal}
        filters={filters}
        filterBoundaries={filterBoundaries}
        closeFiltersModal={closeFiltersModal}
        onStarRatingChange={setFilterStarRating}
        onUserRatingChange={setFilterUserRating}
        onFilterPriceRangeChange={setFilterPriceRange}
        onFreeCancellationChange={setFilterFreeCancellation}
        onAmenitiesChange={handleAmenitiesChange}
        onAmenitiesTypeChange={handleAmenitiesTypeChange}
        resetFilters={resetFilters}
        sort={sort}
        setSort={setSort}
        component={
          <FiltersModal
            open={showFiltersModal}
            filters={filters}
            filterBoundaries={filterBoundaries}
            closeFiltersModal={closeFiltersModal}
            onStarRatingChange={setFilterStarRating}
            onUserRatingChange={setFilterUserRating}
            onFilterPriceRangeChange={setFilterPriceRange}
            onFreeCancellationChange={setFilterFreeCancellation}
            onAmenitiesChange={handleAmenitiesChange}
            onAmenitiesTypeChange={handleAmenitiesTypeChange}
            resetFilters={resetFilters}
            sort={sort}
            setSort={setSort}
          />
        }
      />
      <Slot
        id="hotel-shop-search-modal"
        open={openLocationModal || openCalendarModal}
        onClose={() => {
          setOpenLocationModal(false);
          setOpenCalendarModal(false);
        }}
        title={openCalendarModal ? t("dates") : t("hotels")}
        LodgingSearchRoot={
          <LodgingSearchRoot
            onSearch={(path: string) => {
              window.location.href = path.replace(
                PATH_HOTELS_BOOK_AVAILABILITY,
                ""
              );
            }}
            isAirEnabled={false}
            isLodgingEnabled={true}
            className="hotel-shop-search-modal"
            openCalendarModal={openCalendarModal}
            modal
            initialLocation={place}
            showMapAsDefault={showMapAsDefault}
            guests={guests}
          />
        }
      />
    </>
  );
};
