import { CoreFlightsComponent } from "@b2bportal/core-types";
import defaultStyles from "./DateSelectionButton.module.scss";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import dayjs from "dayjs";
import { useSearchParameters } from "../../../search/components";
import { useI18nContext, type DateTimeFormatStyle } from "@hopper-b2b/i18n";
import { useCallback } from "react";

export type DateSelectionButtonProps = {
  onClick: () => void;
};

export type DateSelectionButtonComponentProps = {
  departureDate: string;
  returnDate?: string;
  format?: DateTimeFormatStyle;
} & DateSelectionButtonProps;

export const DateSelectionButtonComponent = ({
  onClick,
  departureDate,
  returnDate,
  format,
}: DateSelectionButtonComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.DateSelectionButton;

  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block] = useModuleBEM(styles, COMPONENT_KEY);
  const { formatDateTime, DateTimeFormatStyle } = useI18nContext();
  const dateFormat =
    format ?? DateTimeFormatStyle.Custom({ month: "short", day: "numeric" });

  const formatDate = useCallback(
    (date: Date) => {
      return formatDateTime(date, dateFormat);
    },
    [formatDateTime, dateFormat]
  );

  const label = returnDate
    ? `${formatDate(dayjs(departureDate).toDate())} - ${formatDate(
        dayjs(returnDate).toDate()
      )}`
    : formatDate(dayjs(departureDate).toDate());

  return (
    <button className={block} onClick={onClick}>
      {label}
    </button>
  );
};

export const DateSelectionButton = ({ onClick }: DateSelectionButtonProps) => {
  const { departureDate, returnDate } = useSearchParameters();

  if (!departureDate) {
    // We don't have a use case for an empty departure date. It would be applicable if we use this button to start the search only
    return null;
  }

  return (
    <DateSelectionButtonComponent
      departureDate={departureDate}
      returnDate={returnDate}
      onClick={onClick}
    />
  );
};

export default DateSelectionButton;
