import { SvgrComponent } from "@b2bportal/core-types";
import { TenantIconNameEnum, TenantImageNameEnum } from "@tenant/types";
import { createContext, PropsWithChildren, useContext } from "react";
import {
  HotelsAmenityIconSet,
  DisruptionIconNameEnum,
  CFARIconNameEnum,
  PriceFreezeIconNameEnum,
} from "./product";

export interface IAssetContextProps {
  icons?: IconSet;
  images?: ImageSet;
}

export enum CoreImageNameEnum {
  flightBookingConfirmationImage = "flightBookingConfirmationImage",
  walletCardImage = "walletCardImage",
  logo = "logo",
  homepageBackground = "homepageBackground",
  pageBackground = "pageBackground",
  flightLoadingImage = "flightLoadingImage",
  hotelLoadingImage = "hotelLoadingImage",
  tripsLoadingImage = "tripsLoadingImage",
  tripsHeadingBackground = "tripsHeadingBackground",
  roomPlaceholder = "roomPlaceholder",
  htsPoweredByLogo = "htsPoweredByLogo",
}

export type CoreImageSet = {
  [key in CoreIconNameEnum]: IconType;
};

export type IconType = SvgrComponent;

export enum CoreIconNameEnum {
  info = "info",
  warning = "warning",
  error = "error",
  dropdownTriangle = "dropdownTriangle",
  dropdownArrow = "dropdownArrow",
  backIcon = "backIcon",
  actionIcon = "actionIcon",
  chevronRight = "chevronRight",
  calendarSeparatorIcon = "calendarSeparatorIcon",
  fareDetailsAvailable = "fareDetailsAvailable",
  fareDetailsUnavailable = "fareDetailsUnavailable",
  fareDetailsCharge = "fareDetailsCharge",
  fareDetailsGeneric = "fareDetailsGeneric",
  filter = "filter",
  close = "close",
  nextIcon = "nextIcon",
  airplane = "airplane",
  airplaneDiagonal = "airplaneDiagonal",
  airplaneArrive = "airplaneArrive",
  airplaneDeparture = "airplaneDeparture",
  calendar = "calendar",
  filledCalendar = "filledCalendar",
  plus = "plus",
  plusCircle = "plusCircle",
  minus = "minus",
  mapPin = "mapPin",
  searchForFlights = "searchForFlights",
  passengerSelection = "passengerSelection",
  check = "check",
  checkFilled = "checkFilled",
  edit = "edit",
  chevronDown = "chevronDown",
  chevronUp = "chevronUp",
  guest = "guest",
  search = "search",
  externalLink = "externalLink",
  carouselRightArrow = "carouselRightArrow",
  carouselLeftArrow = "carouselLeftArrow",
  tripadvisor = "tripadvisor",
  starFilled = "starFilled",
  bed = "bed",
  checkCircle = "checkCircle",
  warningCircle = "warningCircle",
  premiumDisruptionAssistance = "premiumDisruptionAssistance",
  dismiss = "dismiss",
  hotel = "hotel",
  flight = "flight",
  currency = "currency",
  rightArrow = "rightArrow",
  credits = "credits",
  map = "map",
  list = "list",
  baggageChecked = "baggageChecked",
  flightOutline = "flightOutline",
  warningTriangle = "warningTriangle",
  coins = "coins",
  tenantLogo = "tenantLogo",
  disruptionExerciseFullyCoveredIcon = "money-fill",
  tripCardLink = "tripCardLink",
  disruptionOriginSegmentIcon = "disruptionOriginSegmentIcon",
  car = "car",
  clock = "clock",
}

export type ImageNameEnum = CoreImageNameEnum | TenantImageNameEnum;
export const ImageNameEnum = { ...CoreImageNameEnum, ...TenantImageNameEnum };

export type IconNameEnum =
  | CoreIconNameEnum
  | TenantIconNameEnum
  | DisruptionIconNameEnum
  | CFARIconNameEnum
  | PriceFreezeIconNameEnum;

export const IconNameEnum = {
  ...CoreIconNameEnum,
  ...TenantIconNameEnum,
  ...DisruptionIconNameEnum,
  ...CFARIconNameEnum,
  ...PriceFreezeIconNameEnum,
};

export type ImageSet = {
  [key in ImageNameEnum]?: string;
};
export type IconSet = {
  [key in IconNameEnum]?: IconType;
} & {
  hotelAmenities?: HotelsAmenityIconSet;
};

export interface IAssetContextProvider {
  context: IAssetContextProps;
}

const AssetContext = createContext<IAssetContextProps | undefined>(undefined);

export function useAssetContext(): IAssetContextProps {
  const ctx = useContext(AssetContext);
  if (ctx == null) {
    throw new Error(`must be used within a AssetContextProvider`);
  }
  return ctx;
}

export const useTenantIcons = (): IconSet => {
  const ctx = useAssetContext();
  return ctx.icons ?? {};
};

export const useTenantImages = (): ImageSet => {
  const ctx = useAssetContext();
  return ctx.images ?? {};
};

export const AssetContextProvider = ({
  children,
  context,
}: PropsWithChildren<IAssetContextProvider>) => {
  return (
    <AssetContext.Provider value={context}>{children}</AssetContext.Provider>
  );
};

export const useTenantHotelAmenitiesIcons = (): HotelsAmenityIconSet => {
  const ctx = useAssetContext();
  return ctx.icons?.hotelAmenities ?? {};
};
