import {
  FirstDayOfWeek,
  MonthType,
  OnDatesChangeProps,
  useDatepicker,
} from "@datepicker-react/hooks";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getLang, useI18nContext } from "@hopper-b2b/i18n";

import { Month } from "../Month";
import defaultStyles from "./HorizontalView.module.scss";
import { firstWeekDay } from "../../DateRangePicker";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";

export interface IHorizontalViewProps {
  startDate: Date | null;
  cleanedEndDate: Date | null;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  numberOfMonths: number;
  showPriceRangeTags?: boolean;
  priceTags?: string[];
  priceTagsElement: JSX.Element;
  className?: string;
}

export const HorizontalView = ({
  startDate,
  cleanedEndDate,
  setStartDate,
  setEndDate,
  minAllowedDate,
  maxAllowedDate,
  numberOfMonths,
  priceTagsElement,
  priceTags,
  className,
}: IHorizontalViewProps) => {
  const styles = useUiStyles(
    CoreUiComponents.DatePickerHorizontalView,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.DatePickerHorizontalView
  );

  const { brand } = useI18nContext();
  const handleDateChange = (props: OnDatesChangeProps) => {
    const { startDate, endDate } = props;
    setStartDate && setStartDate(startDate);
    setEndDate && setEndDate(endDate);
  };

  const {
    firstDayOfWeek,
    activeMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
  } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate,
    endDate: cleanedEndDate,
    focusedInput: null,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const [firstMonth, secondMonth] = activeMonths;

  const [currentFocusDate, setCurrentFocusDate] = useState<Date | undefined>(
    dayjs().toDate()
  );

  const [isDateFocused, setIsDateFocused] = useState<boolean>(true);

  const leftArrowDisabled = minAllowedDate
    ? isLessThanMonth(firstMonth, minAllowedDate)
    : false;

  const rightArrowDisabled = maxAllowedDate
    ? isGreaterThanMonth(secondMonth, maxAllowedDate)
    : false;

  const getYearMonthFloat = (date: Date | undefined) =>
    parseFloat(`${dayjs(date).format("YYYYMM")}`);

  useEffect(() => {
    const currentFocusYearMonth = getYearMonthFloat(currentFocusDate);
    const secondMonthYearMonth = getYearMonthFloat(secondMonth.date);
    const firstMonthYearMonth = getYearMonthFloat(firstMonth.date);
    if (currentFocusYearMonth > secondMonthYearMonth && !rightArrowDisabled)
      goToNextMonthsByOneMonth();

    if (currentFocusYearMonth < firstMonthYearMonth && !leftArrowDisabled)
      goToPreviousMonthsByOneMonth();

    if (dayjs(currentFocusDate).isBefore(dayjs(), "date"))
      setCurrentFocusDate(dayjs().toDate());
  }, [currentFocusDate]);

  useEffect(() => {
    if (dayjs(currentFocusDate).isBefore(dayjs(firstMonth.date), "month"))
      setCurrentFocusDate(
        dayjs(firstMonth.date).date(dayjs(currentFocusDate).date()).toDate()
      );
    if (dayjs(currentFocusDate).isAfter(dayjs(secondMonth.date), "month"))
      setCurrentFocusDate(
        dayjs(secondMonth.date).date(dayjs(currentFocusDate).date()).toDate()
      );
  }, [firstMonth, secondMonth]);

  const renderMonths = () =>
    [firstMonth, secondMonth].map((month: MonthType) => {
      return (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          firstDayOfWeek={firstDayOfWeek}
          date={month.date}
          className={className}
          currentFocusDate={currentFocusDate}
          setCurrentFocusDate={setCurrentFocusDate}
          setIsDateFocused={setIsDateFocused}
          isDateFocused={isDateFocused}
          priceTags={priceTags}
          monthFormat={brand?.calendarMonthFormatStyle}
        />
      );
    });

  return (
    <div className={clsx(block, className)}>
      <div className={cn("date-range-picker-section")}>
        <div className={clsx(cn("month-shift-button-container"), cn("left"))}>
          <button
            className={cn("month-shift-button")}
            label="Go to previous months button"
            onClick={goToPreviousMonthsByOneMonth}
            showTappableArea={true}
            disabled={leftArrowDisabled}
          >
            <FontAwesomeIcon
              icon={faAngleLeft as IconProp}
              className={cn("month-shift-icon")}
            />
          </button>
        </div>
        {renderMonths()}
        <div className={clsx(cn("month-shift-button-container"), cn("right"))}>
          <button
            className={cn("month-shift-button")}
            label="Go to next months button"
            onClick={goToNextMonthsByOneMonth}
            showTappableArea={true}
            disabled={rightArrowDisabled}
          >
            <FontAwesomeIcon
              icon={faAngleRight as IconProp}
              className={cn("month-shift-icon")}
            />
          </button>
        </div>
      </div>
      {priceTagsElement && (
        <div className={cn("price-range-tags-section")}>{priceTagsElement}</div>
      )}
    </div>
  );
};

const isLessThanMonth = (left: MonthType, right: Date) =>
  left.year < right.getFullYear() ||
  (left.year === right.getFullYear() && left.month <= right.getMonth());

const isGreaterThanMonth = (left: MonthType, right: Date) =>
  left.year > right.getFullYear() ||
  (left.year === right.getFullYear() && left.month >= right.getMonth());
