import { CoreFlightsComponent, ITripTerminus } from "@b2bportal/core-types";
import defaultStyles from "./LocationSelectionButton.module.scss";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { useSearchParameters } from "../../../search/components";

export type LocationSelectionButtonProps = {
  onClick: () => void;
};

export type LocationSelectionButtonComponentProps = {
  origin: ITripTerminus;
  destination: ITripTerminus;
} & LocationSelectionButtonProps;

export const LocationSelectionButtonComponent = ({
  onClick,
  origin,
  destination,
}: LocationSelectionButtonComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.LocationSelectionButton;

  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <button className={block} onClick={onClick}>
      {origin.label} - {destination.label}
    </button>
  );
};

export const LocationSelectionButton = ({
  onClick,
}: LocationSelectionButtonProps) => {
  const { origin, destination } = useSearchParameters();

  if (!origin || !destination) {
    // We don't have a use case for an empty origin or destination. It would be applicable if we use this button to start the search only
    return null;
  }

  return (
    <LocationSelectionButtonComponent
      origin={origin}
      destination={destination}
      onClick={onClick}
    />
  );
};

export default LocationSelectionButton;
