import {
  TravelItineraryEnum,
  type BookedFlightItinerary,
  type FlightItinerarySlice,
  type TripContext,
} from "@b2bportal/air-booking-api";
import type { TripSlice } from "@b2bportal/air-shopping-api";
import { SliceDirection } from "@b2bportal/core-types";

export const getDepartureSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice => {
  if (
    bookedItinerary.travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    return bookedItinerary.travelItinerary.slices[0];
  }

  return bookedItinerary.travelItinerary.travelItineraries[0].slices[0];
};

export const getReturnSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice | undefined => {
  let slices: FlightItinerarySlice[];

  if (
    bookedItinerary.travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    slices = bookedItinerary.travelItinerary.slices;
  } else {
    const itineraries = bookedItinerary.travelItinerary.travelItineraries;

    slices = itineraries[itineraries.length - 1].slices;
  }

  if (slices.length > 1) return slices[slices.length - 1];

  return undefined;
};

export const getSliceDepartureDate = (slice: FlightItinerarySlice) => {
  const { scheduledDeparture, updatedDeparture } = slice.segments[0];

  return updatedDeparture ?? scheduledDeparture;
};

export const getSliceDestinationCity = (
  slice: FlightItinerarySlice,
  airports: TripContext["airports"]
) => {
  const lastSegment = slice.segments[slice.segments.length - 1];
  const sliceDestCode = lastSegment.destination.locationCode;

  return airports[sliceDestCode].cityName ?? sliceDestCode;
};

export const getSliceDirection = (
  targetSlice: FlightItinerarySlice,
  allSlices: FlightItinerarySlice[]
) => {
  const targetSliceDestination =
    targetSlice.segments[targetSlice.segments.length - 1].destination
      .locationCode;
  const tripOrigin = allSlices[0].segments[0].origin.locationCode;

  return targetSliceDestination === tripOrigin
    ? SliceDirection.Return
    : SliceDirection.Outbound;
};

export const getSliceIndex = (
  departure: boolean,
  details: { slices: TripSlice[] }
): number => {
  return details?.slices.findIndex((slice) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};

export const getSliceOriginCity = (
  slice: FlightItinerarySlice,
  airports: TripContext["airports"]
) => {
  const lastSegment = slice.segments[0];
  const sliceOriginCode = lastSegment.origin.locationCode;

  return airports[sliceOriginCode].cityName ?? sliceOriginCode;
};
