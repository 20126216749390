export enum LodgingShopSteps {
  LODGING_DETAILS = "LODGING_DETAILS",
  ROOM_SELECTION = "ROOM_SELECTION",
  FINTECH_SELECTION = "FINTECH_SELECTION",
  CFAR_SELECTION = "CFAR_SELECTION",
  WALLET_OFFERS_SELECTION = "WALLET_OFFERS_SELECTION",
}

export enum LodgingShopQueryParams {
  SHOP_STEP = "shop_step",
}

export type ContinueScreenType =
  | "room_selection"
  | "image_gallery"
  | "amenities_pop_up";
