import { disruptionRefundFulfill } from "../../../../features/exercise";
import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookedFlightItineraryWithDepartureTime,
  DisruptionProtectionDisruptionProtectionPolicyDetails,
} from "@b2bportal/air-booking-api";
import { useState } from "react";
import defaultStyles from "./DisruptionExerciseRefundReviewPage.module.scss";
import {
  CoreDisruptionComponent,
  DisruptionTrackingEvent,
} from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import {
  DisruptionRefundFulfillRequest,
  PaymentOpaqueValue,
} from "@b2bportal/air-disruption-api";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { useDeviceType } from "@b2bportal/core-utilities";
import { DisruptionRefundFulfillPollResponseEnum } from "@b2bportal/air-disruption-api/lib/api";
import { DisruptionExercisePage } from "../../components/DisruptionExercisePage";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { DisruptionExercisePageContent } from "../../components/DisruptionExercisePageContent";
import { DisruptionExerciseRefundSummaryCard } from "../../components/DisruptionExerciseRefundSummaryCard";
import { DisruptionExerciseInformationCard } from "../../components/DisruptionExerciseInformationCard";
import { unwrapResult } from "@reduxjs/toolkit";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";

export interface DisruptionExerciseRefundReviewPageProps {
  disruptionProductName: string;
  disruptionProductLogo: string;
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  contractId: string;
  reimbursementTotalAmount: FiatPrice;
  refundBreakdown: Array<PaymentOpaqueValue> | null;
  refundFulfillmentToken: string;
  onContactSupport: () => void;
  termsLink: string;
  policyDetails: DisruptionProtectionDisruptionProtectionPolicyDetails;
  displayExternalLinkAsModal: boolean;
  cancelFlowAction?: () => void;
}

export const DisruptionExerciseRefundReviewPage = ({
  disruptionProductName,
  disruptionProductLogo,
  disruptedFlight,
  contractId,
  reimbursementTotalAmount,
  refundBreakdown,
  refundFulfillmentToken,
  onContactSupport,
  termsLink,
  policyDetails,
  displayExternalLinkAsModal,
  cancelFlowAction,
}: DisruptionExerciseRefundReviewPageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRefundReviewPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const dispatch = useDispatch<any>();
  const [refundFulfillError, setRefundFulfillError] = useState<boolean>(false);
  const itineraryId = disruptedFlight.bookedItinerary.id;
  const [loading, setLoading] = useState<boolean>(false);

  const policyDetailsThreshold =
    policyDetails.delayThreshold !== undefined
      ? policyDetails.delayThreshold
      : 180;
  const delayThresholdHours = (policyDetailsThreshold / 60).toString();
  const hoursString =
    delayThresholdHours === "1"
      ? t("core-disruption.disruptionUniversalExercise.notEligiblePage.hour")
      : t("core-disruption.disruptionUniversalExercise.notEligiblePage.hours");

  const fetchDisruptionRefundFulfill = () => {
    if (contractId) {
      const req: DisruptionRefundFulfillRequest = {
        itineraryId,
        contractId,
        token: { value: refundFulfillmentToken },
      };

      return dispatch(disruptionRefundFulfill(req))
        .then((payload) => {
          const resp = unwrapResult(payload);
          setLoading(false);
          if (
            resp.DisruptionRefundFulfillPollResponse ===
            DisruptionRefundFulfillPollResponseEnum.RefundFulfillPollSuccess
          ) {
            trackEvent({
              eventName: DisruptionTrackingEvent.DISRUPTION_POLICY_EXERCISED,
              properties: resp.trackingProperties?.properties ?? [],
              encryptedProperties: [
                resp.trackingProperties?.encryptedProperties,
              ].flatMap((encryptedProp) => encryptedProp ?? []),
            });
            dispatch(
              setDisruptionExerciseProgress(
                DisruptionExerciseProgress.RefundConfirmation
              )
            );
          } else {
            handleRefundFulfillError();
          }
        })
        .catch(() => {
          setLoading(false);
          handleRefundFulfillError();
        });
    }
  };

  const handleRefundFulfillError = () => {
    setRefundFulfillError(true);
  };

  const handleCta = () => {
    setLoading(true);
    fetchDisruptionRefundFulfill();
  };

  const handleGoBack = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
  };

  const bullets = [
    {
      text: t(
        "core-disruption.disruptionUniversalExercise.refundReviewPage.cashRefundTakes"
      ),
      index: 1,
    },
    {
      text: t(
        "core-disruption.disruptionUniversalExercise.refundReviewPage.rebookingIsntAvailableAfter"
      ),
      index: 2,
    },
    {
      text: t(
        "core-disruption.disruptionUniversalExercise.refundReviewPage.keepYourCurrentFlight"
      ),
      index: 3,
    },
  ];

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseRefundReviewPage")}
      pageWidth={DisruptionExercisePageWidth.Regular}
      title={disruptionProductName}
      disruptionProductLogo={disruptionProductLogo}
      onBack={handleGoBack}
      openErrorModal={refundFulfillError}
      onCloseErrorModal={() => {
        setRefundFulfillError(false);
      }}
      onSupportClick={onContactSupport}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.refundReviewPage.confirmAndGetRefund"
      )}
      submitButtonAction={handleCta}
      submitButtonDisabled={false}
      isLoading={loading}
      loadingMessage={t(
        "core-disruption.disruptionUniversalExercise.refundReviewPage.loadingMessage"
      )}
      termsLink={termsLink}
      onCancelFlow={cancelFlowAction}
      content={
        <DisruptionExercisePageContent
          leftContent={[
            <DisruptionExerciseRefundSummaryCard
              reimbursementTotalAmount={reimbursementTotalAmount}
              refundBreakdown={refundBreakdown}
            ></DisruptionExerciseRefundSummaryCard>,
            isDesktopAndUp ? (
              <div></div>
            ) : (
              <div className={cn("mobileDivider")} />
            ),
            <DisruptionExerciseInformationCard
              onSupportClick={onContactSupport}
              bulletPoints={bullets}
              termsLink={termsLink}
              displayExternalLinkAsModal={displayExternalLinkAsModal}
            ></DisruptionExerciseInformationCard>,
          ]}
        />
      }
      delayHours={delayThresholdHours}
      numberOfHours={hoursString}
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRefundReviewPage;
