import { useI18nContext } from "@hopper-b2b/i18n";
import { Airport, FlightItinerarySegment } from "@b2bportal/air-booking-api";
import defaultStyles from "./DisruptionExerciseFlightSliceCard.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import dayjs from "dayjs";
import { DisruptionExerciseSelection } from "@b2bportal/core-disruption";
import { AirlineIcon } from "@b2bportal/core-ui";
import { useCallback } from "react";

interface DisruptionExerciseFlightSliceCardProps {
  slice: { segments: Array<FlightItinerarySegment> };
  isSelected: boolean;
  isReturn: boolean;
  onSelect?: () => void;
  airports: { [key: string]: Airport };
}

export const DisruptionExerciseFlightSliceCard = (
  props: DisruptionExerciseFlightSliceCardProps
) => {
  const { t, language } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseFlightSliceCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const getCityName = (code: string) => {
    return props.airports[code]?.cityName || "";
  };

  const lastSegment = props.slice.segments[props.slice.segments.length - 1];
  const firstSegment = props.slice.segments[0];
  const destinationCode = lastSegment.destination.locationCode;
  const destination = getCityName(destinationCode);
  const date = formatDateTime(
    dayjs(firstSegment.updatedDeparture).toDate(),
    language,
    DateTimeFormatStyle.MediumDate
  );

  const headline = props.isReturn ? (
    <span
      className={cn("regulerText")}
      dangerouslySetInnerHTML={{
        __html: t(
          "core-disruption.disruptionUniversalExercise.rebook.returnHeadline",
          {
            destination: destination,
            airport: destinationCode,
            date: date,
          }
        ),
      }}
    />
  ) : (
    <span
      className={cn("regulerText")}
      dangerouslySetInnerHTML={{
        __html: t(
          "core-disruption.disruptionUniversalExercise.rebook.outboundHeadline",
          {
            destination: destination,
            airport: destinationCode,
            date: date,
          }
        ),
      }}
    />
  );

  const time = () => {
    const firstSegmentDepartureTime = formatDateTime(
      dayjs(firstSegment.updatedDeparture).toDate(),
      language,
      DateTimeFormatStyle.ShortTime
    );

    const lastSegmentArrivalTime = formatDateTime(
      dayjs(firstSegment.updatedArrival).toDate(),
      language,
      DateTimeFormatStyle.ShortTime
    );

    const flightTime = t(
      "core-disruption.disruptionUniversalExercise.rebook.flightTime",
      {
        fromTime: firstSegmentDepartureTime,
        toTime: lastSegmentArrivalTime,
      }
    );

    return (
      <span className={cn("regulerText")}>
        <b>{flightTime}</b>
      </span>
    );
  };

  const duration = () => {
    const hours = dayjs(lastSegment.updatedArrival).diff(
      dayjs(firstSegment.updatedDeparture),
      "hours"
    );
    return (
      <span className={cn("regulerText")}>
        <b>
          {t("core-disruption.disruptionUniversalExercise.hours", {
            numberOfHours: hours,
          })}
        </b>
      </span>
    );
  };

  const stops = () => {
    const numberOfStops = props.slice.segments.length - 1;
    const hasConnections = numberOfStops > 0;
    const stops =
      numberOfStops > 1
        ? t("core-disruption.disruptionUniversalExercise.rebook.stops", {
            stops: numberOfStops,
          })
        : t("core-disruption.disruptionUniversalExercise.rebook.stopsSingle", {
            stops: numberOfStops,
          });

    return hasConnections ? (
      <span className={cn("smallText")}>{stops}</span>
    ) : (
      <span className={cn("smallText")}>
        {t("core-disruption.disruptionUniversalExercise.rebook.direct")}
      </span>
    );
  };

  const airline = useCallback(() => {
    const airlineCodes = props.slice.segments
      .map((segment) => {
        return segment.operatingAirline?.code;
      })
      .filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
    const code = airlineCodes[0];

    if (code != null) {
      return (
        <div className={cn("airlineContainer")}>
          <AirlineIcon airlineCode={code} size={"small"} />
          <span className={cn("smallText")}>{code}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  }, [props.slice.segments, cn]);

  return (
    <DisruptionExerciseSelection
      onSelect={props.onSelect}
      isSelected={props.isSelected}
      hideRadio={false}
      content={
        <div className={cn("disruptionExerciseFlightSliceCardContent")}>
          <div className={cn("topSection")}>{headline}</div>
          <div className={cn("bottomSection")}>
            <div className={cn("column")}>
              {time()}
              {airline()}
            </div>
            <div className={cn("verticalDivider")} />
            <div className={cn("column")}>
              {duration()}
              {stops()}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DisruptionExerciseFlightSliceCard;
