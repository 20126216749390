import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./PriceRangeTags.module.scss";

export interface IPriceRangeTagsProps {
  priceTags: string[];
  currency?: string;
  className?: string;
}

export const PriceRangeTags = ({
  priceTags,
  className,
}: IPriceRangeTagsProps) => {
  const { t, brand } = useI18nContext();
  const legend = [
    t("core-flights.search.dateSelection.calendarPriceTags.lowest"),
    t("core-flights.search.dateSelection.calendarPriceTags.low"),
    t("core-flights.search.dateSelection.calendarPriceTags.medium"),
    t("core-flights.search.dateSelection.calendarPriceTags.high"),
  ];

  const highInflationCurrency = brand?.currency?.highInflationCurrency;

  const styles = useUiStyles(
    CoreUiComponents.CalendarPriceRangeTags,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.CalendarPriceRangeTags
  );

  return (
    <div className={clsx(block, className)}>
      <div className={cn("container")}>
        <div className={cn("section")}>
          {priceTags?.map((priceTag: string, index: number) => {
            const isNotGoingToFit =
              highInflationCurrency || priceTag.length >= 6;
            return (
              <div
                key={index}
                className={cn("tag-root", {
                  [`bucket-${index}`]: true,
                })}
              >
                <div className={cn("tag-container")}>{legend[index]}</div>
                {!isNotGoingToFit && (
                  <div className={cn("tag-price")}>
                    {t(
                      "core-flights.search.dateSelection.calendarPriceTags.over",
                      {
                        price: priceTag.slice(0, -1),
                      }
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
