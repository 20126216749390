import { IconSet, IconNameEnum } from "../context";
import { ReactComponent as TriangleDown } from "./triangle-down.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as ChevronUp } from "./chevron-up.svg";
import { ReactComponent as ChevronRight } from "./Chevron.svg";
import { ReactComponent as CheckFilled } from "./check-filled.svg";
import { ReactComponent as Unavailable } from "./Unavailable.svg";
import { ReactComponent as Charge } from "./Charge.svg";
import { ReactComponent as Generic } from "./GenericFareDetail.svg";
import { ReactComponent as Filters } from "./filters.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as Info } from "./Info.svg";
import { ReactComponent as Airplane } from "./airplane.svg";
import { ReactComponent as AirplaneDiagonal } from "./airplane-diagonal.svg";
import { ReactComponent as AirplaneArrive } from "./airplane-arrive.svg";
import { ReactComponent as AirplaneDeparture } from "./airplane-departure.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as PlusCircle } from "./plus-circle.svg";
import { ReactComponent as WarningCircle } from "./warning-circle.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as MapPin } from "./map-pin.svg";
import { ReactComponent as Glass } from "./glass.svg";
import { ReactComponent as B2BUser } from "./b2b-user.svg";
import { ReactComponent as Check } from "./Check.svg";
import { ReactComponent as Warning } from "./warning.svg";
import { ReactComponent as Tripadvisor } from "./tripadvisor.svg";
import { ReactComponent as StarFilled } from "./star-filled.svg";
import { ReactComponent as Bed } from "./bed.svg";
import { ReactComponent as CarouselRightArrow } from "./carousel-right-arrow.svg";
import { ReactComponent as CarouselLeftArrow } from "./carousel-left-arrow.svg";
import { ReactComponent as CheckCircle } from "./check-circle.svg";
import { ReactComponent as PremiumDisruptionAssistanceLogo } from "./PremiumDisruptionAssistance.svg";
import { ReactComponent as Dismiss } from "./dismiss.svg";
import { ReactComponent as Hotel } from "./hotel.svg";
import { ReactComponent as WarningTriangle } from "./warningTriangle.svg";
import { ReactComponent as Map } from "./map.svg";
import { ReactComponent as List } from "./list.svg";
import { HotelsAmenityIconSet } from "../product/hotels";
import { Amenity } from "@b2bportal/lodging-api";
// Price Freeze
import { ReactComponent as PriceFreezeSnowFlake } from "./priceFreeze/price-freeze-snow-flake.svg";
import { ReactComponent as PriceFreezePriceGoesUp } from "./priceFreeze/price-goes-up.svg";
import { ReactComponent as PriceFreezePriceGoesDown } from "./priceFreeze/price-goes-down.svg";
import { ReactComponent as PriceFreezeCheckMark } from "./priceFreeze/check.svg";
// Amenities
import { ReactComponent as Accessible } from "./hotelAmenities/accessible.svg";
import { ReactComponent as AirConditioning } from "./hotelAmenities/air_conditioning.svg";
import { ReactComponent as Bar } from "./hotelAmenities/bar.svg";
import { ReactComponent as Kitchen } from "./hotelAmenities/kitchen.svg";
import { ReactComponent as Resort } from "./hotelAmenities/resort.svg";
import { ReactComponent as CoffeeTeaCafe } from "./hotelAmenities/coffee_tea_cafe.svg";
import { ReactComponent as DryCleaning } from "./hotelAmenities/dry_cleaning.svg";
import { ReactComponent as Fitness } from "./hotelAmenities/fitness.svg";
import { ReactComponent as Bakery } from "./hotelAmenities/bakery.svg";
import { ReactComponent as Parking } from "./hotelAmenities/parking.svg";
import { ReactComponent as Wifi } from "./hotelAmenities/wifi.svg";
import { ReactComponent as SpaAndWellness } from "./hotelAmenities/spa_and_wellness.svg";
import { ReactComponent as SofaBed } from "./hotelAmenities/sofa_bed.svg";
import { ReactComponent as Bathroom } from "./hotelAmenities/bathroom.svg";
import { ReactComponent as Pool } from "./hotelAmenities/pool.svg";
import { ReactComponent as Menu } from "./hotelAmenities/menu.svg";
import { ReactComponent as Suitcase } from "./hotelAmenities/suitcase.svg";
import { ReactComponent as FunGames } from "./hotelAmenities/fun_games.svg";
import { ReactComponent as CreditCard } from "./hotelAmenities/credit_card.svg";
import { ReactComponent as Casino } from "./hotelAmenities/casino.svg";
import { ReactComponent as BellServices } from "./hotelAmenities/bell.svg";
import { ReactComponent as Power } from "./hotelAmenities/power.svg";
import { ReactComponent as Outdoors } from "./hotelAmenities/outdoors.svg";
import { ReactComponent as Guide } from "./hotelAmenities/guide.svg";
import { ReactComponent as FoodDrink } from "./hotelAmenities/food_drink.svg";
import { ReactComponent as OutdoorSeating } from "./hotelAmenities/outdoor_seating.svg";
import { ReactComponent as Attractions } from "./hotelAmenities/attractions.svg";
import { ReactComponent as PetFriendly } from "./hotelAmenities/pet_friendly.svg";
import { ReactComponent as Taxi } from "./hotelAmenities/taxi.svg";
import { ReactComponent as Fallback } from "./hotelAmenities/checkmark.svg";
// Car
import { ReactComponent as IconCar } from "./vehicles/car.svg";

export const defaultIcons: IconSet = {
  [IconNameEnum.info]: Info,
  [IconNameEnum.dropdownTriangle]: TriangleDown,
  [IconNameEnum.dropdownArrow]: ChevronDown,
  [IconNameEnum.backIcon]: ChevronLeft,
  [IconNameEnum.actionIcon]: ChevronRight,
  [IconNameEnum.fareDetailsAvailable]: CheckFilled,
  [IconNameEnum.fareDetailsUnavailable]: Unavailable,
  [IconNameEnum.fareDetailsCharge]: Charge,
  [IconNameEnum.fareDetailsGeneric]: Generic,
  [IconNameEnum.filter]: Filters,
  [IconNameEnum.close]: Close,
  [IconNameEnum.nextIcon]: ChevronRight,
  [IconNameEnum.airplane]: Airplane,
  [IconNameEnum.airplaneDiagonal]: AirplaneDiagonal,
  [IconNameEnum.airplaneArrive]: AirplaneArrive,
  [IconNameEnum.airplaneDeparture]: AirplaneDeparture,
  [IconNameEnum.calendar]: Calendar,
  [IconNameEnum.plus]: Plus,
  [IconNameEnum.plusCircle]: PlusCircle,
  [IconNameEnum.minus]: Minus,
  [IconNameEnum.calendarSeparatorIcon]: ArrowRight,
  [IconNameEnum.mapPin]: MapPin,
  [IconNameEnum.searchForFlights]: Glass,
  [IconNameEnum.passengerSelection]: B2BUser,
  [IconNameEnum.check]: Check,
  [IconNameEnum.checkFilled]: CheckFilled,
  [IconNameEnum.checkCircle]: CheckCircle,
  [IconNameEnum.warning]: Warning,
  [IconNameEnum.chevronUp]: ChevronUp,
  [IconNameEnum.guest]: B2BUser,
  [IconNameEnum.search]: Glass,
  [IconNameEnum.carouselRightArrow]: CarouselRightArrow,
  [IconNameEnum.carouselLeftArrow]: CarouselLeftArrow,
  [IconNameEnum.tripadvisor]: Tripadvisor,
  [IconNameEnum.starFilled]: StarFilled,
  [IconNameEnum.bed]: Bed,
  [IconNameEnum.premiumDisruptionAssistance]: PremiumDisruptionAssistanceLogo,
  [IconNameEnum.chevronRight]: ChevronRight,
  [IconNameEnum.tripCardLink]: ChevronRight,
  [IconNameEnum.dismiss]: Dismiss,
  [IconNameEnum.hotel]: Hotel,
  [IconNameEnum.rightArrow]: ArrowRight,
  [IconNameEnum.warningTriangle]: WarningTriangle,
  [IconNameEnum.map]: Map,
  [IconNameEnum.list]: List,
};

export const defaultHotelAmenities: HotelsAmenityIconSet = {
  [Amenity.Accessibility]: Accessible,
  [Amenity.AccessibleBathroom]: Accessible,
  [Amenity.AirConditioning]: AirConditioning,
  [Amenity.AirportTransportation]: Taxi,
  [Amenity.AirportTransportationAvailableGeneral]: Taxi,
  [Amenity.Bar]: Bar,
  [Amenity.BarbecueGrill]: Kitchen,
  [Amenity.BarOrLounge]: Bar,
  [Amenity.Beach]: Resort,
  [Amenity.CoffeeMaker]: CoffeeTeaCafe,
  [Amenity.CoffeeTea]: CoffeeTeaCafe,
  [Amenity.DryCleaningLaundryService]: DryCleaning,
  [Amenity.FitnessCenter]: Fitness,
  [Amenity.FitnessFacilities]: Fitness,
  [Amenity.FreeAirportTransportation]: Taxi,
  [Amenity.FreeBreakfast]: Bakery,
  [Amenity.FreeSelfParking]: Parking,
  [Amenity.FreeWifi]: Wifi,
  [Amenity.FullServiceSpa]: SpaAndWellness,
  [Amenity.HomeEssentials]: SofaBed,
  [Amenity.HotTub]: Bathroom,
  [Amenity.IndoorPool]: Pool,
  [Amenity.InRoomAccessibility]: Accessible,
  [Amenity.KitchenEssentials]: Kitchen,
  [Amenity.Laundry]: DryCleaning,
  [Amenity.LaundryFacilities]: DryCleaning,
  [Amenity.NumberOfBarsOrLounges]: Bar,
  [Amenity.NumberOfRestaurants]: Menu,
  [Amenity.OutdoorPool]: Pool,
  [Amenity.OutdoorSeasonalPool]: Pool,
  [Amenity.PaidBreakfast]: Bakery,
  [Amenity.PaidSelfParking]: Parking,
  [Amenity.PaidValetParking]: Parking,
  [Amenity.Parking]: Parking,
  [Amenity.ParkingAvailableGeneral]: Parking,
  [Amenity.Pool]: Pool,
  [Amenity.PoolGeneral]: Pool,
  [Amenity.PoolsideBar]: Bar,
  [Amenity.Restaurant]: Menu,
  [Amenity.RollInShower]: Bathroom,
  [Amenity.RoomWifi]: Wifi,
  [Amenity.RoundTheClockCheckIn]: Suitcase,
  [Amenity.SomeAccessibilityFeaturesAvailableGeneral]: Accessible,
  [Amenity.Spa]: SpaAndWellness,
  [Amenity.SpaTreatmentRoom]: SpaAndWellness,
  [Amenity.SteamRoom]: SpaAndWellness,
  [Amenity.SwimmingPool]: Pool,
  [Amenity.TwentyFourHourFitnessFacilities]: Fitness,
  [Amenity.WheelchairAccessibleParking]: Accessible,
  [Amenity.WheelchairAccessiblePathOfTravel]: Accessible,
  [Amenity.Arcade]: FunGames,
  [Amenity.AtmBanking]: CreditCard,
  [Amenity.BeachEssentials]: Resort,
  [Amenity.Casino]: Casino,
  [Amenity.ConciergeServices]: BellServices,
  [Amenity.ElectricCarCharging]: Power,
  [Amenity.ExpressCheckIn]: Suitcase,
  [Amenity.HikingOrBikingNearby]: Outdoors,
  [Amenity.Library]: Guide,
  [Amenity.LuggageStorage]: Suitcase,
  [Amenity.Nightclub]: Bar,
  [Amenity.OutdoorSpace]: OutdoorSeating,
  [Amenity.PetFriendly]: PetFriendly,
  [Amenity.PetsAllowed]: PetFriendly,
  [Amenity.PetsAllowedNoFees]: PetFriendly,
  [Amenity.PetsOnlyCats]: PetFriendly,
  [Amenity.PetsOnlyDogs]: PetFriendly,
  [Amenity.PetsOnlyDogsAndCats]: PetFriendly,
  [Amenity.PorterBellhop]: BellServices,
  [Amenity.Sauna]: SpaAndWellness,
  [Amenity.SnackOrDeli]: FoodDrink,
  [Amenity.Terrace]: OutdoorSeating,
  [Amenity.ToursTicketAssistance]: Attractions,
  [Amenity.WaterPark]: Pool,
  // TODO: These amenities need icons.
  [Amenity.AdultsOnly]: Fallback,
  [Amenity.BrailleOrRaisedSignage]: Fallback,
  [Amenity.BusinessCenter]: Fallback,
  [Amenity.BusinessFacilities]: Fallback,
  [Amenity.Childcare]: Fallback,
  [Amenity.ComputerStation]: Fallback,
  [Amenity.ConferenceSpace]: Fallback,
  [Amenity.Entertainment]: Fallback,
  [Amenity.HairDryer]: Fallback,
  [Amenity.Heating]: Fallback,
  [Amenity.HomeInfo]: Fallback,
  [Amenity.KidFriendly]: Fallback,
  [Amenity.MeetingRooms]: Fallback,
  [Amenity.TwentyFourHourFrontDesk]: Fallback,
  [Amenity.Workspace]: Fallback,
  [Amenity.Garden]: Fallback,
  [Amenity.Golf]: Fallback,
  [Amenity.HairSalon]: Fallback,
  [Amenity.SafeDepositBoxFrontDesk]: Fallback,
  [Amenity.Tennis]: Fallback,
};

export const defaultHotelPriceFreezeIcons: IconSet = {
  [IconNameEnum.PriceFreezeSnowFlake]: PriceFreezeSnowFlake,
  [IconNameEnum.PriceFreezePriceGoesUp]: PriceFreezePriceGoesUp,
  [IconNameEnum.PriceFreezePriceGoesDown]: PriceFreezePriceGoesDown,
  [IconNameEnum.PriceFreezeCheckMark]: PriceFreezeCheckMark,
};

export const defaultVehicleIcons: IconSet = {
  [IconNameEnum.car]: IconCar,
};
