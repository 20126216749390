import DisruptionExerciseContainer from "../DisruptionExerciseContainer/component";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  DisruptionExerciseButton,
  DisruptionExerciseButtonType,
} from "@b2bportal/core-disruption";
import { IconNameEnum } from "@b2bportal/core-themes";
import { Icon } from "@components/ui";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseCheckoutComponent.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";

export interface DisruptionExerciseCheckoutComponentProps {
  travelers: Array<string>;
  productName: string;
  onSubmit: () => void;
}

export const DisruptionExerciseCheckoutComponent = (
  props: DisruptionExerciseCheckoutComponentProps
) => {
  const { isDesktopAndUp } = useDeviceType();
  const { t } = useI18nContext();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseCheckoutComponent;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <DisruptionExerciseContainer
      content={
        <div className={cn("checkoutContainer")}>
          <span className={cn("title")}>
            {t(
              "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.checkout"
            )}
          </span>
          {props.travelers.map((traveler) => {
            return <span className={cn("traveler")}>{traveler}</span>;
          })}
          <hr className="solid" />
          <div className={cn("totalContainer")}>
            <span className={clsx([cn("totalContainerText"), cn("total")])}>
              {t(
                "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.total"
              )}
            </span>
            <span className={clsx([cn("totalContainerText"), cn("free")])}>
              {t(
                "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.free"
              )}
            </span>
          </div>
          <div className={cn("fullyCoveredContainer")}>
            <Icon
              className={cn("fullyCoveredIcon")}
              iconName={IconNameEnum.disruptionExerciseFullyCoveredIcon}
            />
            <div className={cn("fullyCoveredContainerText")}>
              <Trans
                i18nKey="core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.fullyCovered"
                components={[<span />]}
                values={{
                  productName: props.productName,
                }}
                className={cn("fullyCoveredContainerText")}
              />
            </div>
          </div>
          {isDesktopAndUp && (
            <DisruptionExerciseButton
              type={DisruptionExerciseButtonType.Primary}
              title={t(
                "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.submitButton"
              )}
              disabled={false}
              onClick={props.onSubmit}
            />
          )}
        </div>
      }
    ></DisruptionExerciseContainer>
  );
};

export default DisruptionExerciseCheckoutComponent;
