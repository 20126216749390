import { CoreFlightsComponent } from "@b2bportal/core-types";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./FlightShopSearch.module.scss";
import { useDeviceType } from "@b2bportal/core-utilities";
import { LocationSelectionButton } from "../LocationSelectionButton";
import { DateSelectionButton } from "../DateSelectionButton";
import { Dialog, DialogContent } from "@b2bportal/core-ui";
import { CalendarPicker, FlightSearch } from "@components/flights";
import { useFlightShopSearch } from "./hooks";

export const FlightShopSearch = () => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightShopSearch;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { isDesktopAndUp } = useDeviceType();

  const {
    context: { openMobileSearch, openCalendar },
    handlers: {
      onClose,
      handleOnSearch,
      onDateSelectionClick,
      onLocationSelectionClick,
    },
  } = useFlightShopSearch();

  return (
    <div className={block}>
      {isDesktopAndUp ? (
        <FlightSearch />
      ) : (
        <div>
          <div className={cn("mobile")}>
            <div className={cn("location-selection-button-container")}>
              <LocationSelectionButton onClick={onLocationSelectionClick} />
            </div>
            <div className={cn("date-selection-button-container")}>
              <DateSelectionButton onClick={onDateSelectionClick} />
            </div>
          </div>
          <Dialog open={openMobileSearch}>
            <DialogContent fullScreen={true} onClose={onClose}>
              <FlightSearch
                className={cn("search", { calendar: openCalendar })}
                onSearch={handleOnSearch}
                showCalendarBackButton={true}
              />
            </DialogContent>
          </Dialog>
          <CalendarPicker
            open={openCalendar}
            onSearch={handleOnSearch}
            onClose={onClose}
          />
        </div>
      )}
    </div>
  );
};
