import { useRef, useContext, useEffect } from "react";
import { useDay } from "@datepicker-react/hooks";
import { context as DateRangePickerContext } from "../../../context";
import dayjs from "dayjs";
import { ArrowKeyEnum } from "@hopper-b2b/types";
import { PickerType } from "../../../types";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface UseCalendarDayParams {
  date: Date;
  isMiniDay?: boolean;
  currentFocusDate?: Date;
  setCurrentFocusDate?: (date?: Date) => void;
  setIsDateFocused?: (bool: boolean) => void;
  isDateFocused?: boolean;
  priceBucket?: number;
  priceLabelName?: string;
}

const generateCalculateStyle =
  ({
    isWithinHoverRange,
    disabledDate,
    isSelected,
    isSelectedStartOrEnd,
    startDate,
    endDate,
    hoveredDate,
    isDateBlocked,
    pickerType,
  }: {
    isWithinHoverRange: boolean;
    disabledDate: boolean;
    isSelected: boolean;
    isSelectedStartOrEnd: boolean;
    startDate: Date | null;
    endDate: Date | null;
    hoveredDate: Date | null;
    isDateBlocked: (date: Date) => boolean;
    pickerType: PickerType;
  }) =>
  (date: Date) => {
    const modifiers = {
      "hover-range":
        (isSelectedStartOrEnd && pickerType === PickerType.DAY) ||
        (isWithinHoverRange && !isSelectedStartOrEnd),
      disabled: disabledDate && !isWithinHoverRange,
      "selected-range": isSelected,
      "start-date":
        isSelectedStartOrEnd &&
        startDate != null &&
        dayjs(date).isSame(startDate, "day"),
      "end-date":
        isSelectedStartOrEnd &&
        endDate != null &&
        dayjs(date).isSame(endDate, "day"),
    };

    if (
      startDate &&
      hoveredDate &&
      !isDateBlocked(hoveredDate) &&
      dayjs(date).isSame(hoveredDate, "day")
    ) {
      if (hoveredDate > startDate) {
        modifiers["end-date"] = true;
      } else {
        modifiers["start-date"] = true;
      }
    }

    return modifiers;
  };

export const useCalendarDay = ({
  date,
  priceBucket,
  isMiniDay = false,
  setCurrentFocusDate,
  currentFocusDate,
  setIsDateFocused,
  isDateFocused: isDateButtonFocused = false,
  priceLabelName,
}: UseCalendarDayParams) => {
  const dayRef = useRef(null);

  const currentDateRef = useRef<HTMLButtonElement>(null);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    pickerType,
    startDate,
    endDate,
    hoveredDate,
  } = useContext(DateRangePickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onMouseEnter,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const { formatDateTime, DateTimeFormatStyle } = useI18nContext();

  const calculateStyle = generateCalculateStyle({
    isWithinHoverRange,
    disabledDate,
    isSelected,
    isSelectedStartOrEnd,
    startDate,
    endDate,
    hoveredDate,
    isDateBlocked,
    pickerType,
  });

  const modifiers = calculateStyle(date);

  const isFocusedDate =
    !disabledDate && dayjs(currentFocusDate).isSame(dayjs(date), "day");

  const styleConditions = {
    "mini-day": isMiniDay,
    focused: isFocusedDate && isDateButtonFocused,
    [`bucket-${priceBucket}`]: priceBucket !== undefined && !isNaN(priceBucket),
    ...modifiers,
  };

  useEffect(() => {
    if (isFocusedDate && currentDateRef.current && isDateButtonFocused) {
      currentDateRef.current.focus();
    }
  }, [currentFocusDate]);

  const handleArrowClicks = (e: any) => {
    const getNewDate = (keyPress: string) => {
      switch (keyPress) {
        case ArrowKeyEnum.UP:
          return dayjs(date).subtract(7, "day");
        case ArrowKeyEnum.DOWN:
          return dayjs(date).add(7, "day");
        case ArrowKeyEnum.LEFT:
          return dayjs(date).subtract(1, "day");
        case ArrowKeyEnum.RIGHT:
          return dayjs(date).add(1, "day");
        default:
          return undefined;
      }
    };

    if (Object.values(ArrowKeyEnum).includes(e.key)) {
      const newDate = getNewDate(e.key);
      if (newDate && setCurrentFocusDate) setCurrentFocusDate(newDate.toDate());
    }
  };

  const getDayAriaLabel = () => {
    const dateAria = formatDateTime(date, DateTimeFormatStyle.FullDate);

    if (startDate && dayjs(date).isSame(startDate, "day")) {
      return `Selected start date ${dateAria} ${priceLabelName}`;
    } else if (endDate && dayjs(date).isSame(endDate, "day")) {
      return `Selected end date ${dateAria} ${priceLabelName}`;
    } else {
      return `${dateAria}, ${priceLabelName}`;
    }
  };

  const onFocus = () => {
    if (setCurrentFocusDate) {
      setCurrentFocusDate(date);
    }

    if (setIsDateFocused) {
      setIsDateFocused(true);
    }
  };

  const onBlur = () => {
    if (setIsDateFocused) {
      setIsDateFocused(false);
    }
  };

  const onKeyDown = (e) => {
    handleArrowClicks(e);
  };

  return {
    context: {
      dayRef,
      currentDateRef,
      styleConditions,
      disabledDate,
      isFocusedDate,
    },
    handlers: {
      onClick,
      onMouseEnter,
      onFocus,
      onBlur,
      onKeyDown,
      getDayAriaLabel,
      handleArrowClicks,
    },
  };
};
