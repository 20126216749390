import { type FC, useCallback, useState } from "react";
import {
  HamburgerButton,
  Header as GenericHeader,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import clsx from "clsx";
import { B2B_LOGO_ALT, HOPPER_LOGO_ALT } from "../../../../utils/constants";
import MobileHamburgerMenu from "../../../MobileHamburgerMenu";
import { ReactComponent as ClientLogo } from "../../../../assets/client/client-logo.svg";
import {
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_HOME,
} from "../../../../utils/urlPaths";
import "./styles.scss";
import { useLocation } from "react-router-dom-v5-compat";

interface IMobileHeaderProps {
  openSupportChat?: () => void;
}

const HeaderLogos: FC = () => (
  <div className={clsx("header-logo-container")}>
    <ClientLogo
      aria-label={B2B_LOGO_ALT}
      className={clsx("mobile img-logo client-logo header-logo-client")}
    />
    <hr className={clsx("header-logo-divider")} />
    <IconComponent
      ariaLabel={HOPPER_LOGO_ALT}
      className={clsx("mobile img-logo header-logo-hopper")}
      name={IconName.HopperLogo}
    />
  </div>
);

const MobileHeader = ({ openSupportChat }: IMobileHeaderProps) => {
  const location = useLocation();
  return (
    <MobileGenericHeader
      isConfirmation={location.pathname.includes(
        PATH_FLIGHTS_BOOK_CONFIRMATION
      )}
      openSupportChat={openSupportChat}
    />
  );
};

export { HeaderLogos, MobileHeader };
const MobileGenericHeader = ({
  isConfirmation,
  openSupportChat,
}: {
  isConfirmation: boolean;
  openSupportChat: () => void;
}) => (
  <GenericHeader
    isMobile={true}
    shadow={true}
    className={clsx("b2b", { confirmation: isConfirmation })}
    left={
      <div className={clsx("header-logo")}>
        <a href={PATH_HOME}>
          <HeaderLogos />
        </a>
      </div>
    }
    right={<Hamburger openSupportChat={openSupportChat} />}
  />
);
const Hamburger = ({ openSupportChat }: { openSupportChat: () => void }) => {
  const [showMenu, setShowMenu] = useState(false);

  const onOpen = useCallback(() => {
    setShowMenu(true);
  }, []);
  const onClose = useCallback(() => {
    setShowMenu(false);
  }, []);

  return (
    <HamburgerButton
      onOpen={onOpen}
      onClose={onClose}
      open={showMenu}
      menu={
        <MobileHamburgerMenu
          onClose={onClose}
          openSupportChat={openSupportChat}
        />
      }
    />
  );
};
