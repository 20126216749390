import {
  type CategorizedResponse,
  type LocationQuery,
  LocationQueryEnum,
} from "@b2bportal/air-shopping-api";
import { logger } from "@b2bportal/core-utilities";
import { flightsShoppingApi } from "@overrides/utilities";
import { debounce } from "lodash-es";
import { useCallback, useState } from "react";

export const useFetchLocation = () => {
  const [destinationCategories, setDestinationCategories] = useState<
    CategorizedResponse[]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchLocations = useCallback(
    debounce(async (queryString: string) => {
      try {
        const requestBody: LocationQuery = {
          LocationQuery: LocationQueryEnum.Label,
          l: queryString,
        };
        setLoading(true);

        const res = await flightsShoppingApi.apiV0AutocompleteLocationPost(
          requestBody
        );

        const { categories } = res.data;
        setDestinationCategories(categories);
      } catch (e) {
        logger.debug(e);
        setDestinationCategories([]);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  return {
    context: {
      loading,
      destinationCategories,
    },
    handlers: {
      fetchLocations,
    },
  };
};
