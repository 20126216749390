import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  BookedFlightItineraryWithDepartureTime,
  FiatPrice,
  TrackingProperties,
} from "@b2bportal/air-booking-api";
import { Trip, Fare, FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  ActionButton,
  B2BLoadingPopup,
  DetailHalfSheet,
  ErrorPopup,
  MobileFloatingButton,
  FlightConfirmation,
} from "@hopper-b2b/ui";
import {
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionFailureEnum,
  DisruptionFulfillErrorEnum,
  DisruptionRebookFulfillResponse,
  DisruptionRebookPollPQResponse,
  DisruptionRebookPollPQResponseEnum,
  DisruptionTrackingEvents,
  ExerciseType,
  FintechProductType,
  FlightSummaryInfo,
  RebookPollFulfillResponseEnum,
  TripDetails,
  getReturnSlice,
} from "@hopper-b2b/types";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";
import { IContactInfo } from "@hopper-b2b/checkout";

import { ClientContext } from "../../../../../App";
import {
  getAirlinesMap,
  getAirportMap,
  getDisruptionExerciseProductType,
  getDisruptionFulfillError,
  getDisruptionRebookSelectedFlight,
  getSelectedFlight,
} from "../../../../TripsList/reducer";
import {
  setDisruptionExerciseProgress,
  setDisruptionFulfillError,
} from "../../../../TripsList/actions/actions";
import disruptionRebookQuote from "../../../../../api/v1/disruption/disruptionRebookQuote";
import disruptionRebookFulfill from "../../../../../api/v1/disruption/disruptionRebookFulfill";
import { getDisruptionExerciseTracking } from "../helpers";
import { AxiosResponse } from "axios";

export interface IDisruptionRebookConfirmationProps {
  flight: BookedFlightItineraryWithDepartureTime;
  contractId: string;
  selectedFlight: {
    fare: Fare;
    trip: Trip;
    fareSlice: FlightsFareSlice;
    tripDetails: TripDetails;
  };
  errorModalPrimaryOnClick: () => void;
  errorModalSecondaryOnClick: (
    flightDetails: BookedFlightItineraryWithDepartureTime
  ) => void;
  contactInfo: IContactInfo;
  disableFloatingButton?: boolean;
  onDone?: () => void;
}

export const DisruptionRebookConfirmation = ({
  flight,
  contractId,
  selectedFlight,
  errorModalPrimaryOnClick,
  errorModalSecondaryOnClick,
  contactInfo,
  disableFloatingButton,
  onDone,
}: IDisruptionRebookConfirmationProps) => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);
  const airlineMap = useSelector(getAirlinesMap);
  const airportMap = useSelector(getAirportMap);
  const selectedFlightDetails = useSelector(getDisruptionRebookSelectedFlight);
  const disruptionFulfillError = useSelector(getDisruptionFulfillError);
  const selectedTripFlight = useSelector(getSelectedFlight);
  const disruptionProductType = useSelector(getDisruptionExerciseProductType);

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [rebookingFailed, setRebookingFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmationCode, setConfirmationCode] = useState<string>(null);

  const trackExerciseEvent = useCallback(
    (
      rebookedTripPrice?: FiatPrice,
      trackingProperties?: TrackingProperties
    ) => {
      const properties = getDisruptionExerciseTracking(
        selectedTripFlight,
        disruptionProductType,
        trackingProperties,
        rebookedTripPrice
      );
      trackEvent({
        eventName:
          disruptionProductType ===
          DisruptionExerciseProductType.MissedConnection
            ? DisruptionTrackingEvents.MISSED_CONNECTION_POLICY_EXERCISED
            : DisruptionTrackingEvents.DELAY_POLICY_EXERCISED,
        properties: {
          ...properties,
          exercise_type: ExerciseType.rebook,
        },
      });
    },
    [disruptionProductType, selectedTripFlight]
  );

  const processBooking = useCallback(() => {
    const { trip, fare } = selectedFlight;

    // TODO: ALL API CALLS HERE SHOULD RETURN AS A UNIFORM TYPE (AxiosResponse or the explicit type of response body)
    disruptionRebookQuote({
      contractId,
      tripId: trip.id,
      fareId: fare.id,
      emailAddress: contactInfo.email,
      phoneNumber: `${contactInfo.countryCode}${contactInfo.phoneNumber}`,
    })
      .then((response: DisruptionRebookPollPQResponse) => {
        if (
          response?.DisruptionRebookPollPQResponse ===
            DisruptionRebookPollPQResponseEnum.RebookPollPQSuccess ||
          response?.DisruptionRebookPollPQResponse ===
            DisruptionRebookPollPQResponseEnum.Success
        ) {
          disruptionRebookFulfill({
            contractId,
            token: response.token,
          })
            .then(
              (
                fulfillResponse: AxiosResponse<DisruptionRebookFulfillResponse>
              ) => {
                const { data } = fulfillResponse;
                if (
                  data?.RebookPollFulfillResponse ===
                  RebookPollFulfillResponseEnum.Failure
                ) {
                  setRebookingFailed(true);
                  dispatch(setDisruptionFulfillError(data?.reason));
                } else if (
                  data?.RebookPollFulfillResponse ===
                  RebookPollFulfillResponseEnum.Success
                ) {
                  setConfirmationCode(
                    data?.rebookedFlight?.travelItinerary?.locators?.b2b
                  );
                  trackExerciseEvent(
                    data?.rebookedFlight?.pricingSummary?.totalPricing?.total
                      ?.fiat,
                    data?.rebookedFlight?.trackingProperties
                  );
                }
                setLoading(false);
              }
            )
            .catch((error) => {
              setLoading(false);
              setRebookingFailed(true);
            });
        } else {
          setLoading(false);
          setRebookingFailed(true);
          if (response.reason) {
            dispatch(setDisruptionFulfillError(response.reason));
          }
        }
      })
      .catch((_error) => {
        setLoading(false);
        setRebookingFailed(true);
      });
  }, [
    contactInfo.countryCode,
    contactInfo.email,
    contactInfo.phoneNumber,
    contractId,
    dispatch,
    selectedFlight,
    trackExerciseEvent,
  ]);

  useEffect(() => {
    processBooking();
  }, [processBooking]);

  const getFlightSummaryInfo = useCallback(
    (isDeparture: boolean): FlightSummaryInfo | null => {
      if (selectedFlightDetails) {
        const departureSegment = selectedFlightDetails?.segments.at(0);
        const departure = selectedFlightDetails?.departure;
        const arrival = selectedFlightDetails?.arrival;
        const originLocationCode = selectedFlightDetails.origin;
        const destinationLocationCode = selectedFlightDetails.destination;
        const stops = (selectedFlightDetails?.segments.length || 0) - 1;

        return {
          originCity: airportMap?.[originLocationCode || ""]?.cityName,
          destinationCity:
            airportMap?.[destinationLocationCode || ""]?.cityName,
          departure: departure || "",
          arrival: arrival || "",
          airlineName:
            airlineMap[departureSegment?.marketingAirline || ""]?.displayName ||
            "",
          airlineCode: departureSegment?.marketingAirline || "",
          isDeparture,
          title: t("originToDestination", {
            origin: airportMap?.[originLocationCode || ""]?.cityName,
            destination: airportMap?.[destinationLocationCode || ""]?.cityName,
            interpolation: { escapeValue: false },
          }),
          stops,
        };
      }
      return null;
    },
    [selectedFlightDetails, airportMap, airlineMap, t]
  );

  const onContinue = useCallback(() => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
    );
    onDone?.();
  }, [dispatch, onDone]);

  const bookingStatus = useMemo(
    () =>
      t("bookingConfirmation.status", {
        id:
          confirmationCode ||
          flight?.bookedItinerary?.travelItinerary?.locators?.agent
            .unscopedValue ||
          "",
      }),
    [
      confirmationCode,
      flight?.bookedItinerary?.travelItinerary?.locators?.agent.unscopedValue,
      t,
    ]
  );

  const handleOpenDetails = useCallback(() => setOpenDetails(true), []);
  const handleCloseOpenDetails = useCallback(() => setOpenDetails(false), []);

  const getErrorModalProps = useCallback(
    (type: DisruptionFulfillErrorEnum | DisruptionFailureEnum) => {
      switch (type) {
        case DisruptionFailureEnum.AlreadyExercised:
          return {
            title: t("disruptionExercise.error.alreadyExercisedTitle"),
            subtitle: t(
              "disruptionExercise.error.rebookAlreadyExercisedSubtitle"
            ),
          };
        default:
          return {
            title: t("disruptionExercise.error.title"),
            subtitle: t("disruptionExercise.error.rebookingSubtitle"),
          };
      }
    },
    [t]
  );

  const errorModalProps = useMemo(
    () => getErrorModalProps(disruptionFulfillError?.DisruptionFailure),
    [disruptionFulfillError, getErrorModalProps]
  );

  return loading ? (
    <B2BLoadingPopup
      className="disruption-rebook-confirmation-loader"
      open
      fullScreen={true}
      message={t("disruptionExercise.rebookConfirmation.loading")}
      secondaryMessage={t("bookingFlightSubtitle")}
      popupSize="mobile"
    />
  ) : (
    <div className="disruption-rebook-confirmation">
      {!rebookingFailed ? (
        <>
          <FlightConfirmation
            statusLabel={bookingStatus}
            title={t("disruptionExercise.rebookConfirmation.title")}
            subtitle={t("disruptionExercise.rebookConfirmation.subtitle", {
              email: contactInfo.email,
            })}
            nextHeader={t("bookingConfirmation.whatsNext")}
            outgoingFlightSummaryInfo={getFlightSummaryInfo(true)}
            returningFlightSummaryInfo={
              getReturnSlice(flight.bookedItinerary)
                ? getFlightSummaryInfo(false)
                : undefined
            }
            isMobile
            // seats={seats?.seats}
            assets={{
              airplaneDepart: clientContext.assets?.airplaneDepart,
              airplaneArrive: clientContext.assets?.airplaneArrive,
            }}
          />
          <div className="disruption-rebook-confirmation-next">
            <h3 className="disruption-rebook-confirmation-next-header">
              {t("bookingConfirmation.whatsNext")}
            </h3>
            <ol className="disruption-rebook-confirmation-next-list">
              <li className="disruption-rebook-confirmation-next-list-item">
                {t("disruptionExercise.rebookConfirmation.nextSteps.luggage")}
              </li>
              <li className="disruption-rebook-confirmation-next-list-item">
                {t("disruptionExercise.rebookConfirmation.nextSteps.departure")}
              </li>
              <li className="disruption-rebook-confirmation-next-list-item">
                {t("disruptionExercise.rebookConfirmation.nextSteps.return")}
              </li>
            </ol>
          </div>
          <div className="disruption-rebook-confirmation-about">
            <h3 className="disruption-rebook-confirmation-about-header">
              {t("disruptionExercise.rebookConfirmation.aboutTitle")}
            </h3>
            <div className="disruption-rebook-confirmation-about-content">
              <Trans
                i18nKey="disruptionExercise.rebookConfirmation.aboutContent"
                components={[
                  <span
                    className="disruption-rebook-confirmation-about-link"
                    onClick={handleOpenDetails}
                  />,
                ]}
              />
            </div>
          </div>
          {disableFloatingButton ? (
            <ActionButton
              className="disruption-rebook-confirmation-button"
              onClick={onContinue}
              message={t("done")}
            />
          ) : (
            <MobileFloatingButton
              className="disruption-rebook-confirmation-button"
              onClick={onContinue}
              wrapperClassName="disruption-rebook-button-wrapper"
              wide
            >
              {t("done")}
            </MobileFloatingButton>
          )}
          <DetailHalfSheet
            className="disruption-rebook-confirmation-about-modal"
            contentClassName="disruption-rebook-confirmation-about-modal-content"
            productType={FintechProductType.Disruption}
            open={openDetails}
            onClose={handleCloseOpenDetails}
            header={t(
              "disruptionExercise.rebookConfirmation.returnModalHeader"
            )}
            title={t("disruptionExercise.rebookConfirmation.returnModalTitle")}
            description={
              <I18nMarkup
                tKey={
                  "disruptionExercise.rebookConfirmation.returnModalContent"
                }
              />
            }
            descriptionAdditional
          />
        </>
      ) : (
        <ErrorPopup
          reverse
          open={rebookingFailed}
          primaryButtonText={t("disruptionExercise.error.primaryButton")}
          primaryOnClick={errorModalPrimaryOnClick}
          secondaryButtonText={t("disruptionExercise.error.secondaryButton")}
          secondaryOnClick={() => errorModalSecondaryOnClick(flight)}
          {...errorModalProps}
        />
      )}
    </div>
  );
};
