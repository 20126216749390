import React from "react";
import clsx from "clsx";
import { TFunction } from "i18next";
import { BookedRooms, RoomInfo } from "@b2bportal/lodging-api";
import { getCancellationPolicyInfo } from "@hopper-b2b/utilities";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import {
  CancellationPolicy,
  CancellationPolicyEnum,
  HotelCfarContract,
  NonRefundable,
  Refundable,
  RoomProduct,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box } from "@material-ui/core";

import "./styles.scss";

interface ICancellationText {
  primaryText: string;
  secondaryText?: string;
  iconName?: string;
}

const getCancellationText = (
  cancellationPolicy: CancellationPolicy,
  t: TFunction<string[], undefined, string[]>,
  hotelCfar?: HotelCfarContract
): ICancellationText => {
  if (hotelCfar) {
    return {
      iconName: IconName.CheckShieldBlue,

      primaryText: t("hotelCard.cfarCancellationPrimaryText", {
        percentage: hotelCfar.coverage.percentage,
      }),
      secondaryText: t("hotelCard.cfarCancellationSecondaryText", {
        percentage: hotelCfar.coverage.percentage,
      }),
    };
  }

  if (
    cancellationPolicy?.CancellationPolicy ===
    CancellationPolicyEnum.NonRefundable
  ) {
    const { primaryText, secondaryText } = cancellationPolicy as NonRefundable;
    return { primaryText, secondaryText: secondaryText! };
  }

  if (
    cancellationPolicy?.CancellationPolicy === CancellationPolicyEnum.Refundable
  ) {
    const { primaryText, secondaryText } = cancellationPolicy as Refundable;
    return { primaryText, secondaryText: secondaryText! };
  }

  return { primaryText: "" };
};

export interface IHotelShopRoomTypePickerProps {
  bookedRooms: BookedRooms[];
  roomInfo: RoomInfo;
  cancellationPolicy?: CancellationPolicy;
  isLuxuryCollection?: boolean;
}

export interface IHotelShopRoomTypePickerState {
  panelExpanded: boolean;
}

// TODO: Add translations
export interface HotelShopInfoPartsTexts {
  nightText?: string;
  perNightText?: string;
}

export const RoomRefundability = ({
  product,
}: {
  product: RoomProduct;
}): React.ReactElement | null => {
  const cancellationClassAndTitle = getCancellationPolicyInfo(
    product.cancellationPolicy
  );

  if (!cancellationClassAndTitle) {
    return null;
  }

  return (
    <div
      className={clsx(
        "hotel-description room-type-rate-type",
        cancellationClassAndTitle.className
      )}
    >
      <h3 className="text-container">{cancellationClassAndTitle.tagText}</h3>
    </div>
  );
};

export const RoomName = ({
  name,
  count,
}: {
  name?: string;
  count?: number;
}): React.ReactElement | null => {
  if (!name) return null;
  return <p className="hotel-description room-type-name">{name}</p>;
};

export const RoomCapacity = ({
  occupancy,
}: {
  occupancy?: number;
}): React.ReactElement | null => {
  const { t } = useI18nContext();
  if (!occupancy) {
    return null;
  }
  return (
    <div className="hotel-description room-type-capacity">
      <IconComponent className="room-type-bed-icon" name={IconName.User} />
      <p className="hotel-description room-type-capacity inline">
        {t("roomCapacityGuest", { count: occupancy })}
      </p>
    </div>
  );
};

export const RoomBedDescription = ({
  description,
}: {
  description?: string;
}): React.ReactElement => {
  return (
    <div className="hotel-description room-type-bed-description">
      <IconComponent className="room-type-bed-icon" name={IconName.Bed} />
      <p className="hotel-description room-type-bed-description inline">
        {description}
      </p>
    </div>
  );
};

export const HotelCancellation = ({
  hotelCfar,
  cancellationPolicy,
}: {
  hotelCfar?: HotelCfarContract;
  cancellationPolicy: CancellationPolicy;
}) => {
  const { t } = useI18nContext();

  const { iconName, primaryText, secondaryText } = getCancellationText(
    cancellationPolicy,
    t,
    hotelCfar
  );

  if (!primaryText) {
    return null;
  }

  return (
    <div className="hotel-cancellation-text">
      <div className="hotel-cancellation-title-line">
        {iconName && (
          <IconComponent className="cancellation-text-icon" name={iconName} />
        )}
        <h3 className="cancellation-title">{primaryText}</h3>
      </div>
      {secondaryText && (
        <p className="cancellation-subtitle">{secondaryText}</p>
      )}
    </div>
  );
};

export const PremierCollectionBenefits = ({
  isMobile,
}: {
  isMobile?: boolean;
}) => {
  const { t } = useI18nContext();

  return (
    <div
      className={clsx("trips-premier-collection-benefits-container", {
        mobile: !!isMobile,
      })}
    >
      <div className="benefits-icon-title-wrapper">
        <IconComponent name={IconName.StarOutline} />
        <h3 className="benefits-title">
          {t("premierCollectionBenefitsTitle")}
        </h3>
      </div>
    </div>
  );
};

export const RoomImage = ({ roomInfo }: { roomInfo?: RoomInfo }) => {
  const roomImageUrl = roomInfo?.media?.[0]?.url;
  return roomImageUrl ? (
    <img className="room-image" src={roomImageUrl} alt="" />
  ) : null;
};

export const HotelCardRoomTypeContent = ({
  bookedRooms,
  roomInfo,
  isLuxuryCollection,
}: IHotelShopRoomTypePickerProps): React.ReactElement | null => {
  if (!bookedRooms) {
    return null;
  }
  const bookedRoom = bookedRooms[0];
  const { count } = bookedRoom;

  return (
    <div className="hotel-shop-room-type-room-details-list">
      <RoomImage roomInfo={roomInfo} />
      <Box>
        <RoomName name={roomInfo?.name} count={count} />
        <RoomCapacity occupancy={roomInfo?.maxAdults} />
        <RoomBedDescription description={roomInfo?.beds.description} />
        {isLuxuryCollection && <PremierCollectionBenefits />}
      </Box>
    </div>
  );
};
