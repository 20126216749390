import { useMemo } from "react";
import { TripType } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type IFareData,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AlgomerchTags } from "@components/flights";
import { useFiatPriceToText } from "@overrides/utilities";
import defaultStyles from "./FlightFareGridItem.module.scss";
import clsx from "clsx";
import { PriceDisplay } from "@b2bportal/core-ui";

export type FlightFareGridItemComponentProps = PlatformComponentProps & {
  tripType: TripType;
  fare: IFareData;
  tagsLimit?: number;
  onFareGridClick: (fare: IFareData) => void;
  isFilteredOut?: boolean;
  numberOfFares?: number;
  fareFormatOptions?: Intl.NumberFormatOptions;
};

export const FlightFareGridItem = ({
  fare,
  tripType,
  tagsLimit,
  onFareGridClick,
  isFilteredOut,
  numberOfFares = 0,
  className,
  children,
  fareFormatOptions,
}: FlightFareGridItemComponentProps) => {
  const { t } = useI18nContext();
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightFareGridItem,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightFareGridItem,
    { "filtered-out": isFilteredOut ?? false }
  );

  const startingFrom = useMemo(() => {
    switch (tripType) {
      case TripType.one_way: {
        return t("flightOneWayPricing");
      }
      case TripType.open_jaw: {
        return t("flightOpenJawPricing");
      }
      case TripType.round_trip: {
        return t("flightRoundTripPricing");
      }
    }
  }, [t, tripType]);

  return (
    <li className={clsx(block, className)} key={`fare-${fare.fareId}`}>
      <button
        className={cn("button")}
        onClick={(e) => {
          e.stopPropagation();
          onFareGridClick(fare);
        }}
      >
        <div className={cn("startingFrom")}>{startingFrom}</div>
        <div className={cn("price")}>
          <PriceDisplay
            prices={fare?.price}
            formatOptions={fareFormatOptions}
          />
        </div>
        <AlgomerchTags
          className={cn("alogomerch-tags")}
          tags={fare?.tags ?? []}
          tagsLimit={tagsLimit}
        />
        <div className={cn("fare-name")}>
          {fare?.fareName}
          {numberOfFares > 0 ? (
            <div className={cn("fares-number")}>
              {t("fareGrid.moreFares", { count: numberOfFares })}
            </div>
          ) : null}
        </div>
        {children}
      </button>
    </li>
  );
};
