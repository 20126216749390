import { TripCategory } from "@b2bportal/core-types";
import {
  useFlightSearch,
  CalendarPicker,
  FlightDateSelectionProps,
} from "@components/flights";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import { useEffect, useState } from "react";

export const useFlightDateSelection = ({
  setCalendarVisitedHandler,
}: FlightDateSelectionProps) => {
  const [openCalendar, setOpenCalendar] = useState(false);

  const {
    context: { tripCategory, departureDate, returnDate },
  } = useFlightSearch();

  const [selectedDepartureDateState, setSelectedDepartureDateState] =
    useState(departureDate);
  const [selectedReturnDateState, setSelectedReturnDateState] =
    useState(returnDate);

  const { t } = useI18nContext();

  useEffect(() => {
    if (departureDate !== selectedDepartureDateState) {
      setSelectedDepartureDateState(departureDate);
    }
    if (returnDate !== selectedReturnDateState) {
      setSelectedReturnDateState(returnDate);
    }
  }, [
    selectedDepartureDateState,
    selectedReturnDateState,
    setSelectedReturnDateState,
    setSelectedDepartureDateState,
    departureDate,
    returnDate,
  ]);

  const renderCalendarPopup = () => {
    return (
      <CalendarPicker
        open={openCalendar}
        onClose={() => setOpenCalendar(false)}
        setCalendarVisitedHandler={setCalendarVisitedHandler}
      />
    );
  };

  return {
    context: {
      startDate: selectedDepartureDateState ?? null,
      startLabel: t("departure"),
      endDate: selectedReturnDateState ?? null,
      endLabel: t("return"),
      dateFormat: DateTimeFormatStyle.ShortMonthDayShortWeekday,
      disabled: false,
      hideEndDate: tripCategory === TripCategory.ONE_WAY,
    },
    handlers: {
      onClick: () => setOpenCalendar(true),
      renderCalendarPopup,
    },
  };
};
