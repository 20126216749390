import { useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { LodgingSummary } from "@hopper-b2b/types";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import {
  customFormatDateTime,
  dateStringToNewDate,
} from "@hopper-b2b/utilities";
import styles from "./styles.module.scss";

export interface IHotelCancellationSummaryPanelProps {
  checkIn: string;
  checkOut: string;
  lodging: LodgingSummary;
  refundAmount?: string;
}

export const HotelCancellationSummaryPanel = (
  props: IHotelCancellationSummaryPanelProps
): JSX.Element => {
  const { checkIn, checkOut, lodging, refundAmount } = props;
  const { t, language, brand } = useI18nContext();

  const {
    city,
    country,
    state: { code, name },
  } = lodging;
  const state = code || name || "";
  const addressParts = [city];

  if (state) {
    addressParts.push(state);
  }

  addressParts.push(country);

  const addressText = addressParts.join(", ");

  const formattedCheckInWeekday = useMemo(
    () =>
      customFormatDateTime(
        dateStringToNewDate(checkIn),
        language,
        DateTimeFormatStyle.Custom({
          weekday: "long",
        })
      ),
    [checkIn]
  );

  const formattedCheckInDate = useMemo(
    () =>
      customFormatDateTime(
        dateStringToNewDate(checkIn),
        language,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      ),
    [checkIn]
  );

  const formattedCheckOutWeekday = useMemo(
    () =>
      customFormatDateTime(
        dateStringToNewDate(checkOut),
        language,
        DateTimeFormatStyle.Custom({
          weekday: "long",
        })
      ),
    [checkOut]
  );

  const formattedCheckOutDate = useMemo(
    () =>
      customFormatDateTime(
        dateStringToNewDate(checkOut),
        language,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.tripsList
      ),
    [checkOut]
  );

  return (
    <div className={styles.hotelCancellationSummaryPanel}>
      {refundAmount ? (
        <div className="refund-amount section">
          <div className="label">{t("estimatedRefundAmount")}</div>
          <div className="amount">{refundAmount}</div>
        </div>
      ) : null}
      <div className="info section">
        <div className="name">{lodging.name}</div>
        <div className="address">{addressText}</div>
      </div>
      <div className="check-in dates section">
        <div className="label">{t("checkIn")}</div>
        <div className="date">
          {formattedCheckInWeekday}, <span>{formattedCheckInDate}</span>
        </div>
      </div>
      <div className="check-out dates section">
        <div className="label">{t("checkOut")}</div>
        <div className="date">
          {formattedCheckOutWeekday}, <span>{formattedCheckOutDate}</span>
        </div>
      </div>
    </div>
  );
};

export default HotelCancellationSummaryPanel;
