import { Box } from "@material-ui/core";
import type { ReactElement } from "react";
import clsx from "clsx";
import { MyTripsFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import Shapes from "../../../assets/client/shapes.svg";
import "./styles.scss";

export interface INoResultsProps {
  className?: string;
  tripsFilter: MyTripsFilter;
}

export const NoResults = ({
  className,
  tripsFilter,
}: INoResultsProps): ReactElement => {
  const { t } = useI18nContext();

  const getTitle = () => {
    switch (tripsFilter) {
      case MyTripsFilter.PAST_TRIPS:
        return t("noPastBookings");
      case MyTripsFilter.PRIZE_FREEZES:
        return t("noFrozenPrices");
      case MyTripsFilter.HOTELS:
        return t("noHotelBookings");
      case MyTripsFilter.FLIGHTS:
        return t("noTrips");
      default:
        return t("noBookings");
    }
  };

  return (
    <Box className={clsx("no-results", "slot", "nubank", className)}>
      <img src={Shapes} alt="" className="no-results-cart" />
      <h3 className="no-results-title">{getTitle()}</h3>
    </Box>
  );
};
export default NoResults;
