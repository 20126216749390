import { CoreBaseColorConfig } from "./core-design-system";

export const coreDefaultBaseColors: CoreBaseColorConfig = {
  "success-fill-color": "rgba(34, 197, 94, 1.0)",
  "error-fill-color": "rgba(204, 36, 39, 1.0)",
  "caution-fill-color": "rgba(245, 158, 11, 1.0)",
  "neutral-fill-color": "rgba(103, 104, 104, 1.0)",
  "text-inverted-color": "#ffffff",

  error: "rgba(204, 36, 39, 1.0)",

  "core-color-text-primary": "#141414",
  "core-color-text-secondary": "rgba(103, 104, 104, 1.0)",
  "core-color-text-disabled": "#d7d7d7",
  "core-color-text-error": "rgba(204, 36, 39, 1.0)",

  "core-color-surface-color": "#ffffff",
  "core-color-surface-interactive": "#ffffff",
  "core-color-surface-interactive-disabled": "#f5f5f5",

  "core-color-border": "#CECFCD",
  "core-color-border-disabled": "rgba(204, 204, 204, 1.0)",
  "core-color-border-error": "rgba(204, 36, 39, 1.0)",
};
