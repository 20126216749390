import { useContext } from "react";
import { MonthType, useMonth, FirstDayOfWeek } from "@datepicker-react/hooks";
import { context as DateRangePickerContext } from "../../context";
import { Day, EmptyDayTile } from "@components/ui";
import { IMonthBucket } from "@hopper-b2b/types";
import defaultStyles from "./Month.module.scss";
import dayjs from "dayjs";
import clsx from "clsx";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";

export interface IMonthProps extends MonthType {
  firstDayOfWeek: FirstDayOfWeek;
  hideWeekdayLabels?: boolean;
  hideHeader?: boolean;
  hideBody?: boolean;
  headerClassName?: string;
  bodyClassName?: string;
  rootClassName?: string;
  monthFormat?: DateTimeFormatStyle;
  isMiniCalendar?: boolean;
  onClick?: () => void;
  className?: string;
  nextMonth?: (date: string) => void;
  prevMonth?: (date: string) => void;
  currentFocusDate?: Date;
  setCurrentFocusDate?: (date?: Date) => void;
  setIsDateFocused?: (bool: boolean) => void;
  isDateFocused?: boolean;
  priceTags?: string[];
}

export const Month = ({
  year,
  month,
  firstDayOfWeek,
  hideWeekdayLabels,
  hideHeader,
  hideBody,
  headerClassName,
  bodyClassName,
  rootClassName,
  monthFormat,
  isMiniCalendar,
  onClick,
  className,
  currentFocusDate,
  setCurrentFocusDate,
  setIsDateFocused,
  isDateFocused,
  priceTags,
}: IMonthProps) => {
  const styles = useUiStyles(CoreUiComponents.DatePickerMonth, defaultStyles);

  const [block, cn] = useModuleBEM(styles, CoreUiComponents.DatePickerMonth);

  const { formatDateTime, DateTimeFormatStyle } = useI18nContext();

  const weekdayLabelFormat = (date: Date) =>
    formatDateTime(date, DateTimeFormatStyle.Custom({ weekday: "narrow" }));
  const dayLabelFormat = (date: Date) =>
    formatDateTime(date, DateTimeFormatStyle.Custom({ day: "numeric" }));

  const monthFormatStyle =
    monthFormat ?? DateTimeFormatStyle.Custom({ month: "long" });
  // return month name and year if
  //     a) the style says so; or
  //     b) the styls says month only but today's year and the date's year is different
  // otherwise return month name
  const monthLabelFormat = (date: Date) => {
    if (monthFormatStyle.options.year == null) {
      if (dayjs(date).isSame(dayjs(), "year")) {
        return formatDateTime(date, monthFormatStyle);
      } else {
        const style = DateTimeFormatStyle.Custom({
          month: monthFormatStyle.options.month,
          year: "numeric",
        });
        return formatDateTime(date, style);
      }
    } else {
      return formatDateTime(date, monthFormatStyle);
    }
  };

  const { months: monthBuckets } = useContext(DateRangePickerContext);

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    weekdayLabelFormat,
    monthLabelFormat,
    dayLabelFormat,
  });

  return (
    <div
      className={clsx(block, rootClassName, className, {
        [cn("mini-calendar")]: isMiniCalendar,
      })}
      onClick={() => onClick && onClick()}
    >
      {!hideHeader && (
        <div className={clsx(cn("header"), headerClassName)}>
          <div className={cn("header-contents")}>
            <div className={cn("label")}>{monthLabel}</div>
          </div>
        </div>
      )}
      <div className={clsx(cn("body"), bodyClassName)} aria-label={monthLabel}>
        {!hideWeekdayLabels &&
          generateRenderWeekdayLabels({ month, year })(weekdayLabels)}
        {!hideBody &&
          generateRenderDays({
            year,
            monthLabel,
            month: month,
            months: monthBuckets,
            isMiniDay: isMiniCalendar,
            className: className,
            currentFocusDate,
            setCurrentFocusDate,
            setIsDateFocused,
            isDateFocused,
            priceTags,
          })(days)}
      </div>
    </div>
  );
};

export const generateRenderWeekdayLabels =
  ({ month, year }: { month: number; year: number }) =>
  (labels: string[]) => {
    const styles = useUiStyles(CoreUiComponents.DatePickerMonth, defaultStyles);

    const [block, cn] = useModuleBEM(styles, CoreUiComponents.DatePickerMonth);

    return labels.map((label, index) => (
      <div className={cn("weekday-label")} key={`${month} ${year} ${index}`}>
        {label}
      </div>
    ));
  };

export const generateCalculateDateStyle =
  ({
    months,
    currentMonth,
    priceTags,
  }: {
    months: IMonthBucket[] | null;
    currentMonth: number;
    priceTags?: string[];
  }) =>
  (
    day: number | { dayLabel: string; date: Date }
  ): { bucket?: number; priceLabelName: string } => {
    if (months && typeof day !== "number") {
      const dates =
        months.find(({ monthIndex }) => monthIndex === currentMonth)?.dates ||
        [];

      const bucket = dates.find((dateBucket) =>
        dayjs(day.date).isSame(dateBucket.date, "day")
      )?.bucket;

      if (typeof bucket === "number") {
        return {
          bucket,
          priceLabelName: generatePriceLabel(bucket, priceTags),
        };
      }
    }

    return { priceLabelName: "" };
  };

export const generateRenderDays =
  ({
    year,
    month,
    months,
    monthLabel,
    isMiniDay,
    className,
    currentFocusDate,
    setCurrentFocusDate,
    setIsDateFocused,
    isDateFocused,
    priceTags,
  }: {
    year: number;
    month: number;
    months: IMonthBucket[] | null;
    monthLabel: string;
    isMiniDay?: boolean;
    className?: string;
    currentFocusDate?: Date;
    setCurrentFocusDate?: (date?: Date) => void;
    setIsDateFocused?: (bool: boolean) => void;
    isDateFocused?: boolean;
    priceTags?: string[];
  }) =>
  (
    days: (
      | number
      | {
          dayLabel: string;
          date: Date;
        }
    )[]
  ) =>
    days.map((day, index) => {
      const { bucket, priceLabelName } = generateCalculateDateStyle({
        months,
        currentMonth: month,
        priceTags,
      })(day);

      if (typeof day === "number")
        return <EmptyDayTile key={`${month} ${index} ${year}`} />;
      else {
        return (
          <Day
            priceBucket={bucket}
            className={clsx(className)}
            key={`${day.dayLabel} ${day.date} ${year}`}
            monthLabel={monthLabel}
            dayLabel={day.dayLabel}
            date={day.date}
            isMiniDay={isMiniDay}
            currentFocusDate={currentFocusDate}
            setCurrentFocusDate={setCurrentFocusDate}
            setIsDateFocused={setIsDateFocused}
            isDateFocused={isDateFocused}
            priceLabelName={priceLabelName}
          />
        );
      }
    });

export const generatePriceLabel = (
  index: number,
  priceTags?: string[]
): string => (priceTags && priceTags.length > 0 ? priceTags[index] : "");
