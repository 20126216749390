import { useMemo } from "react";
import clsx from "clsx";
import {
  PortalItineraryStatusEnum,
  TagColors,
  type TagInfo,
} from "@hopper-b2b/types";
import { ReservationStateEnum } from "@b2bportal/lodging-api";
import { ButtonWrap, StatusTag } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import ChevronWhite from "../../../assets/client/darkMode/chevron-white.svg";
import { useDarkModePreferred } from "../../../utils/colors";
import Chevron from "../../../assets/client/chevron.svg";
import styles from "./styles.module.scss";

interface TripsCardProps {
  onClick: () => void;
  destination: string;
  dates: string;
  details: string;
  status: PortalItineraryStatusEnum | ReservationStateEnum;
}

export const TripsCard = ({
  onClick,
  destination,
  dates,
  details,
  status,
}: TripsCardProps) => {
  const { t } = useI18nContext();

  const darkModeEnabled = useDarkModePreferred();

  const titleTag = useMemo((): TagInfo | undefined => {
    if (status) {
      switch (status) {
        case PortalItineraryStatusEnum.Confirmed:
        case ReservationStateEnum.Confirmed:
          return {
            label: t("confirmed"),
            type: TagColors.GREEN,
          };
        case PortalItineraryStatusEnum.Pending:
        case ReservationStateEnum.Pending:
          return {
            label: t("pending"),
            type: TagColors.GREY,
          };
        case PortalItineraryStatusEnum.Canceled:
        case ReservationStateEnum.Cancelled:
          return {
            label: t("cancelled"),
            type: TagColors.RED,
          };
        case PortalItineraryStatusEnum.Modified:
          return {
            label: t("itineraryModified"),
            type: TagColors.YELLOW,
          };
        default:
          return undefined;
      }
    }
    return undefined;
  }, [status, t]);

  return (
    <ButtonWrap
      onClick={onClick}
      className={clsx(styles.tripsCard, "tripsCard")}
    >
      <div className="trips-card-content">
        <p className="trips-card-destination">{destination}</p>
        <p className="trips-card-dates">{dates}</p>
        {details ? <p className="trips-card-details">{details}</p> : null}
      </div>
      <div className="trips-card-more">
        {titleTag ? (
          <StatusTag className="title-status-tag" tagInfo={titleTag} />
        ) : null}
        <img
          className="trips-card-chevron"
          src={darkModeEnabled ? ChevronWhite : Chevron}
          alt=""
        />
      </div>
    </ButtonWrap>
  );
};
