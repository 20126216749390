import {
  DisruptionRebookPQRequest,
  airDisruptionApi,
  DisruptionRebookPQResponseEnum,
  CipherText,
  DisruptionRebookPollPQResponseEnum,
  DisruptionRebookPollPQResponse,
} from "@b2bportal/air-disruption-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setDisruptionExerciseToken } from "../store";

const disruptionRebookPollPQ = async (
  req: { contractId: string; token: CipherText },
  index = 0
): Promise<any> => {
  const delayTimes = [0, 2000, 4000, 6000, 8000, 10000, 20000, 30000];
  await new Promise((resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 7)])
  );
  const response = await airDisruptionApi(
    axios
  ).apiV0DisruptionExerciseRebookQuotePollPost(req);
  // eslint-disable-next-line no-useless-catch
  try {
    if (
      response.data.DisruptionRebookPollPQResponse ===
      DisruptionRebookPollPQResponseEnum.RebookPollPQPending
    ) {
      return disruptionRebookPollPQ(req, index++);
    } else if (
      response.data.DisruptionRebookPollPQResponse ===
      DisruptionRebookPollPQResponseEnum.RebookPollPQSuccess
    ) {
      return { response: response.data, token: req.token };
    } else {
      throw new Error(
        "disruptionRebookPQPoll: " +
          response.data.DisruptionRebookPollPQResponse
      );
    }
  } catch (e) {
    throw e;
  }
};

export const disruptionRebookPQ = createAsyncThunk<
  { response: DisruptionRebookPollPQResponse; token: CipherText },
  DisruptionRebookPQRequest
>(
  "disruption/exercise/rebook/quote/schedule",
  async (request: DisruptionRebookPQRequest, thunkAPI) => {
    try {
      const res = await airDisruptionApi(
        axios
      ).apiV0DisruptionExerciseRebookQuoteSchedulePost(request);
      if (
        res.data.DisruptionRebookPQResponse ===
        DisruptionRebookPQResponseEnum.RebookPQSuccess
      ) {
        const pollReq = {
          contractId: request.contractId,
          token: res.data.token,
        };
        return disruptionRebookPollPQ(pollReq);
      } else {
        throw new Error(
          `disruptionRebookPQ: ${res.data.DisruptionRebookPQResponse} ${res.data.reason}`
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
