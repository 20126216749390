import { type PropsWithChildren, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";

import { fetchUserInfo } from "../../../api/session/userInfo";
import { PATH_AUTH_INVALID_SESSION } from "../../../utils/urlPaths";
import { UserContext } from "../../../App";
import { IMPERSONATE_COOKIE_NAME } from "../../AuthModule/constants";

const ProtectedRoute = (props: PropsWithChildren) => {
  const { children } = props;
  const [authenticated, setAuthenticated] = useState(false);
  const { sessionInfo, updateSessionInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const userInfoCall = async () => {
      try {
        const userInfo = await fetchUserInfo({});
        // Check if impersonation cookie is set
        const cookieRegex = `(^|;)\\s*${IMPERSONATE_COOKIE_NAME}\\s*=\\s*([^;]+)`;
        const impersonateCookie = document.cookie.match(cookieRegex)?.pop();

        updateSessionInfo?.({
          ...sessionInfo,
          userInfoResponse: {
            ...(sessionInfo?.userInfoResponse || {}),
            ...userInfo,
          },
          isImpersonation: impersonateCookie === "true",
        });
        setAuthenticated(true);
      } catch (err) {
        const redirectUrl = `${window.location.pathname}${window.location.search}`;
        if (!redirectUrl.includes("/auth/")) {
          sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
        }
        // TODO: before deploying to prod, this should go back to nubank app
        navigate(PATH_AUTH_INVALID_SESSION);
      }
    };
    // userInfo validates both Nequi and Hopper sessions, returning the NequiUserDetails if both are valid.
    if (!authenticated) userInfoCall();
  }, [authenticated, navigate, sessionInfo, updateSessionInfo]);

  return authenticated ? children : null;
};

export default ProtectedRoute;
