import { useI18nContext } from "@hopper-b2b/i18n";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./FlightListNoFlights.module.scss";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CalendarPicker, useFlightShopSearch } from "@components/flights";
import { getHasSetFareClassFilter } from "../../../search/store/selectors";
import { hasSetNonFareclassFiltersSelector } from "../../store";

export type FlightListNoFlightsComponentProps = PlatformComponentProps;
export const FlightListNoFlights = ({
  className,
  children,
}: FlightListNoFlightsComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightListNoFlights;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();
  const {
    context: { openCalendar },
    handlers: { onDateSelectionClick: changeDates, onClose, handleOnSearch },
  } = useFlightShopSearch();

  const hasAppliedFareClassFilter = useSelector(getHasSetFareClassFilter);
  const hasAppliedNonFareclassFilter = useSelector(
    hasSetNonFareclassFiltersSelector
  );

  const noFlightsString = useMemo(() => {
    return hasAppliedFareClassFilter
      ? t("fareClassFlightsNotFound")
      : hasAppliedNonFareclassFilter
      ? t("filteredFlightsNotFound")
      : t("noFlightsAvailable");
  }, [hasAppliedFareClassFilter, hasAppliedNonFareclassFilter, t]);

  return (
    <div className={clsx(block, className)}>
      <div className={cn("title")}>{t("noFlightsFound")}</div>
      <div className={cn("subtitle")}>{noFlightsString}</div>
      <button onClick={() => changeDates()} className={cn("button")}>
        {t("changeDates")}
      </button>
      <CalendarPicker
        open={openCalendar}
        onSearch={handleOnSearch}
        onClose={onClose}
      />
      {children}
    </div>
  );
};
