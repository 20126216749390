import { CoreFlightsComponent } from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import defaultStyles from "./CalendarPicker.module.scss";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface CalendarPickerBottomContentProps {
  priceTagsElement?: JSX.Element | null;
  ready?: boolean;
  onClose?: () => void;
  onSearch?: () => void;
}

export const CalendarPickerBottomContent = (
  props: CalendarPickerBottomContentProps
) => {
  const { t } = useI18nContext();
  const COMPONENT_KEY = CoreFlightsComponent.CalendarPicker;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { priceTagsElement, onClose, onSearch } = props;
  const ready = props.ready ?? true;

  const { isDesktopAndUp } = useDeviceType();

  return isDesktopAndUp ? (
    <div className={cn("bottom-content")}>
      <div className={cn("price-tags-section")}>{priceTagsElement}</div>

      <button
        onClick={onClose}
        disabled={!ready}
        className={cn("apply-button")}
      >
        {t("core-flights.search.dateSelection.apply")}
      </button>
    </div>
  ) : (
    <button
      className={cn("search-button")}
      onClick={onSearch}
      disabled={!ready}
    >
      {t("core-flights.search.dateSelection.search")}
    </button>
  );
};
