import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFlightsComponent, ITripTerminus } from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./FlightLocationSelection.module.scss";
// TODO: We shouldn't be depending on this component
import { B2BSearchAutocomplete, CategoryType } from "./components";
import { useFetchLocation } from "./hooks/useFetchLocation";

export type FlightLocationSelectionProps = {
  locationChangeHandler?: (value: ITripTerminus) => void;
  selectedLocation?: ITripTerminus;
  label: string;
  placeholder?: string;
  className?: string;
  popperClassName?: string;
  disablePortal?: boolean;
  autoFocus?: boolean;
};

export const FlightLocationSelection = ({
  locationChangeHandler,
  selectedLocation,
  label,
  popperClassName,
  className,
  autoFocus = false,
  disablePortal = false,
}: FlightLocationSelectionProps) => {
  const { t } = useI18nContext();

  const styles = useFlightStyles(
    CoreFlightsComponent.FlightLocationSelection,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightLocationSelection
  );

  const {
    context: { loading, destinationCategories },
    handlers: { fetchLocations },
  } = useFetchLocation();

  return (
    <B2BSearchAutocomplete
      className={clsx(block, className)}
      // TODO: This cast shouldn't need to happen
      valueCategories={
        destinationCategories as unknown as CategoryType<ITripTerminus>[]
      }
      fetchValueCategories={fetchLocations}
      popperClassName={clsx(cn("popper"), popperClassName)}
      loading={loading}
      noTabIndex
      customIcon={<Icon iconName={IconNameEnum.mapPin} />}
      label={label}
      autoFocus={autoFocus}
      loadingText={`${t("core-flights.search.locationSelection.searching")}`}
      value={selectedLocation ?? null}
      setValue={locationChangeHandler ?? (() => undefined)}
      freeSolo
      getOptionSelected={(option, value) => {
        return (
          value != null &&
          option != null &&
          value.id.code.code === option.id.code.code
        );
      }}
      disablePortal={disablePortal}
    />
  );
};
