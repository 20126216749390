import { useI18nContext } from "@hopper-b2b/i18n";
import type { BedTypeList } from "@b2bportal/lodging-api";
import clsx from "clsx";
import { ReactComponent as Guests } from "../../../assets/client/two-users.svg";
import { ReactComponent as Bed } from "../../../assets/client/bed.svg";
import styles from "./styles.module.scss";

export interface RoomDetailsModalHeaderProps {
  name: string;
  maxAdults: number;
  beds: BedTypeList;
}

export function RoomDetailsModalHeader({
  name,
  maxAdults,
  beds,
}: RoomDetailsModalHeaderProps) {
  const { t } = useI18nContext();

  return (
    <div
      className={clsx(styles.RoomDetailsModalHeader, RoomDetailsModalHeader)}
    >
      <h4 className="title" gutterBottom>
        {name}
      </h4>
      <div className="icons">
        <Bed className="icon" />
        <span className="beds">{beds.description}</span>
      </div>
      {maxAdults ? (
        <div className="icons">
          <Guests className="icon" />
          <span className="guests">
            {t("roomFitsGuests", {
              count: maxAdults,
            })}
          </span>
        </div>
      ) : null}
    </div>
  );
}
