import {
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ButtonWrap } from "@hopper-b2b/ui";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { useEnablePriceDropProtection } from "@hopper-b2b/utilities";

import PriceDropProtectionIcon from "../../../assets/client/price-drop-protection-icon.svg";
import PriceDropProtectionIconDark from "../../../assets/client/darkMode/price-drop-protection-icon.svg";
import PriceDropProtectionIconHalfsheet from "../../../assets/client/uv/price-drop-protection-lavender-icon.svg";
import {
  isUserTypeCookieUltravioleta,
  useDarkModePreferred,
} from "../../../utils/colors";
import { FintechHalfSheet } from "../../FintechHalfSheet";
import { FintechBanner } from "../../FintechBanner";
import { TermsAndConditionsContext } from "../../TermsAndConditionsWrapper/component";
import "./styles.scss";

// eslint-disable-next-line @nx/enforce-module-boundaries
import { shopSelectors } from "@hopper-b2b/flights";

interface FlightPriceDropProtectionBannerProps {
  isEligible: boolean;
  title?: string;
  subtitle?: string | JSX.Element;
  showMore?: boolean;
  hideBrand?: boolean;
  className?: string;
  children?: ReactNode;
}

const FlightPriceDropProtectionBanner = ({
  isEligible,
  title,
  subtitle,
  showMore = false,
  hideBrand = false,
  className,
  children,
}: FlightPriceDropProtectionBannerProps) => {
  const { t } = useI18nContext();
  const { setOpenModal } = useContext(TermsAndConditionsContext);
  const isDarkMode = useDarkModePreferred();

  const enabledPriceDropProtection = useEnablePriceDropProtection();
  const isUserUltravioletta = isUserTypeCookieUltravioleta();
  const [openHalfSheet, setOpenHalfSheet] = useState(false);
  const [openSeeRules, setOpenSeeRules] = useState(false);
  const priceDropViewedProperties = useSelector(
    shopSelectors.getPriceDropProperties
  );
  const handleOpenHalfSheet = useCallback(() => {
    setOpenHalfSheet(true);
    trackEvent({
      eventName: VIEWED_PRICE_DROP_DETAILS,
      properties: { ...priceDropViewedProperties, page: "flight_list" },
    });
  }, [priceDropViewedProperties]);

  const handleCloseHalfSheet = useCallback(() => {
    setOpenHalfSheet(false);
  }, []);

  const handleCloseSeeRules = useCallback(() => {
    setOpenSeeRules(false);
  }, []);

  const bannerCopy = useMemo(() => {
    // check for undefined to allow tenants to not have a title by sending null
    return {
      title:
        title !== undefined ? (
          title
        ) : (
          <I18nMarkup
            className="default-text"
            tKey={"priceDropProtectionIncludedBold"}
          />
        ),
      subtitle: subtitle || undefined,
    };
  }, [subtitle, title]);

  const isPDPEnabledAndEligible =
    isEligible && isUserUltravioletta && enabledPriceDropProtection;

  useEffect(() => {
    if (isPDPEnabledAndEligible) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          ...priceDropViewedProperties,
          page: "flight_list",
        },
      });
    }
  }, []);

  return isPDPEnabledAndEligible ? (
    <>
      <FintechBanner
        onClick={showMore ? handleOpenHalfSheet : null}
        fintechIcon={
          isDarkMode ? PriceDropProtectionIconDark : PriceDropProtectionIcon
        }
        hideBrand={hideBrand}
        title={bannerCopy.title}
        subtitle={bannerCopy.subtitle}
        className={className}
      />
      {showMore ? (
        <>
          <FintechHalfSheet
            isOpen={openHalfSheet}
            onClose={handleCloseHalfSheet}
            icon={PriceDropProtectionIconHalfsheet}
            title={t("priceDropHalfSheetTitle")}
            description={
              <div>
                {t("priceDropHalfSheetDescription")}{" "}
                <ButtonWrap
                  className="nubank-terms-container"
                  onClick={() => setOpenSeeRules(true)}
                >
                  <p>{t("seeRules")}</p>
                </ButtonWrap>
              </div>
            }
          />
          <FintechHalfSheet
            isOpen={openSeeRules}
            onClose={handleCloseSeeRules}
            title={t("priceDropProtectionFaq.title")}
            description={
              <div className="faq">
                {t("priceDropProtectionFaq.introContent")}
                <ul>
                  <li>{t("priceDropProtectionFaq.first")}</li>
                  <li>{t("priceDropProtectionFaq.second")}</li>
                  <li>{t("priceDropProtectionFaq.third")}</li>
                  <li>{t("priceDropProtectionFaq.fourth")}</li>
                  <li>{t("priceDropProtectionFaq.fifth")}</li>
                </ul>
                <div className="terms-and-conditions">
                  <Trans
                    i18nKey={"priceDropProtectionFaq.termsAndConditionsEntry"}
                    components={[
                      <ButtonWrap
                        className="terms-link"
                        onClick={() => setOpenModal(true)}
                      />,
                    ]}
                  />
                </div>
              </div>
            }
          />
        </>
      ) : null}
    </>
  ) : null;
};

export default FlightPriceDropProtectionBanner;
