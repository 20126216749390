import { useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { customFormatDateTime } from "@hopper-b2b/utilities";
import "./styles.scss";
import { MultiAirlineBadge } from "../../MultiAirlineBadge";

export interface IFareDetailsSliceHeaderProps {
  origin: string;
  destination: string;
  date: Date;
  airline: string;
  isHackerFare: boolean;
}

const FareDetailsSliceHeader = ({
  origin,
  destination,
  date,
  airline,
  isHackerFare,
}: IFareDetailsSliceHeaderProps) => {
  const { t, language: locale, brand } = useI18nContext();

  const formattedWeekday = useMemo(
    () =>
      formatDateTime(
        date,
        locale,
        DateTimeFormatStyle.Custom({
          weekday: "long",
        })
      ),
    [date, locale]
  );

  const formattedDate = useMemo(
    () =>
      customFormatDateTime(
        date,
        locale,
        DateTimeFormatStyle.MediumDate,
        brand?.customDateTimeFormats?.fareDetails
      ),
    [brand?.customDateTimeFormats?.fareDetails, date, locale]
  );

  return (
    <>
      <div className="fare-slice-header-flight-details">
        <p className="fare-slice-header-location">
          {t("originToDestination", { origin, destination })}
        </p>
        {/* TODO: When VI is enabled, add in check to see if this is a VI flight with multiple stops */}
        {/* <MultiAirlineBadge airline={airlineName} /> */}
        <p className="fare-slice-header-airline">{airline}</p>
      </div>
      <div className="fare-slice-header-date-details">
        <p className="fare-slice-header-weekday">{formattedWeekday}</p>
        <p className="fare-slice-header-month">{formattedDate}</p>
      </div>
    </>
  );
};

export default FareDetailsSliceHeader;
