import type { TripType } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  FlightRatingsEnum,
  type IFareData,
  type IFlightFares,
} from "@b2bportal/core-types";
import { useMemo } from "react";
import defaultStyles from "./FlightFareGrid.module.scss";
import { useFlightSlot } from "../../../../slots";

export interface TFlightFareGrid {
  className?: string;
  fares: IFlightFares;
  fareShelfFilter?: number[];
  tripType: TripType;
  tagsLimit?: number;
  onFareGridClick: (fare: IFareData) => void;
}

export const FlightFareGrid = ({
  fares,
  tripType,
  tagsLimit = 2,
  onFareGridClick,
  fareShelfFilter,
}: TFlightFareGrid) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightFareGrid,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(styles, CoreFlightsComponent.FlightFareGrid);

  const FlightSlot = useFlightSlot();
  const grid = useMemo(() => {
    return [
      FlightRatingsEnum[FlightRatingsEnum.basic],
      FlightRatingsEnum[FlightRatingsEnum.standard],
      FlightRatingsEnum[FlightRatingsEnum.enhanced],
      FlightRatingsEnum[FlightRatingsEnum.premium],
      FlightRatingsEnum[FlightRatingsEnum.luxury],
    ].map((fareKey, index) => {
      const fare: IFareData | undefined = fares[fareKey];
      return fare != null ? (
        <FlightSlot.FlightFareGridItem
          key={`fare-grid-${fare.fareId}`}
          fare={fare}
          tripType={tripType}
          tagsLimit={tagsLimit}
          onFareGridClick={onFareGridClick}
          isFilteredOut={!(fareShelfFilter?.includes(fare.fareShelf) ?? false)}
          numberOfFares={fare.numberOfFares}
        />
      ) : (
        <div key={`no-fare-${index}`} className={cn("empty-fare")}></div>
      );
    });
  }, []);
  return <ul className={block}>{grid}</ul>;
};
