import type { Prices } from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface UsePriceDisplayParams {
  prices: Prices;
  formatOptions?: Intl.NumberFormatOptions;
  showRewards?: boolean;
}

export interface UsePriceDisplayResponse {
  priceText: string;
  rewardsText: string;
  combinedText: string;
  showRewardPrice: boolean;
}

/**
 * TODO: Later on this should connect to a store and support multiple rewards accounts.
 * For now this just takes the first reward in account
 */
export const usePriceDisplay = ({
  prices: { accountSpecific, fiat },
  formatOptions = {},
  showRewards = true,
}: UsePriceDisplayParams): UsePriceDisplayResponse => {
  const { formatFiatCurrency, formatRewardsCurrency } = useI18nContext();

  const priceText = formatFiatCurrency(fiat, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...formatOptions,
  });

  const [rewardsProgram] = Object.values(accountSpecific);
  const rewardsText = formatRewardsCurrency(rewardsProgram, formatOptions);
  const showRewardPrice =
    showRewards && rewardsText !== "" && rewardsText !== undefined;

  const combinedText = `${priceText}${
    showRewardPrice ? ` / ${rewardsText}` : ""
  }`;

  return {
    priceText,
    rewardsText,
    combinedText,
    showRewardPrice,
  };
};
