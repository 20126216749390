import { MonthAndDatePickerComponent } from "./components/MonthAndDatePicker";
import { IMonthAndDatePickerProps } from "./components/MonthAndDatePicker/MonthAndDatePicker";

export * from "./components/DateRangePicker";

export { MonthAndDatePickerType } from "./components/MonthAndDatePicker";
export { PickerType } from "./components/DateRangePicker";

export interface CalendarComponentProps extends IMonthAndDatePickerProps {}
export const Calendar = MonthAndDatePickerComponent;
