import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import { SortOption } from "@hopper-b2b/lodging-utils";
import {
  AccordionCollection,
  ActionButton,
  ActionLink,
  AmenitiesFilter,
  MobilePopoverCard,
  PriceRange,
  SortOptionSelection,
} from "@hopper-b2b/ui";
import { useTrackEvents } from "@hopper-b2b/lodging";
import {
  type ILodgingFilterState,
  LodgingShopTrackingEvents,
  type LodgingsFilterBoundaries,
  type PriceRange as PriceRangeType,
} from "@hopper-b2b/types";
import { FilterTitle } from "../FilterTitle";
import { useCallback, useMemo } from "react";
import { StarRatingFilter } from "../../StarRatingFilter";
import { UserRatingFilter } from "../../UserRatingFilter";
import { ReactComponent as Close } from "../../../assets/client/close.svg";
import "./styles.scss";

export interface IFiltersModalProps {
  open: boolean;
  filters: ILodgingFilterState;
  closeFiltersModal: () => void;
  onFilterPriceRangeChange: (
    nextValue: Pick<PriceRangeType, "min" | "max">
  ) => void;
  sort?: SortOption;
  setSort?: (sortOption: SortOption) => {
    type: string;
    value: any;
  };
  filterBoundaries: LodgingsFilterBoundaries;
  onAmenitiesChange: (value: string[]) => void;
  onAmenitiesTypeChange: (value: boolean) => void;
  resetFilters: () => void;
  onStarRatingChange(nextValue: number[]): void;
  onUserRatingChange(nextValue: number): void;
}
export const FiltersModal = ({
  open,
  filters: {
    priceRange,
    starRating,
    userRating,
    amenities,
    filterAmenitiesAnd,
  },
  closeFiltersModal,
  onFilterPriceRangeChange,
  sort,
  setSort,
  filterBoundaries,
  onAmenitiesChange,
  onAmenitiesTypeChange,
  resetFilters,
  onStarRatingChange,
  onUserRatingChange,
}: IFiltersModalProps) => {
  const { t } = useI18nContext();
  const { formatCurrency } = useI18nCurrency();
  const trackEvent = useTrackEvents();

  const handleCloseFilter = useCallback(() => {
    closeFiltersModal();
  }, [closeFiltersModal]);

  const handleAmenitiesFiltersChange = useCallback(
    (amenities: string[]) => {
      onAmenitiesChange(amenities);
    },
    [onAmenitiesChange]
  );

  const handleAmenitiesTypeChange = useCallback(
    (andFilter: boolean) => {
      onAmenitiesTypeChange(andFilter);
    },
    [onAmenitiesTypeChange]
  );

  const sortOptions: { value: SortOption; label: string }[] = [
    {
      value: SortOption.MOST_RECOMMENDED,
      label: t("sortOption.recommended"),
    },
    {
      value: SortOption.STAR_RATING,
      label: t("starRating"),
    },
    {
      value: SortOption.USER_RATING,
      label: t("userRating"),
    },
    {
      value: SortOption.PRICE,
      label: t("price"),
    },
  ];

  const handleSort = useCallback(
    (option: any) => {
      setSort(option);
      trackEvent(LodgingShopTrackingEvents.hotel_updated_list_page);
    },
    [setSort, trackEvent]
  );

  const accordionContents = useMemo(
    () => [
      // Sort by
      {
        title: (
          <div className="header-container sort">
            {t("searchFilter.sortHeader")}{" "}
          </div>
        ),
        body: (
          <div className="mobile-filter-option-selection sort">
            <SortOptionSelection
              showDropdownContentOnly
              sortOption={sort}
              setSortOption={handleSort}
              sortOptions={sortOptions}
            />
          </div>
        ),
      },
      // MaxPrice
      {
        title: (
          <div className="header-container price">
            {t("searchFilter.price")}
          </div>
        ),
        body: (
          <div className="mobile-filter-option-selection price">
            <p>{t("pricePerNightBeforeFees")}</p>
            <PriceRange
              min={priceRange?.min}
              max={priceRange?.max}
              lowest={priceRange?.lowest}
              highest={priceRange?.highest}
              onFilterPriceRangeChange={onFilterPriceRangeChange}
            />
            <div className="price-stationary">
              <p className="left">
                {formatCurrency(priceRange?.min, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
              <p className="right">
                {formatCurrency(priceRange?.max, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </div>
        ),
      },
      // Star rating
      {
        title: (
          <div className="header-container star-rating">{t("starRating")}</div>
        ),
        body: (
          <div className="mobile-filter-option-selection star-rating">
            <StarRatingFilter
              value={starRating}
              onChange={onStarRatingChange}
              ariaLabel={t("chooseAStarRating")}
              dropdownLabel={t("starRating")}
            />
          </div>
        ),
      },
      // User rating
      {
        title: (
          <div className="header-container user-rating">{t("userRating")}</div>
        ),
        body: (
          <div className="mobile-filter-option-selection user-rating">
            <UserRatingFilter
              value={userRating}
              onChange={onUserRatingChange}
              ariaLabel={t("chooseAUserRating")}
              dropdownLabel={t("userRating")}
            />
          </div>
        ),
      },
      // Amenities
      {
        title: (
          <div className="header-container amenities">{t("amenities")}</div>
        ),
        body: (
          <div className="mobile-filter-option-selection amenities">
            <AmenitiesFilter
              amenities={filterBoundaries.amenities}
              andFilter={filterAmenitiesAnd}
              appliedFilters={amenities}
              onChange={handleAmenitiesFiltersChange}
              onTypeChange={handleAmenitiesTypeChange}
            />
          </div>
        ),
      },
    ],
    [sort, priceRange, starRating, userRating, amenities, filterBoundaries]
  );

  const renderHeader = useMemo(() => {
    return {
      topRightButton: (
        <ActionLink
          className="filter-close-button"
          content={<Close className="close-button-icon" />}
          label={t("close")}
          onClick={handleCloseFilter}
        />
      ),
    };
  }, [handleCloseFilter, t]);

  return (
    <div className="filters-modal-root">
      <div className="fitlers-modal-container">
        <MobilePopoverCard
          open={open}
          onClose={handleCloseFilter}
          fullScreen={true}
          className="filters-modal full-screen"
          contentClassName="filters-modal-wrapper"
          bottomButton={
            <ActionButton
              className="filters-modal-button"
              message={t("searchFilter.apply")}
              onClick={handleCloseFilter}
            />
          }
          {...renderHeader}
        >
          <div className="filters-modal-content-header">
            <FilterTitle title={t("filters")} />
            <button
              className="filters-modal-reset-button"
              onClick={resetFilters}
            >
              {t("resetFilters")}
            </button>
          </div>
          <div className="filters-modal-content-container">
            <AccordionCollection
              className="filters-modal-accordions-container"
              accordionContents={accordionContents}
            />
          </div>
        </MobilePopoverCard>
      </div>
    </div>
  );
};
