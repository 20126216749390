import defaultStyles from "./styles.module.scss";
import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { usePriceDisplay, type UsePriceDisplayParams } from "@components/ui";
import { CoreUiComponents } from "@b2bportal/core-types";
import clsx from "clsx";

export interface PriceDisplayProps extends UsePriceDisplayParams {
  className?: string;
}

export const PriceDisplay: React.FC<PriceDisplayProps> = (props) => {
  const styles = useUiStyles(CoreUiComponents.PriceDisplay, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.PriceDisplay);

  const { combinedText } = usePriceDisplay(props);

  return <span className={clsx(block, props.className)}>{combinedText}</span>;
};
