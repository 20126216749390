import { connect, type ConnectedProps } from "react-redux";
import {
  resetFilters,
  setFilterAmenities,
  setFilterAmenitiesType,
  setFilterFreeCancellation,
  setFilterPriceRange,
  setFilterStarRating,
  setFilterUserRating,
  setSort,
} from "./actions/actions";
import { Availability } from "./Availability";
import {
  getFilters,
  getFromDate,
  getGuests,
  getIsOverFiltered,
  getLodgings,
  getLodgingsFilterBoundaries,
  getPlace,
  getRooms,
  getSort,
  getTotalLodgingCount,
  getUntilDate,
  type ILodgingAvailabilityState,
} from "./reducer";

const mapDispatchToProps = {
  setFilterFreeCancellation,
  setFilterStarRating,
  setFilterUserRating,
  setFilterAmenities,
  setFilterAmenitiesType,
  setFilterPriceRange,
  setSort,
  resetFilters,
};
const mapStateToProps = (state: {
  lodgingAvailability: ILodgingAvailabilityState;
}) => {
  const lodgings = getLodgings(state);
  const totalLodgingCount = getTotalLodgingCount(state);

  return {
    isOverFiltered: getIsOverFiltered(
      state.lodgingAvailability.lodgings,
      lodgings
    ),
    place: getPlace(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    lodgings,
    totalLodgingCount,
    guests: getGuests(state),
    rooms: getRooms(state),
    filters: getFilters(state),
    filterBoundaries: getLodgingsFilterBoundaries(state),
    sort: getSort(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedLodgingAvailability = connector(Availability);

export type AvailabilityProps = ConnectedProps<typeof connector>;
