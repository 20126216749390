import defaultStyles from "./FlightSearch.module.scss";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import {
  CalendarPicker,
  FlightCategorySelection,
  FlightDateSelection,
  FlightLocationSelection,
  FlightPassengerSelection,
} from "@components/flights";
import { useFlightSearch } from "./hooks";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceType } from "@b2bportal/core-utilities";
import { FormEvent } from "react";

export interface FlightSearchComponentProps extends PlatformComponentProps {
  showCalendarBackButton?: boolean;
  onSearch?: () => void;
}

export const FlightSearch = (props: FlightSearchComponentProps) => {
  const {
    className,
    children,
    showCalendarBackButton,
    onSearch: onSearchProp,
  } = props;

  const COMPONENT_KEY = CoreFlightsComponent.FlightSearch;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { isDesktopAndUp } = useDeviceType();

  const { context, handlers } = useFlightSearch();

  const { t } = useI18nContext();

  const onSubmitForm = (e?: FormEvent) => {
    e?.preventDefault();
    if (onSearchProp != null) {
      onSearchProp();
    } else if (context.validSearchParams != null) {
      handlers.onSearch(context.validSearchParams);
    }
  };

  return (
    <div className={clsx(block, className)}>
      <form className={clsx(cn("form"))} onSubmit={onSubmitForm}>
        <div
          className={clsx(
            cn("category-selection-container"),
            cn("cell-content")
          )}
        >
          <FlightCategorySelection
            tripCategoryChangeHandler={handlers.onTripCategoryChange}
            category={context.tripCategory}
          />
        </div>
        <div
          className={clsx(
            cn("traveler-selection-container"),
            cn("cell-content")
          )}
        >
          <FlightPassengerSelection
            updatePaxCount={handlers.onPassengerCountChange}
            paxCount={context.passengerCounts}
          />
        </div>
        <div
          className={clsx(cn("origin-selection-container"), cn("cell-content"))}
        >
          <FlightLocationSelection
            locationChangeHandler={handlers.onOriginChange}
            popperClassName={cn("location-selection-popper", {
              mobile: !isDesktopAndUp,
            })}
            label={t("searchControl.whereFrom")}
            selectedLocation={context.origin}
            id="location-selection-origin"
          />
        </div>
        <div
          className={clsx(
            cn("destination-selection-container"),
            cn("cell-content")
          )}
        >
          <FlightLocationSelection
            locationChangeHandler={handlers.onDestinationChange}
            popperClassName={cn("location-selection-popper", {
              mobile: !isDesktopAndUp,
            })}
            label={t("searchControl.whereTo")}
            selectedLocation={context.destination}
            id="location-selection-destination"
          />
        </div>
        <div
          className={clsx(cn("dates-selection-container"), cn("cell-content"))}
        >
          <FlightDateSelection />
        </div>

        <div className={clsx(cn("search-button-container"))}>
          <button
            disabled={!context.isSearchComplete}
            className={cn("search-button")}
          >
            {t("searchButton")}
          </button>
        </div>

        {children}
      </form>
      <div className={clsx(cn("continue-button-container"))}>
        <button
          onClick={() => {
            handlers.onNavigateToCalendar();
          }}
          disabled={!context.isSearchCompleteWithoutDates}
          className={cn("continue-button")}
        >
          {t("core-flights.search.dateSelection.chooseDates")}
        </button>
      </div>
      <CalendarPicker
        open={context.openCalendar}
        onSearch={onSubmitForm}
        onClose={handlers.onCalendarClose}
        showBackButton={showCalendarBackButton}
      />
    </div>
  );
};
