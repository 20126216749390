import { useMemo } from "react";
import clsx from "clsx";
import { usePriceFormatterContext } from "@hopper-b2b/contexts";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { Prices } from "@b2bportal/lodging-api";
import { ReactComponent as ArrowDown } from "../../../assets/client/arrow-down.svg";
import styles from "./styles.module.scss";

interface LodgingCardCashBackProps {
  originalPrice: Prices;
  finalPrice: Prices;
}

const LodgingCardCashBack = ({
  originalPrice,
  finalPrice,
}: LodgingCardCashBackProps) => {
  const { brand } = useI18nContext();
  const { formatPrice } = usePriceFormatterContext();

  const discount = useMemo(
    () =>
      Math.round(
        100 - (finalPrice?.fiat?.value / originalPrice?.fiat?.value) * 100
      ),
    [originalPrice, finalPrice]
  );

  return (
    <div
      className={clsx(
        styles.LodgingCardOriginalPriceContainer,
        "LodgingCardOriginalPriceContainer"
      )}
    >
      <div className={clsx(styles.LodgingCardOriginalPrice)}>
        {formatPrice(originalPrice, {
          maximumFractionDigits: brand?.lodgingCardPriceRounding || 0,
          minimumFractionDigits: brand?.lodgingCardPriceRounding || 0,
          roundingMode: "ceil",
        })}
      </div>
      <div className={clsx(styles.LodgingCardOriginalPriceDiscount)}>
        <ArrowDown
          className={clsx(
            styles.LodgingCardOriginalPriceDiscountIcon,
            "LodgingCardOriginalPriceDiscountIcon"
          )}
        />
        {discount}%
      </div>
    </div>
  );
};

export default LodgingCardCashBack;
