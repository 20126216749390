import { useEffect, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";

interface HorizontalScrollListProps<GenericItemType> {
  columns: Array<GenericItemType>;
  scrollingDivClassName?: string;
  parentDivClassName?: string;
  itemWidth?: number;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
  renderColumn: (item: GenericItemType, index: number) => JSX.Element;
  selectedId: string | null;
  getItemId: (item: GenericItemType) => string;
  listItemClassName?: string;
  onItemScroll: (idx: number) => void;
}

export function HorizontalScrollList<GenericItemType>({
  columns,
  scrollingDivClassName,
  parentDivClassName,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  itemWidth,
  renderColumn,
  selectedId,
  getItemId,
  listItemClassName,
  onItemScroll,
}: HorizontalScrollListProps<GenericItemType>) {
  const parentRef = useRef<HTMLDivElement>();

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columns.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemWidth,
    gap: 16,
    paddingStart: 16,
    scrollPaddingStart: 16,
    paddingEnd: 16,
    overscan: 4,
  });

  // Loads next page when end of list is reached
  useEffect(() => {
    const [lastItem] = [...columnVirtualizer.getVirtualItems()].reverse();
    if (!lastItem) {
      return;
    }
    if (
      lastItem.index >= columns.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    columnVirtualizer,
    hasNextPage,
    fetchNextPage,
    columns.length,
    isFetchingNextPage,
  ]);

  // We need to track when the div is scrolling, and when to "snap" to a card
  useEffect(() => {
    const parent = parentRef.current;
    let isScrollingTimeout;

    const handleScroll = () => {
      clearTimeout(isScrollingTimeout);
      isScrollingTimeout = setTimeout(
        () => {
          const scrolledLeft = parent.scrollLeft;
          // Calculate the item index, divide (and round) the amount scrolled by the item plus its piece of the gap
          // this will give an integer that should correspond
          const itemIndex = Math.round(scrolledLeft / (itemWidth + 16));

          columnVirtualizer.scrollToIndex(itemIndex, {
            align: "start",
            behavior: "smooth",
          });
          onItemScroll(itemIndex);
        },
        // only apply if we haven't been scrolling for 150ms
        150
      );
    };

    if (parent) {
      parent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parent) {
        parent.removeEventListener("scroll", handleScroll);
      }
      clearTimeout(isScrollingTimeout);
    };
  }, [columnVirtualizer, itemWidth, onItemScroll]);

  useEffect(() => {
    if (typeof selectedId === "string") {
      const index = columns.findIndex(
        (c: GenericItemType) => getItemId(c) === selectedId
      );
      columnVirtualizer.scrollToIndex(index, {
        align: "start",
      });
    }
  }, [columnVirtualizer, columns, getItemId, selectedId]);

  return (
    <div ref={parentRef} className={clsx("List", parentDivClassName)}>
      <div
        className={scrollingDivClassName}
        style={{
          width: `${columnVirtualizer.getTotalSize()}px`,
        }}
      >
        {columnVirtualizer.getVirtualItems().map((virtualColumn) => (
          <div
            key={virtualColumn.index}
            className={listItemClassName}
            style={{
              width: `${itemWidth}px`,
              transform: `translateX(${virtualColumn.start}px)`,
            }}
          >
            {renderColumn(columns[virtualColumn.index], virtualColumn.index)}
          </div>
        ))}
      </div>
    </div>
  );
}
