import * as React from "react";
import { ReactNode } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Dialog, DialogContent, Icon } from "@b2bportal/core-ui";
import defaultStyles from "./FlightPassengerSelection.module.scss";
import {
  useModuleBEM,
  useFlightStyles,
  IconNameEnum,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  IPassengerCounts,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import clsx from "clsx";
import {
  MAXIMUM_PASSENGERS_COUNT,
  PassengerCountPicker,
  useFlightPassengerSelection,
} from "@components/flights";

export const initialPaxCount: IPassengerCounts = {
  adultsCount: 1,
  childrenCount: 0,
  infantsInSeatCount: 0,
  infantsOnLapCount: 0,
};

export type TravelerPickerProps = PlatformComponentProps & {
  paxCount?: IPassengerCounts;
  disabled?: boolean;
  updatePaxCount: (count: IPassengerCounts) => void;
  endIcon?: JSX.Element;
  unlimited?: boolean;
  maximumCount?: number;
  dialogClassName?: string;
};

export const FlightPassengerSelection = ({
  paxCount = initialPaxCount,
  disabled,
  updatePaxCount,
  dialogClassName,
  unlimited = false,
  maximumCount = MAXIMUM_PASSENGERS_COUNT,
  children,
}: TravelerPickerProps) => {
  const { t } = useI18nContext();

  const styles = useFlightStyles(
    CoreFlightsComponent.FlightPassengerSelection,
    defaultStyles
  );
  const [, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightPassengerSelection
  );
  const { context, handlers } = useFlightPassengerSelection({
    paxCount,
    updatePaxCount,
    dialogClassName,
    unlimited,
    maximumCount,
  });
  const {
    applyButtonText,
    maximumExceeded,
    travelerSelection,
    selectedPassengerLabel,
  } = context;
  const {
    handleOnChange,
    setOpenPassengerSelectionDialog,
    openPassengerSelectionDialog,
    closeDialog,
    setTravelerSelection,
  } = handlers;

  const applyButton = (
    <div className={cn("apply-button-container")}>
      <button
        className={cn("apply-button")}
        onClick={handleOnChange}
        disabled={maximumExceeded}
      >
        {applyButtonText}
      </button>
    </div>
  );

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setOpenPassengerSelectionDialog(true);
        }}
        disabled={disabled}
        className={cn("button")}
      >
        <Icon
          iconName={IconNameEnum.passengerSelection}
          className={clsx(cn("icon"), cn("icon-start"))}
        />
        <div className={cn("label")}>{selectedPassengerLabel}</div>
        <Icon
          iconName={IconNameEnum.dropdownTriangle}
          className={clsx(cn("icon"), cn("icon-end"))}
        />
      </button>
      <Dialog
        open={openPassengerSelectionDialog}
        className={dialogClassName}
        onClose={closeDialog}
      >
        <DialogContent
          className={cn("dialog")}
          title={t("passengerCount.modalTitle")}
          bottomFixedContent={applyButton}
        >
          <PassengerCountPicker
            minimumCount={1}
            setPassengerCounts={handleOnChange}
            className=""
            onChange={setTravelerSelection}
            counts={travelerSelection}
            onClose={closeDialog}
          />
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};
