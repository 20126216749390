import clsx from "clsx";
import defaultStyles from "./DisruptionExerciseSegmentSelectCard.module.scss";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { DisruptionExerciseSelection } from "@b2bportal/core-disruption";
import { Icon } from "@components/ui";

interface DisruptionExerciseSegmentSelectCardProps {
  title: string;
  subtitle: string;
  onSelect?: () => void;
  isDestination: boolean;
  selected: boolean;
  disabled: boolean;
}

export const DisruptionExerciseSegmentSelectCard = (
  props: DisruptionExerciseSegmentSelectCardProps
) => {
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseSegmentSelectCard;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const destinationIcon = (
    <Icon iconName={IconNameEnum.mapPin} className={cn("destinationIcon")} />
  );

  const originIcon = (
    <Icon
      iconName={IconNameEnum.disruptionOriginSegmentIcon}
      className={cn("originIcon", {
        disabled: props.disabled,
        selected: props.selected,
      })}
    />
  );

  const title = (
    <p
      className={cn("title", {
        disabled: props.disabled,
        selected: props.selected,
      })}
    >
      {props.title}
    </p>
  );

  const subtitle = (
    <p
      className={cn("subtitle", {
        disabled: props.disabled,
        selected: props.selected,
      })}
    >
      {props.subtitle}
    </p>
  );

  return (
    <DisruptionExerciseSelection
      onSelect={props.onSelect}
      isSelected={props.selected}
      hideRadio={props.isDestination}
      content={
        <div className={cn("segmentSelectionCardContainer")}>
          {props.isDestination && destinationIcon}
          {!props.isDestination && originIcon}
          <div
            className={clsx(
              cn("segmentSelectionCardContent"),
              cn("segmentSelectionCardContainerChild")
            )}
          >
            {title}
            {subtitle}
          </div>
        </div>
      }
    />
  );
};

export default DisruptionExerciseSegmentSelectCard;
