import { NavigationFlow } from "../../common/flow";
import { FlightSteps } from "./FlightNavigationFlowProvider";

const useGoToNextStep = (currentStep: FlightSteps) => {
  return NavigationFlow.useGoToNextStep<FlightSteps>(currentStep);
};

const useGoToStep = () => {
  return NavigationFlow.useGoToStep<FlightSteps>();
};

export const FlightsNavigationFlow = {
  useGoToNextStep,
  useGoToStep,
};
