import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom-v5-compat";
import { LodgingSelectionEnum } from "@b2bportal/lodging-api";
import {
  getChildren,
  getFromDate,
  getNumberOfAdults,
  getRooms,
  getUntilDate,
} from "@hopper-b2b/lodging-availability";
import {
  useDeviceTypes,
  useLodgingFeatureFlagsContext,
} from "@hopper-b2b/utilities";
import type { AvailabilityByLodgingQuery } from "../../../api/availability/fetchAvailability";

export const useLodgingQuery = (): AvailabilityByLodgingQuery => {
  const params = useParams();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const featureFlags = useLodgingFeatureFlagsContext();

  const fromDate = useSelector(getFromDate);
  const untilDate = useSelector(getUntilDate);

  const adults = useSelector(getNumberOfAdults);
  const children = useSelector(getChildren);

  const rooms = useSelector(getRooms);

  return useMemo(
    () => ({
      stayDates: {
        from: fromDate,
        until: untilDate,
      },
      guests: {
        adults,
        children,
      },
      platform: isMobile ? "Mobile" : "Desktop",
      lodgingSelection: {
        lodgingIds: [params.id],
        preserveOrder: true,
        LodgingSelection: LodgingSelectionEnum.LodgingIds,
      },
      rooms: {
        numberOfRooms: featureFlags.showLodgingRooms ? rooms : 1,
      },
    }),
    [
      adults,
      children,
      featureFlags.showLodgingRooms,
      fromDate,
      isMobile,
      params.id,
      rooms,
      untilDate,
    ]
  );
};
