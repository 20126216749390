import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom-v5-compat";

import { type Lodging, LodgingMediaAssetEnum } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { Carousel, Slot } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantContext } from "@hopper-b2b/utilities";

import clsx from "clsx";
import { useTrackEvents } from "../../../../tracking";
import { getLodgingShopTrackingProperties } from "../../../../util/utils";
import { useLodgingLink } from "../../hooks";
import { getHotelAvailabilityTrackingProperties } from "../../reducer";
import { LodgingSummary } from "./components";
import styles from "./LodgingCard.module.scss";
import { LodgingCardSkeleton } from "./LodgingCardSkeleton";

interface LodgingCardProps {
  lodging: Lodging;
  lodgingListIndex: number;
  id: string;

  setOveredId(nextValue: string | null): void;

  className?: string;
  singleImage?: boolean;
  isHorizontalScroll?: boolean;
}

export const LodgingCard = React.memo(
  ({
    id,
    lodging: currentLodging,
    lodgingListIndex,
    setOveredId,
    className,
    singleImage,
    isHorizontalScroll = false,
  }: LodgingCardProps) => {
    const { matchesMobile } = useDeviceTypes();
    const location = useLocation();
    const { lodging, available } = currentLodging;
    const lodgingLink = useLodgingLink(lodging.id, lodging.name);
    const hotelAvailabilityTrackingProperties = useSelector(
      getHotelAvailabilityTrackingProperties
    );
    const [viewed, setViewed] = useState(false);
    const trackEvent = useTrackEvents();
    const { ref, inView } = useInView();
    const { t } = useI18nContext();
    const { icons: tenantIcons } = useTenantContext();

    const trackingProperties = useMemo(
      () => ({
        properties: hotelAvailabilityTrackingProperties,
        ...getLodgingShopTrackingProperties(currentLodging, lodgingListIndex),
      }),
      [currentLodging, hotelAvailabilityTrackingProperties, lodgingListIndex]
    );

    useEffect(() => {
      if (inView && !viewed) {
        setViewed(true);
        trackEvent(LodgingShopTrackingEvents.hotel_viewed_lodging_in_list, {}, [
          trackingProperties,
        ]);
      }
    }, [currentLodging, inView, trackEvent, trackingProperties, viewed]);

    const carouselImagesUrls = useMemo(
      () =>
        lodging.media
          .filter(
            (asset) => asset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video
          )
          .map((asset) => asset.url),
      [lodging.media]
    );

    const onCarouselNavigate = useCallback(() => {
      trackEvent(LodgingShopTrackingEvents.hotel_swiped_carousel, {}, [
        trackingProperties,
      ]);
    }, [trackEvent, trackingProperties]);

    const availableLodgingLink = useMemo(() => {
      return available ? lodgingLink : location.pathname + location.search;
    }, [available, lodgingLink, location]);

    return (
      <div ref={ref} className={className}>
        {viewed ? (
          <Link
            id={id}
            onClick={() => {
              if (available) {
                trackEvent(
                  LodgingShopTrackingEvents.hotel_tapped_lodging_in_list,
                  {},
                  [trackingProperties]
                );
                trackEvent(
                  LodgingShopTrackingEvents.hotel_tapped_map_card,
                  {},
                  [trackingProperties]
                );
              }
            }}
            to={availableLodgingLink}
            className={clsx(
              styles.LodgingCard,
              "LodgingCard",
              !available ? "not-available" : null
            )}
            target={matchesMobile || !available ? "" : "_blank"}
            replace={!available}
            onMouseEnter={() => setOveredId(id)}
            onMouseLeave={() => setOveredId(null)}
            onFocus={() => setOveredId(id)}
            onBlur={() => setOveredId(null)}
          >
            <div
              className={clsx(
                styles.Carousel,
                !available && styles.unavailable,
                "Carousel"
              )}
            >
              {!available ? (
                <div className="no-availability-badge">
                  <img src={tenantIcons.unavailable} alt="" />
                  <p>{t("noAvailabilityForDates")}</p>
                </div>
              ) : null}
              <Carousel
                imageUrlsArray={
                  singleImage ? [carouselImagesUrls[0]] : carouselImagesUrls
                }
                preventDefaultTouchmoveEvent={!singleImage}
                onNavigateToNextSlide={onCarouselNavigate}
                onNavigateToPrevSlide={onCarouselNavigate}
              />
            </div>

            <Slot
              id="lodging-card-summary"
              currentLodging={currentLodging}
              component={
                <LodgingSummary
                  currentLodging={currentLodging}
                  onlyStarRating={isHorizontalScroll}
                />
              }
            />
          </Link>
        ) : (
          <LodgingCardSkeleton />
        )}
      </div>
    );
  }
);
