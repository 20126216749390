import { useCallback, useMemo } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { IFlyerOption, RadioOption } from "@hopper-b2b/types";
import { RadioDropdown } from "@hopper-b2b/ui";
import Chevron from "../../../assets/client/chevron.svg";
import { SelectWithHalfSheet } from "../../SelectWithHalfSheet";
import "./styles.scss";

export interface FrequentFlyerDropdownProps {
  selected: IFlyerOption | null;
  options: IFlyerOption[];
  setOption: (option: IFlyerOption) => void;
}

const FrequentFlyerDropdown = ({
  selected,
  options,
  setOption,
}: FrequentFlyerDropdownProps) => {
  const { t } = useI18nContext();

  const frequentFlyerOptions = useMemo(
    () =>
      options.map((option) => {
        return { value: option.value, label: option?.label || null };
      }),
    [options]
  );

  const handleSetOption = useCallback(
    (optionValue: RadioOption["value"]) => {
      const newlySelectedOption = options.find(
        (option) => option.value === optionValue
      );
      setOption(newlySelectedOption);
    },
    [options, setOption]
  );

  return (
    <SelectWithHalfSheet
      label={t("frequentFlyerProgram")}
      selected={selected ? selected.program : null}
      className="frequent-flyer-select traveler-info-field"
      chevronSrc={Chevron}
      modalContent={
        <RadioDropdown
          dropdownLabel={t("frequentFlyerProgram")}
          options={frequentFlyerOptions}
          setOption={handleSetOption}
          selected={selected?.value}
          showDropdownContentOnly={true}
        />
      }
    />
  );
};

export default FrequentFlyerDropdown;
