import { Divider, Typography, type TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { isString } from "lodash-es";
import { type ReactNode, useEffect, useState } from "react";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createGeneralSupportConversation } from "@hopper-b2b/self-serve";
import { copyToClipboard, useTenantIcons } from "@hopper-b2b/utilities";
import { ClientName, type IConfirmationNumber } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BannerSeverity,
  ButtonWrap,
  DetailHalfSheet,
  IconComponent,
  IconName,
  ImportantInfoList,
  MobileFloatingSection,
  NotificationBanner,
} from "@hopper-b2b/ui";

import { ReactComponent as Chevron } from "../../../assets/client/arrow-back.svg";
import { ContactSupportModalContent } from "../ContactSupportModalContent";
import "./styles.scss";

export interface ISelfServeFlightInfoProps {
  actions?: ReactNode | ReactNode[];
  disclaimer?: string;
  infoItems: string[];
  subtitle?: ReactNode;
  subtitle2?: ReactNode;
  subtitleProps?: TypographyProps;
  tcHelpProps?: TypographyProps;
  tcHelpText?: ReactNode;
  title?: ReactNode;
  titleProps?: TypographyProps;
  primaryOnClick?: () => void;
  primaryButtonText?: string;
  secondaryOnClick?: () => void;
  secondaryButtonText?: string;
  modal?: boolean;
  confirmationNumbers?: IConfirmationNumber[];
  onBack?: () => void;
  contactAirline?: boolean;
  contactCustomerService?: boolean;
  ItinerarySummary?: ReactNode;
  onClose?: () => void;
}

const defaultProps: Partial<ISelfServeFlightInfoProps> = {
  ItinerarySummary: null,
  infoItems: [],
};

const SelfServeFlightInfo = (props: ISelfServeFlightInfoProps): JSX.Element => {
  const {
    actions,
    disclaimer,
    infoItems,
    title,
    titleProps,
    subtitleProps,
    tcHelpText,
    primaryOnClick,
    primaryButtonText,
    secondaryOnClick,
    secondaryButtonText,
    modal = false,
    confirmationNumbers,
    onBack,
    contactAirline = false,
    contactCustomerService = false,
    ItinerarySummary,
    onClose,
  } = props;
  const { t } = useI18nContext();
  const { copy } = useTenantIcons();
  let { subtitle, subtitle2 } = props;
  const updatedSubtitleProps: TypographyProps = subtitleProps ?? {};
  const updatedSubtitle2Props: TypographyProps = subtitleProps ?? {};
  const [showFtcInfo, setShowFtcInfo] = useState(false);

  const handleOpenChat = () => {
    onClose && onClose();
    createGeneralSupportConversation(ClientName.NUBANK);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if subtitle is string[], format to show as separate sentences
  if (
    subtitle &&
    Array.isArray(subtitle) &&
    subtitle.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitleProps.dangerouslySetInnerHTML = {
      __html: subtitle.join(" "),
    };
    subtitle = null;
  }

  if (
    subtitle2 &&
    Array.isArray(subtitle2) &&
    subtitle2.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitle2Props.dangerouslySetInnerHTML = {
      __html: subtitle2.join(" "),
    };
    subtitle2 = null;
  }

  return (
    <div
      className={clsx("self-serve-flight-info-container nubank slot", {
        modal,
        contact: contactCustomerService,
      })}
    >
      {onBack ? (
        <div className="header">
          <ButtonWrap className="back-button" onClick={onBack}>
            <Chevron className="back-icon" />
          </ButtonWrap>
        </div>
      ) : null}
      {contactCustomerService ? (
        <ContactSupportModalContent
          onClose={onClose}
          onContactSupport={handleOpenChat}
        />
      ) : (
        <>
          <div className="self-serve-flight-info-content nubank slot">
            <Typography
              variant="h2"
              {...titleProps}
              className={clsx("modal-title", titleProps?.className, {
                paddingTop: onBack ? false : true,
              })}
            >
              {title || ""}
            </Typography>
            {disclaimer && (
              <NotificationBanner
                html={disclaimer}
                severity={BannerSeverity.WARNING}
              />
            )}
            {(subtitle || updatedSubtitleProps) && (
              <Typography
                {...updatedSubtitleProps}
                className={clsx("subtitle", updatedSubtitleProps?.className)}
              >
                {subtitle}
              </Typography>
            )}
            {(subtitle2 || updatedSubtitle2Props) && (
              <Typography
                {...updatedSubtitle2Props}
                className={clsx("subtitle", updatedSubtitle2Props?.className)}
              >
                {subtitle2}
              </Typography>
            )}
            {tcHelpText && (
              <>
                <div className="tc-help-container">
                  <IconComponent
                    ariaLabel="ftc info icon"
                    className="ftc-info-icon"
                    name={IconName.InfoCircleFill}
                  />
                  <div
                    className="tc-help-content"
                    onClick={() => setShowFtcInfo(true)}
                  >
                    <Typography className="title bold" variant="h4">
                      <>{t("selfServe.ftcInfoCta")}</>
                    </Typography>
                    <FontAwesomeIcon
                      icon={faChevronRight as IconProp}
                      className="ftc-info-icon-cta"
                    />
                  </div>
                </div>
                <DetailHalfSheet
                  onClose={() => setShowFtcInfo(false)}
                  open={showFtcInfo}
                  title=""
                  description={t("selfServe.ftcInfo")}
                  header={t("selfServe.ftcInfoTitle") || ""}
                  className="ftc-info-modal"
                />
              </>
            )}
            {contactAirline && confirmationNumbers ? (
              <div className="self-serve-flight-info-confirmation">
                <div className="self-serve-flight-info-confirmation-content">
                  <h3 className="self-serve-flight-info-confirmation-label">
                    {t("airlineConfirmationNumber")}
                  </h3>
                  <p className="self-serve-flight-info-confirmation-number">
                    {confirmationNumbers[1]?.locator}
                  </p>
                </div>
                <ButtonWrap
                  className="confirmation-copy-container"
                  onClick={() =>
                    copyToClipboard(confirmationNumbers[1]?.locator)
                  }
                  aria-label={t("copy")}
                >
                  <img src={copy} className="copy-icon" alt="" />
                </ButtonWrap>
              </div>
            ) : null}
            {!contactAirline ? ItinerarySummary : null}
            {infoItems.length ? (
              <div className="info-items">
                <Divider className="divider" />
                <ImportantInfoList
                  titleProps={{ variant: "h6", className: "cancel-info-title" }}
                  infoItems={infoItems}
                  title={t("selfServe.cancelationInfoTitle") || ""}
                />
              </div>
            ) : null}
            <Divider className="divider" />
          </div>
          {actions && <div className="actions-container">{actions}</div>}
          {primaryOnClick ? (
            <MobileFloatingSection
              primaryButton={{
                children: primaryButtonText,
                onClick: primaryOnClick,
                className: "self-serve-flight-info-primary-button",
              }}
              secondaryButton={
                secondaryOnClick
                  ? {
                      children: secondaryButtonText,
                      onClick: secondaryOnClick,
                      className: "self-serve-flight-info-secondary-button",
                    }
                  : null
              }
            />
          ) : null}
        </>
      )}
    </div>
  );
};

SelfServeFlightInfo.defaultProps = defaultProps;

export default SelfServeFlightInfo;
