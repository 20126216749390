import { useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import defaultStyles from "./DisruptionExerciseRebookSegmentSelectPage.module.scss";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
  useTenantIcons,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import {
  Airport,
  FlightItinerarySegment,
  TripSlice,
} from "@b2bportal/air-disruption-api";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import {
  DisruptionExerciseContainer,
  DisruptionExerciseFooter,
  DisruptionExercisePage,
  DisruptionExercisePageContent,
} from "@b2bportal/core-disruption";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import { DisruptionExerciseSegmentSelectCard } from "../../components/DisruptionExerciseSegmentSelectCard";
import { DisruptionExerciseFlightInfoRow } from "../../components/DisruptionExerciseFlightInfoRow";

export interface DisruptionExerciseRebookSegmentSelectPageProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  segments: Array<FlightItinerarySegment>;
  airports: { [key: string]: Airport };
  disruptionProductName: string;
  onFlightSelectContinue: (props: {
    origin: string;
    destination: string;
    departure: string;
    tripSlice?: TripSlice | null;
  }) => void;
  onSupportClick: () => void;
  termsLink: string;
  displayExternalLinkAsModal: boolean;
  delayHours: string;
  hoursString: string;
  handleRefundClick: () => void;
  cancelFlowAction?: () => void;
}

export const DisruptionExerciseRebookSegmentSelectPage = ({
  disruptedFlight,
  segments,
  airports,
  disruptionProductName,
  onFlightSelectContinue,
  onSupportClick,
  termsLink,
  displayExternalLinkAsModal,
  delayHours,
  hoursString,
  handleRefundClick,
  cancelFlowAction,
}: DisruptionExerciseRebookSegmentSelectPageProps) => {
  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const dispatch = useDispatch();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<
    number | null
  >(null);
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRebookSegmentSelectPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handleCta = () => {
    if (selectedSegmentIndex != null) {
      const selectedSegment = segments[selectedSegmentIndex];
      const lastSegment = segments[segments.length - 1];

      if (selectedSegment && destination) {
        const origin = selectedSegment.origin.locationCode;
        const departure =
          selectedSegment.updatedDeparture ??
          selectedSegment.scheduledDeparture;
        const destination = lastSegment.destination.locationCode;

        onFlightSelectContinue({
          origin: origin,
          destination: destination,
          departure: departure,
          tripSlice: null,
        });

        dispatch(
          setDisruptionExerciseProgress(DisruptionExerciseProgress.FlightSearch)
        );
      }
    }
  };

  const handleGoBack = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
    );
  };

  const getCityName = (code: string) => {
    return airports[code]?.cityName || "";
  };

  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];
  const originCode = firstSegment.origin.locationCode;
  const destinationCode = lastSegment.destination.locationCode;
  const destination = getCityName(destinationCode);
  const fareName =
    disruptedFlight.bookedItinerary.travelItinerary.slices[0].segments[0]
      .cabinClassName;
  const numberOfTravelers =
    disruptedFlight.bookedItinerary.passengers.alone.length +
    disruptedFlight.bookedItinerary.passengers.withLapInfants.length;

  const selectableRows = segments.map((segment, index) => {
    return (
      <DisruptionExerciseSegmentSelectCard
        title={getCityName(segment.origin.locationCode)}
        subtitle={segment.origin.locationCode}
        onSelect={() => setSelectedSegmentIndex(index)}
        selected={selectedSegmentIndex === index}
        isDestination={false}
        disabled={
          selectedSegmentIndex !== null && selectedSegmentIndex !== index
        }
        key={index}
      ></DisruptionExerciseSegmentSelectCard>
    );
  });

  selectableRows.push(
    <DisruptionExerciseSegmentSelectCard
      title={getCityName(lastSegment.destination.locationCode)}
      subtitle={lastSegment.destination.locationCode}
      selected={selectedSegmentIndex !== null}
      isDestination={true}
      disabled={false}
      key={segments.length}
    ></DisruptionExerciseSegmentSelectCard>
  );

  const selectableRowsContainer = (
    <div className={cn("rowsContainer")}>{selectableRows}</div>
  );

  return (
    <DisruptionExercisePage
      className={cn("DisruptionExerciseRebookSegmentSelectPage")}
      pageWidth={DisruptionExercisePageWidth.Regular}
      title={
        !isDesktopAndUp
          ? disruptionProductName
          : t(
              "core-disruption.disruptionUniversalExercise.rebook.segmentSelectionPage.title"
            )
      }
      onBack={handleGoBack}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.rebook.segmentSelectionPage.submitButton",
        {
          destination: destination,
        }
      )}
      submitButtonAction={handleCta}
      submitButtonDisabled={selectedSegmentIndex === null}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
      onCancelFlow={cancelFlowAction}
      content={
        <DisruptionExercisePageContent
          leftContent={[
            !isDesktopAndUp ? (
              <span className={cn("title")}>
                {t(
                  "core-disruption.disruptionUniversalExercise.rebook.segmentSelectionPage.title"
                )}
              </span>
            ) : (
              <></>
            ),
            <DisruptionExerciseFlightInfoRow
              originCode={originCode}
              destinationCode={destinationCode}
              numberOfTravelers={numberOfTravelers}
              date={
                firstSegment.updatedDeparture ?? firstSegment.scheduledDeparture
              }
              fareName={fareName ?? ""}
              airports={airports}
            />,
            <DisruptionExerciseContainer
              content={[
                selectableRowsContainer,
                <DisruptionExerciseFooter
                  onSupportClick={onSupportClick}
                  onRefundClick={handleRefundClick}
                ></DisruptionExerciseFooter>,
              ]}
            ></DisruptionExerciseContainer>,
          ]}
        />
      }
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRebookSegmentSelectPage;
