import { ReactComponent as Airplane } from "../../assets/client/airplane.svg";
import { ReactComponent as AirplaneArrive } from "../../assets/client/airplane-arrive.svg";
import { ReactComponent as AirplaneDepart } from "../../assets/client/airplane-depart.svg";
import { ReactComponent as AirplaneFintech } from "../../assets/client/airplane-fintech.svg";
import { ReactComponent as AirplaneIconFintech } from "../../assets/client/airplane-icon-fintech.svg";
import { ReactComponent as Bell } from "../../assets/client/bell.svg";
import { ReactComponent as BlackLock } from "../../assets/client/black-lock.svg";
import { ReactComponent as Calendar } from "../../assets/client/calendar.svg";
import { ReactComponent as Card } from "../../assets/client/card.svg";
import { ReactComponent as Checkmark } from "../../assets/client/checkmark.svg";
import { ReactComponent as CheckmarkFilled } from "../../assets/client/checkmark-filled.svg";
import { ReactComponent as CheckmarkSimple } from "../../assets/client/checkmark-simple.svg";
import { ReactComponent as Chevron } from "../../assets/client/chevron.svg";
import { ReactComponent as CircleCheck } from "../../assets/client/circle-check.svg";
import { ReactComponent as Clock } from "../../assets/client/clock.svg";
import { ReactComponent as ClockOutline } from "../../assets/client/clock-outline.svg";
import { ReactComponent as Copy } from "../../assets/client/copy.svg";
import { ReactComponent as Close } from "../../assets/client/close.svg";
import { ReactComponent as ErrorIcon } from "../../assets/client/error.svg";
import { ReactComponent as Filter } from "../../assets/client/filter.svg";
import { ReactComponent as Info } from "../../assets/client/info.svg";
import { ReactComponent as LocationMarker } from "../../assets/client/location-marker.svg";
import { ReactComponent as Lock } from "../../assets/client/lock.svg";
import { ReactComponent as MissedConnection } from "../../assets/client/missed-connection.svg";
import { ReactComponent as NavArrow } from "../../assets/client/nav-arrow.svg";
import { ReactComponent as OneWay } from "../../assets/client/oneway.svg";
import { ReactComponent as Passenger } from "../../assets/client/passenger.svg";
import { ReactComponent as RoundTrip } from "../../assets/client/roundtrip.svg";
import { ReactComponent as Shield } from "../../assets/client/shield.svg";
import { ReactComponent as Cancel } from "../../assets/client/cancel.svg";
import { ReactComponent as Warning } from "../../assets/client/warning-black.svg";
import { ReactComponent as WarningError } from "../../assets/client/warning.svg";
import { ReactComponent as WarningTriangle } from "../../assets/client/warning-triangle.svg";
import { ReactComponent as ShieldDisruption } from "../../assets/client/shield-disruption.svg";
import { ReactComponent as MissedConnectionBlack } from "../../assets/client/missed-connection-black.svg";
import { ReactComponent as AirplanDepartChfar } from "../../assets/client/airplane-depart-chfar.svg";
import { ReactComponent as Reload } from "../../assets/client/reload.svg";
import { ReactComponent as Refresh } from "../../assets/client/refresh.svg";
import { ReactComponent as Ticket } from "../../assets/client/ticket.svg";
import { ReactComponent as ArrowFork } from "../../assets/client/arrow-fork.svg";
import { ReactComponent as MagnifyCheck } from "../../assets/client/magnify-check.svg";
import { ReactComponent as CalendarLoading } from "../../assets/client/calendar-loading.svg";
import { ReactComponent as ClockSolo } from "../../assets/client/clock-solo.svg";
import { ReactComponent as DollarBill } from "../../assets/client/dollar-bill.svg";
import { ReactComponent as DollarSign } from "../../assets/client/dollar-sign.svg";
import { ReactComponent as BriefCase } from "../../assets/client/briefcase-detail.svg";
import { ReactComponent as AirplaneOutline } from "../../assets/client/airplane-outline.svg";
import { ReactComponent as A11yIcon } from "../../assets/client/a11y-icon.svg";
import { ReactComponent as A11yIconActive } from "../../assets/client/a11y-icon-active.svg";
import { ReactComponent as ArrowBack } from "../../assets/client/arrow-back.svg";
import { ReactComponent as Redirect } from "../../assets/client/redirect.svg";
import { ReactComponent as UserIcon } from "../../assets/client/user.svg";
import { ReactComponent as TwoUsersIcon } from "../../assets/client/two-users.svg";
import { ReactComponent as HotelIcon } from "../../assets/client/hotel-icon.svg";
import { ReactComponent as GreenShieldCheck } from "../../assets/client/green-shield-check.svg";
import { ReactComponent as Bed } from "../../assets/client/bed.svg";
import { ReactComponent as ExternalLink } from "../../assets/client/external-link.svg";
import { ReactComponent as HotelTripsIcon } from "../../assets/client/hotel-trips-icon.svg";
import { ReactComponent as UnavailableIcon } from "../../assets/client/unavailable-icon.svg";
import { ReactComponent as AirplaneDouble } from "../../assets/client/airplane-double.svg";
import { ReactComponent as WarningOutline } from "../../assets/client/warning-outline.svg";
import { ReactComponent as ArrowAction } from "../../assets/client/arrow-action.svg";

// Dark Mode Icons
import { ReactComponent as A11yIconWhite } from "../../assets/client/darkMode/a11y-icon-white.svg";
import { ReactComponent as A11yIconDarkActive } from "../../assets/client/darkMode/a11y-icon-active.svg";
import { ReactComponent as AirplaneArriveWhite } from "../../assets/client/darkMode/airplane-arrive-white.svg";
import { ReactComponent as AirplaneDepartWhite } from "../../assets/client/darkMode/airplane-depart-white.svg";
import { ReactComponent as AirplaneWhite } from "../../assets/client/darkMode/airplane-white.svg";
import { ReactComponent as AirplaneIconFintechDark } from "../../assets/client/darkMode/airplane-icon-fintech-dark.svg";
import { ReactComponent as BellWhite } from "../../assets/client/darkMode/bell-white.svg";
import { ReactComponent as CalendarWhite } from "../../assets/client/darkMode/calendar-white.svg";
import { ReactComponent as CardWhite } from "../../assets/client/darkMode/card-white.svg";
import { ReactComponent as CheckmarkSimpleWhite } from "../../assets/client/darkMode/checkmark-simple-white.svg";
import { ReactComponent as CheckmarkWhite } from "../../assets/client/darkMode/checkmark-white.svg";
import { ReactComponent as ChevronDownWhite } from "../../assets/client/darkMode/chevron-down-white.svg";
import { ReactComponent as CloseWhite } from "../../assets/client/darkMode/close-white.svg";
import { ReactComponent as CloseWhiter } from "../../assets/client/darkMode/close-white-opacity-88.svg";
import { ReactComponent as CopyDark } from "../../assets/client/darkMode/copy.svg";
import { ReactComponent as FilterWhite } from "../../assets/client/darkMode/filter-white.svg";
import { ReactComponent as InfoGrey } from "../../assets/client/darkMode/info-grey.svg";
import { ReactComponent as LocationMarkerWhite } from "../../assets/client/darkMode/location-marker-white.svg";
import { ReactComponent as LockWhite } from "../../assets/client/darkMode/lock-white.svg";
import { ReactComponent as OneWayWhite } from "../../assets/client/darkMode/oneway-white.svg";
import { ReactComponent as PassengerWhite } from "../../assets/client/darkMode/passenger-white.svg";
import { ReactComponent as ReloadWhite } from "../../assets/client/darkMode/reload.svg";
import { ReactComponent as RoundTripWhite } from "../../assets/client/darkMode/roundtrip-white.svg";
import { ReactComponent as ShieldWhite } from "../../assets/client/darkMode/shield-white.svg";
import { ReactComponent as LockLargeWhite } from "../../assets/client/darkMode/lock-large-white.svg";
import { ReactComponent as CancelWhite } from "../../assets/client/darkMode/cancel.svg";
import { ReactComponent as WarningWhite } from "../../assets/client/darkMode/warning-white.svg";
import { ReactComponent as WarningOutlineWhite } from "../../assets/client/darkMode/warning-outline-white.svg";
import { ReactComponent as WarningTriangleWhite } from "../../assets/client/darkMode/warning-triangle.svg";
import { ReactComponent as ShieldDisruptionWhite } from "../../assets/client/darkMode/shield-disruption-white.svg";
import { ReactComponent as MissedConnectionWhite } from "../../assets/client/darkMode/missed-connection-white.svg";
import { ReactComponent as AirplanDepartChfarWhite } from "../../assets/client/darkMode/airplane-depart-chfar-white.svg";
import { ReactComponent as RefreshWhite } from "../../assets/client/darkMode/refresh-white.svg";
import { ReactComponent as TicketWhite } from "../../assets/client/darkMode/ticket-white.svg";
import { ReactComponent as ArrowForkWhite } from "../../assets/client/darkMode/arrow-fork-white.svg";
import { ReactComponent as AirplaneOutlineWhite } from "../../assets/client/darkMode/airplane-outline-white.svg";
import { ReactComponent as UserIconWhite } from "../../assets/client/darkMode/user-white.svg";
import { ReactComponent as TwoUsersIconWhite } from "../../assets/client/darkMode/two-users-white.svg";
import { ReactComponent as ArrowBackWhite } from "../../assets/client/darkMode/arrow-back-white.svg";
import { ReactComponent as NavArrowDark } from "../../assets/client/darkMode/nav-arrow-dark.svg";
import { ReactComponent as HotelIconWhite } from "../../assets/client/darkMode/hotel-icon-white.svg";
import { ReactComponent as BedWhite } from "../../assets/client/darkMode/bed-white.svg";
import { ReactComponent as ExternalLinkWhite } from "../../assets/client/darkMode/external-link-white.svg";
import { ReactComponent as UnavailableIconDark } from "../../assets/client/darkMode/unavailable-icon.svg";
import { ReactComponent as GreenShieldCheckDark } from "../../assets/client/darkMode/green-shield-check-dark.svg";
import { ReactComponent as HotelTripsIconWhite } from "../../assets/client/darkMode/hotel-trips-icon-white.svg";
import { ReactComponent as ArrowActionWhite } from "../../assets/client/darkMode/arrow-action-white.svg";
import { ReactComponent as GreenCheck } from "../../assets/client/green-confirmation-check.svg";

import type { darkModeIcons, lightModeIcons } from "./sharedIcons";
import type { SvgrComponent } from "@b2bportal/core-types";
import { IconNameEnum } from "@b2bportal/core-themes";

type DarkModeR = {
  [K in keyof typeof darkModeIcons]: SvgrComponent;
};

export const darkModeIconsSvg: DarkModeR = {
  a11yIcon: A11yIconWhite,
  a11yIconActive: A11yIconDarkActive,
  airplane: AirplaneWhite,
  airplaneArrive: AirplaneArriveWhite,
  airplaneDepart: AirplaneDepartWhite,
  airplaneDouble: AirplaneDouble,
  airplaneFintech: AirplaneFintech,
  airplaneOutline: AirplaneOutlineWhite,
  airplaneOutlineWhite: AirplaneOutlineWhite,
  back: ArrowBackWhite,
  bell: BellWhite,
  calendar: CalendarWhite,
  card: CardWhite,
  clock: Clock,
  copy: CopyDark,
  error: ErrorIcon,
  cancel: CancelWhite,
  checkmark: CheckmarkWhite,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimpleWhite,
  chevron: ChevronDownWhite,
  close: CloseWhite,
  circleCheck: CircleCheck,
  filter: FilterWhite,
  info: InfoGrey,
  locationMarker: LocationMarkerWhite,
  locationMarkerWhite: LocationMarkerWhite,
  lock: LockLargeWhite,
  blackLock: LockWhite,
  oneway: OneWayWhite,
  reload: ReloadWhite,
  roundtrip: RoundTripWhite,
  passenger: PassengerWhite,
  missedConnection: MissedConnection,
  navArrow: NavArrowDark,
  shield: ShieldWhite,
  warning: WarningWhite,
  warningError: WarningError,
  warningTriangle: WarningTriangleWhite,
  shieldDisruption: ShieldDisruptionWhite,
  missedConnectionFintech: MissedConnectionWhite,
  airplaneDepartChfar: AirplanDepartChfarWhite,
  refresh: RefreshWhite,
  ticket: TicketWhite,
  arrowFork: ArrowForkWhite,
  magnifyCheck: MagnifyCheck,
  calendarLoading: CalendarLoading,
  clockSolo: ClockSolo,
  dollarBill: DollarBill,
  dollarSign: DollarSign,
  briefcase: BriefCase,
  flightDetailsModalOutbound: AirplaneOutlineWhite,
  flightDetailsModalInbound: LocationMarkerWhite,
  flightDetailsModalStopover: ClockOutline,
  tripDetails: AirplaneOutlineWhite,
  redirect: Redirect,
  user: UserIconWhite,
  twoUsers: TwoUsersIconWhite,
  hotelIcon: HotelIconWhite,
  greenShieldCheck: GreenShieldCheckDark,
  bed: BedWhite,
  externalLink: ExternalLinkWhite,
  hotelTrips: HotelTripsIconWhite,
  unavailable: UnavailableIconDark,
  backWhite: ArrowBackWhite,
  deleteChip: CloseWhiter,
  [IconNameEnum.disruptionProtection]: AirplaneIconFintechDark,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineOne]: WarningOutlineWhite,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineTwo]: AirplaneOutline,
  [IconNameEnum.rightArrow]: ArrowActionWhite,
  [IconNameEnum.disruptionConfirmationIcon]: GreenCheck,
};

type LightModeR = {
  [K in keyof typeof lightModeIcons]: SvgrComponent;
};

export const lightModeIconsSvg: LightModeR = {
  a11yIcon: A11yIcon,
  a11yIconActive: A11yIconActive,
  airplane: Airplane,
  airplaneArrive: AirplaneArrive,
  airplaneDepart: AirplaneDepart,
  airplaneDouble: AirplaneDouble,
  airplaneFintech: AirplaneFintech,
  airplaneOutline: AirplaneOutline,
  airplaneOutlineWhite: AirplaneOutlineWhite,
  back: ArrowBack,
  bell: Bell,
  calendar: Calendar,
  cancel: Cancel,
  deleteChip: Cancel,
  card: Card,
  clock: Clock,
  copy: Copy,
  close: Close,
  error: ErrorIcon,
  checkmark: Checkmark,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Chevron,
  circleCheck: CircleCheck,
  filter: Filter,
  info: Info,
  locationMarker: LocationMarker,
  locationMarkerWhite: LocationMarkerWhite,
  lock: Lock,
  blackLock: BlackLock,
  oneway: OneWay,
  reload: Reload,
  roundtrip: RoundTrip,
  passenger: Passenger,
  missedConnection: MissedConnection,
  navArrow: NavArrow,
  shield: Shield,
  warning: Warning,
  warningError: WarningError,
  warningTriangle: WarningTriangle,
  shieldDisruption: ShieldDisruption,
  missedConnectionFintech: MissedConnectionBlack,
  airplaneDepartChfar: AirplanDepartChfar,
  refresh: Refresh,
  ticket: Ticket,
  arrowFork: ArrowFork,
  magnifyCheck: MagnifyCheck,
  calendarLoading: CalendarLoading,
  clockSolo: ClockSolo,
  dollarBill: DollarBill,
  dollarSign: DollarSign,
  briefcase: BriefCase,
  flightDetailsModalOutbound: AirplaneOutlineWhite,
  flightDetailsModalInbound: LocationMarkerWhite,
  flightDetailsModalStopover: ClockOutline,
  tripDetails: AirplaneOutline,
  redirect: Redirect,
  user: UserIcon,
  twoUsers: TwoUsersIcon,
  hotelIcon: HotelIcon,
  greenShieldCheck: GreenShieldCheck,
  bed: Bed,
  externalLink: ExternalLink,
  hotelTrips: HotelTripsIcon,
  unavailable: UnavailableIcon,
  backWhite: ArrowBackWhite,
  [IconNameEnum.disruptionProtection]: AirplaneIconFintech,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineOne]: WarningOutline,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineTwo]: AirplaneOutline,
  [IconNameEnum.rightArrow]: ArrowAction,
  [IconNameEnum.disruptionConfirmationIcon]: GreenCheck,
};
