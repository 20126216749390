import { createContext, useContext } from "react";
import type { CoreFlightsComponent } from "@b2bportal/core-types";
import type {
  SlotProviderProps,
  SlotComponents,
  SlotContext,
} from "@b2bportal/core-utilities";
import type {
  FlightFareGridItem,
  FlightShopSearch,
  FlightShopBreadcrumbs,
  FlightListNoFlights,
  FlightCardFareList,
} from "@components/flights";

type FlightSlot = {
  [CoreFlightsComponent.FlightFareGridItem]: typeof FlightFareGridItem;
  [CoreFlightsComponent.FlightShopSearch]: typeof FlightShopSearch;
  [CoreFlightsComponent.FlightShopBreadcrumbs]: typeof FlightShopBreadcrumbs;
  [CoreFlightsComponent.FlightListNoFlights]: typeof FlightListNoFlights;
  [CoreFlightsComponent.FlightCardFareList]: typeof FlightCardFareList;
};
export type FlightSlotType = SlotComponents<FlightSlot>;

const FlightSlotContext = createContext<
  SlotContext<FlightSlotType> | undefined
>(undefined);

export const FlightSlotProvider = ({
  context,
  children,
}: SlotProviderProps<FlightSlotType>) => {
  return (
    <FlightSlotContext.Provider value={context}>
      {children}
    </FlightSlotContext.Provider>
  );
};

const useSlotSystem = () => {
  const context = useContext(FlightSlotContext);
  if (context == null) {
    throw new Error("useSlots must be used within a FlightSlotProvider");
  }
  return context;
};

export const useFlightSlot = () => {
  const context = useSlotSystem();
  return context.components;
};
