import {
  FirstDayOfWeek,
  MonthType,
  OnDatesChangeProps,
  useDatepicker,
} from "@datepicker-react/hooks";

import { DateTimeFormatStyle, getLang, useI18nContext } from "@hopper-b2b/i18n";
import { Month } from "../Month";
import { firstWeekDay } from "../../DateRangePicker";
import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "../ColumnView/ColumnView.module.scss";

export interface IGroupViewProps {
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  setFocusedMonthIndex?: (index: number) => void;
  numberOfMonths: number;
  priceTagsElement: JSX.Element;
}

/* eslint-disable */
// temporarily disable linting here to prevent errors due to the placeholder function
export const GroupView = ({
  minAllowedDate,
  maxAllowedDate,
  setFocusedMonthIndex,
  numberOfMonths,
  priceTagsElement,
}: IGroupViewProps) => {
  // @ts-ignore: a placeholder handleDateChange is needed for using useDatepicker
  const handleDateChange = (props: OnDatesChangeProps) => {};
  /* eslint-enable */

  const { firstDayOfWeek, activeMonths } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate: minAllowedDate,
    endDate: maxAllowedDate,
    focusedInput: null,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const styles = useUiStyles(
    CoreUiComponents.DatePickerGroupView,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.DatePickerGroupView
  );
  const { DateTimeFormatStyle } = useI18nContext();

  return (
    <div className={block}>
      <div className={cn("price-range-tags-section")}>{priceTagsElement}</div>
      <div className={cn("months-container")}>
        {renderMonths(
          activeMonths,
          firstDayOfWeek,
          DateTimeFormatStyle.Custom({ month: "short" }),
          setFocusedMonthIndex
        )}
      </div>
    </div>
  );
};

const renderMonths = (
  months: MonthType[],
  firstDayOfWeek: FirstDayOfWeek,
  monthFormat: DateTimeFormatStyle,
  setFocusedMonthIndex?: (index: number) => void
) => {
  const styles = useUiStyles(
    CoreUiComponents.DatePickerGroupView,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.DatePickerGroupView
  );

  return months.map((month: MonthType, index: number) => (
    <Month
      key={`${month.year}-${month.month}`}
      year={month.year}
      month={month.month}
      firstDayOfWeek={firstDayOfWeek}
      date={month.date}
      hideWeekdayLabels={true}
      rootClassName={cn("group-view-month-root")}
      headerClassName={cn("group-view-month-label")}
      bodyClassName={cn("group-view-month-body")}
      monthFormat={monthFormat}
      isMiniCalendar={true}
      onClick={() => setFocusedMonthIndex && setFocusedMonthIndex(index)}
    />
  ));
};
