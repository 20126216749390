import { useState } from "react";
import * as React from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import defaultStyles from "./DatePickerButton.module.scss";
import { Icon } from "@components/ui";
import { useI18nContext, type DateTimeFormatStyle } from "@hopper-b2b/i18n";

export interface DatePickerButtonProps {
  dateFormat?: DateTimeFormatStyle;
  startDate: string | null;
  endDate: string | null;
  disabled?: boolean;
  startLabel: string;
  endLabel: string;
  renderCalendarPopup?: (extraProps: {
    open: boolean;
    closePopup: () => void;
  }) => React.ReactElement;
  onClick?: () => void;
  tabIndex?: number;
  buttonClass?: string;
  hideEndDate?: boolean;
  hideSeparator?: boolean;
  ariaLabel?: string;
  classes?: string[];
}

interface ContentProps {
  label: string;
  date: string | null;
}

export const DatePickerButton = (props: DatePickerButtonProps) => {
  const {
    startDate,
    endDate,
    startLabel,
    endLabel,
    disabled,
    renderCalendarPopup,
    onClick,
    dateFormat,
    tabIndex,
    buttonClass,
    hideEndDate,
    hideSeparator,
    ariaLabel,
    classes = [],
  } = props;

  const styles = useFlightStyles(
    CoreFlightsComponent.DatePickerButton,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.DatePickerButton
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const { formatDateTime, DateTimeFormatStyle } = useI18nContext();
  const defaultDateTimeFormat =
    DateTimeFormatStyle.YearShortMonthDayShortWeekday;

  const handleClick = () => {
    setOpenModal(true);
    onClick?.();
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const calendarIcon = <Icon iconName={IconNameEnum.calendar} />;

  const Content = ({ date, label }: ContentProps) => (
    <div className={cn("button-content")}>
      <p className={cn("content-top-label")}>{date ? label : ""}</p>
      <p className={clsx(cn("content-main-content", { label: !date }))}>
        {date
          ? formatDateTime(
              dayjs(date).toDate(),
              dateFormat ?? defaultDateTimeFormat
            )
          : label}
      </p>
    </div>
  );

  const startDateComponent = (
    <div className={cn("start-date-container")}>
      {calendarIcon}
      <Content date={startDate} label={startLabel} />
    </div>
  );

  const endDateComponent = (
    <div className={cn("end-date-container")}>
      {calendarIcon}
      <Content date={endDate} label={endLabel} />
    </div>
  );

  const separatorIcon = (
    <div className={cn("separator-container")}>
      <Icon iconName={IconNameEnum.calendarSeparatorIcon} />
    </div>
  );

  return (
    <div className={clsx(cn("container"), ...classes)}>
      <button
        className={clsx(cn("start-button"), buttonClass)}
        aria-label={ariaLabel}
        type="button"
        disabled={disabled}
        onClick={handleClick}
        tabIndex={tabIndex}
      >
        {startDateComponent}
        {!hideEndDate && !hideSeparator && separatorIcon}
        {!hideEndDate && endDateComponent}
      </button>
      {renderCalendarPopup &&
        renderCalendarPopup({
          open: openModal,
          closePopup: handleClose,
        })}
    </div>
  );
};
