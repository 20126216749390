import { DisruptionContractStatus, DisruptionExerciseState } from "./slice";
import { DisruptionState } from "../../../store";
import { DisruptionProtectionAncillaryContract } from "@b2bportal/air-booking-api";

export const getDisruptionExerciseProgress = (state: DisruptionState) => {
  return state.disruption.disruptionExerciseState.disruptionExerciseProgress;
};

export const getDisruptionExerciseToken = (state: DisruptionExerciseState) =>
  state.token;

export const disruptionExerciseFlags = (
  contract?: DisruptionProtectionAncillaryContract
) => {
  const hasDisruptionProtection = !!contract;
  if (contract != null) {
    const isDisruptionProtectionExercisable =
      hasDisruptionProtection &&
      contract.status.DisruptionContractStatus ===
        DisruptionContractStatus.ANY_EXERCISE_ELIGIBLE;
    const isEligibleToBookReturn =
      hasDisruptionProtection &&
      contract.status.DisruptionContractStatus ===
        DisruptionContractStatus.REBOOK_ONLY_ELIGIBLE;
    return {
      isDisruptionProtectionExercisable: isDisruptionProtectionExercisable,
      isEligibleToBookReturn: isEligibleToBookReturn,
    };
  } else {
    return {
      isDisruptionProtectionExercisable: false,
      isEligibleToBookReturn: false,
    };
  }
};
