import { useI18nContext } from "@hopper-b2b/i18n";
import { useEffect, useState } from "react";

import { TripCategory } from "../../../../../types";

export interface ITripCategoryOption {
  label: string;
  category: TripCategory;
}

export interface UseFlightCategorySelectionParams {
  tripCategoryChangeHandler: (tripCategory: TripCategory) => void;
  category?: TripCategory;
}

export const useFlightCategorySelection = ({
  category,
  tripCategoryChangeHandler,
}: UseFlightCategorySelectionParams) => {
  const { t } = useI18nContext();

  const [selectedTripCategoryState, setSelectedTripCategory] =
    useState<TripCategory>(category || TripCategory.ROUND_TRIP);

  const TRIP_TYPES: ITripCategoryOption[] = [
    { label: t("flightRoundTrip"), category: TripCategory.ROUND_TRIP },
    { label: t("flightOneWay"), category: TripCategory.ONE_WAY },
  ];

  useEffect(() => {
    if (category != null && category !== selectedTripCategoryState) {
      setSelectedTripCategory(category);
    }
  }, [category, selectedTripCategoryState, setSelectedTripCategory]);

  const getCategoryOptions = () => {
    return TRIP_TYPES.map(({ label, category }) => {
      return {
        value: category,
        label: label,
      };
    });
  };

  const handleOnChange = (value: string) => {
    const tripType = TRIP_TYPES.find((item) => item.category === value);
    if (!tripType) {
      return;
    }
    setSelectedTripCategory(tripType.category);
    tripCategoryChangeHandler(tripType.category);
  };

  return {
    selectedTripCategoryState,
    handleOnChange,
    getCategoryOptions,
  };
};
