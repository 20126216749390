import Restriction, { type IRestrictionProps } from "./Restriction";

interface IAmenityProps {
  restriction: IRestrictionProps;
}

const Amenity = ({ restriction }: IAmenityProps) => (
  <Restriction
    key={restriction.description}
    symbol={restriction.symbol}
    description={restriction.description}
  />
);

export default Amenity;
