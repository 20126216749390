import { useI18nContext } from "@hopper-b2b/i18n";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExerciseFooter.module.scss";
import { DisruptionExerciseButton } from "../DisruptionExerciseButton";
import { DisruptionExerciseButtonType } from "../DisruptionExerciseButton/DisruptionExerciseButton";
import { useDeviceType } from "@b2bportal/core-utilities";
import ExternalLinkContainer from "../../../SharedComponents/ExternalLinkContainer/ExternalLinkContainer";

export interface DisruptionExerciseFooterProps {
  onSupportClick?: () => void;
  onRefundClick?: () => void;
  termsLink?: string;
  displayLinkInModal?: boolean;
}

export const DisruptionExerciseFooter = (
  props: DisruptionExerciseFooterProps
) => {
  const { t } = useI18nContext();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExerciseFooter;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { isDesktopAndUp } = useDeviceType();

  const supportButton = props.onSupportClick ? (
    <div className={cn("supportButtonContainer")}>
      <DisruptionExerciseButton
        onClick={props.onSupportClick}
        title={t("core-disruption.disruptionUniversalExercise.contactSupport")}
        disabled={false}
        type={DisruptionExerciseButtonType.Clear}
      />
    </div>
  ) : null;

  const refundButton = props.onRefundClick ? (
    <div className={cn("refundButtonContainer")}>
      <DisruptionExerciseButton
        onClick={props.onRefundClick}
        title={t("core-disruption.disruptionUniversalExercise.requestRefund")}
        disabled={false}
        type={DisruptionExerciseButtonType.Clear}
      />
    </div>
  ) : null;

  const termsButton =
    props.termsLink != null && !isDesktopAndUp ? (
      <div className={cn("termsButtonContainer")}>
        <ExternalLinkContainer
          linkUrl={props.termsLink}
          content={
            <DisruptionExerciseButton
              onClick={() => {
                // no action
              }}
              title={t(
                "core-disruption.disruptionUniversalExercise.termsAndConditions"
              )}
              disabled={false}
              type={DisruptionExerciseButtonType.Clear}
            />
          }
          displayInModal={props.displayLinkInModal ?? true}
        />
      </div>
    ) : null;

  const footer = (
    <div className={cn("disruptionFooterContent")}>
      <span className={cn("text")}>
        {t("core-disruption.disruptionUniversalExercise.needHelp")}
      </span>
      {termsButton}
      {supportButton}
      {props.onRefundClick && (
        <div className={cn("refundContainer")}>
          <span className={cn("text")}>
            {t("core-disruption.disruptionUniversalExercise.or")}
          </span>
          {refundButton}
        </div>
      )}
    </div>
  );

  return (
    <div className={cn("disruptionFooterRoot")}>
      <hr></hr>
      {footer}
    </div>
  );
};

export default DisruptionExerciseFooter;
