import {
  CoreFlightSteps,
  FlightsNavigationFlow,
} from "@b2bportal/core-utilities";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FlightSearchActions,
  FlightSearchSelectors,
} from "../../../../search/store";

interface UseFlightShopSearchResponse {
  context: {
    openMobileSearch: boolean;
    openCalendar: boolean;
  };
  handlers: {
    onClose: () => void;
    handleOnSearch: () => void;
    onDateSelectionClick: () => void;
    onLocationSelectionClick: () => void;
  };
}

export const useFlightShopSearch = (): UseFlightShopSearchResponse => {
  const dispatch = useDispatch();

  const [openMobileSearch, setOpenMobileSearch] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setOpenCalendar(false);
    setOpenMobileSearch(false);
  }, []);

  const goToStep = FlightsNavigationFlow.useGoToStep();

  const search = useSelector(FlightSearchSelectors.getSearchFormValues);

  const handleOnSearch = useCallback(() => {
    onClose();
    dispatch(FlightSearchActions.setFlightSearch(search));
    goToStep(CoreFlightSteps.DEPARTURE);
  }, [onClose, dispatch, search, goToStep]);

  const onLocationSelectionClick = useCallback(
    () => setOpenMobileSearch(true),
    []
  );

  const onDateSelectionClick = useCallback(() => setOpenCalendar(true), []);

  return {
    context: {
      openMobileSearch,
      openCalendar,
    },
    handlers: {
      onClose,
      handleOnSearch,
      onDateSelectionClick,
      onLocationSelectionClick,
    },
  };
};
