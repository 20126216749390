import {
  useUiStyles,
  useModuleBEM,
  IconNameEnum,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./Breadcrumbs.module.scss";
import clsx from "clsx";
import { Icon } from "@components/ui";

export interface BreadcrumbsStep {
  label: string;
  onClick?: () => void;
  isSelected: boolean;
}

export interface Breadcrumbs {
  steps: BreadcrumbsStep[];
  className?: string;
}

export const Breadcrumbs = ({ steps, className }: Breadcrumbs) => {
  const styles = useUiStyles(CoreUiComponents.Breadcrumbs, defaultStyles);
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.Breadcrumbs);

  const selectedIndex = steps.findIndex((s) => s?.isSelected);
  return (
    <nav className={clsx(block, className)}>
      <ol className={cn("list")}>
        {steps.map((step, index) => {
          const isLink = index < selectedIndex;
          return (
            <li className={cn("item")} key={index}>
              {isLink ? (
                <button className={cn("link")} onClick={step.onClick}>
                  {step.label}
                </button>
              ) : (
                <span className={cn("text", { selected: step.isSelected })}>
                  {step.label}
                </span>
              )}
              {index !== steps.length - 1 && (
                <span className={cn("separator")}>
                  <Icon iconName={IconNameEnum.nextIcon} />
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
